import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/constants/routes';
import { useMemo } from 'react';
import { USER_PROFILE_FIELDS } from '../../../../utils/constants/fields';

const OnboardingSteps = observer(() => {
  const { fundsStore, authStore } = useStore();

  const hasFund = useMemo(() => {
    return !!fundsStore.funds.length;
  }, [fundsStore.funds]);

  const hasFilledUserInfo = useMemo(() => {
    return !Object.values(USER_PROFILE_FIELDS).find((f) => !authStore.userInfo?.[f.NAME]);
  }, [authStore.userInfo]);

  const isLoadingFundStatus = fundsStore.isLoadingData;

  return (
    <>
      <h2>Onboarding</h2>
      <div className="cards-container">
        <div className="card">
          <div className="heading">
            <div className="icon-onboarding step-1"></div>
            {hasFilledUserInfo ? (
              <div className="badge success">Completed</div>
            ) : (
              <div className="badge info">Pending</div>
            )}
          </div>
          <div className="body">
            {hasFilledUserInfo ? (
              <h6>Complete your profile</h6>
            ) : (
              <Link to={paths.USER_PROFILE}>
                <h6>Complete your profile</h6>
              </Link>
            )}
            <p>Enhance your platform presence with our Complete Profile feature.</p>
          </div>
        </div>
        <div className="card">
          <div className="heading">
            <div className="icon-onboarding step-2"></div>
            {isLoadingFundStatus ? (
              <div className="badge">Loading</div>
            ) : hasFund ? (
              <div className="badge success">Completed</div>
            ) : (
              <div className="badge info">Pending</div>
            )}
          </div>
          <div className="body">
            {isLoadingFundStatus || hasFund ? (
              <h6>Create your first fund</h6>
            ) : (
              <Link to={paths.FUNDS_CREATE}>
                <h6>Create your first fund</h6>
              </Link>
            )}
            <p>Kickstart your investment journey with our user-friendly fund tool.</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default OnboardingSteps;
