import StaticPageLayout from '../layouts/StaticPageLayout';
import GeneralLoader from './GeneralLoader';

const AppLoader = () => (
  <div className="app-wrapper">
    <StaticPageLayout>
      <GeneralLoader />
    </StaticPageLayout>
  </div>
);

export default AppLoader;
