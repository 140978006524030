import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { GA_CONFIG, GA_EVENTS, GA_DIMENSIONS, isInDevelopment } from './constants';
import { logError, gaEventGuard } from './utils';

let isInit = false;

const dimsToClear = [
  GA_DIMENSIONS.testMode,
  GA_DIMENSIONS.debug_mode,
  GA_DIMENSIONS.debug_event,
  GA_DIMENSIONS.ecommerce,
  GA_DIMENSIONS.cType
];
const dimsClearObj = Object.fromEntries(dimsToClear.map((dim) => [dim, undefined]));

/**
 * Initializes GTM and GA4
 */
export const initGA = () => {
  if (!GA_CONFIG.gaMeasurementId && !GA_CONFIG.gtmContainerId) {
    return;
  }

  const isInternalTraffic = isInDevelopment;

  const initOptions = {
    ...(isInDevelopment ? { [GA_DIMENSIONS.debug_mode]: true } : {}),
    ...(isInternalTraffic ? { [GA_DIMENSIONS.traffic_type]: 'internal' } : {})
  };

  const gaOptions = {
    anonymizeIp: true,
    siteSpeedSampleRate: 100,
    ...initOptions
  };

  if (GA_CONFIG.gaMeasurementId) {
    ReactGA.initialize(GA_CONFIG.gaMeasurementId, {
      [GA_DIMENSIONS.testMode]: isInDevelopment,
      gaOptions
      // legacyDimensionMetric: false, // uncomment if needed to be false
    });
  }

  if (GA_CONFIG.gtmContainerId) {
    TagManager.initialize({
      gtmId: GA_CONFIG.gtmContainerId,
      dataLayer: {
        anonymizeIp: true,
        ...initOptions
      }
    });

    isInit = true;
  }
};

/**
 * Pushes event objects to GTM data layer.
 * @param {Object} mainEventData - The event object.
 */
const pushEvent = (eventName = '', mainEventData = {}) => {
  if (!isInit) {
    return;
  }

  if (!Object.values(GA_EVENTS).includes(eventName)) {
    logError('[pushEvent] eventName param is wrong!');
  }

  const dataLayerVariables = {};
  if (isInDevelopment) {
    Object.assign(
      dataLayerVariables,
      Object.fromEntries(
        [GA_DIMENSIONS.testMode, GA_DIMENSIONS.debug_mode, GA_DIMENSIONS.debug_event].map((dim) => [
          dim,
          true
        ])
      )
    );
  }

  // if traffic type is internal dataLayerVariable.traffic_type = 'internal'

  const eventData = { ...dataLayerVariables, ...mainEventData, event: eventName };
  if (isInDevelopment) {
    console.log('eventData', eventData);
  }
  window.dataLayer.push({ ...dimsClearObj });
  window.dataLayer.push(eventData);
};

export const eventWaitlistFormStep1 = (companyType = '') => {
  gaEventGuard(() => {
    const waitlistFormStep1EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.Waitlist_form_step_1, waitlistFormStep1EventData);
  });
};

export const eventWaitlistFormStep2 = (companyType = '') => {
  gaEventGuard(() => {
    const waitlistFormStep2EventData = { [GA_DIMENSIONS.cType]: companyType };
    pushEvent(GA_EVENTS.Waitlist_form_step_2, waitlistFormStep2EventData);
  });
};
