import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useStore } from '../store/store';
import { paths } from '../utils/constants/routes';

const useSessionGuard = () => {
  const { authStore, utilsStore, isWaitlistActivated } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (utilsStore.isSessionExpired) {
      utilsStore.setIsSessionExpired(false);
      authStore.logout();
      navigate(isWaitlistActivated ? paths.LOGIN : paths.HOME);
      utilsStore.setHeaderMessage('Your session has expired. Please login again.', true);
    }
  }, [utilsStore.isSessionExpired]);
};

export default useSessionGuard;
