export const isInDevelopment =
  process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'stg';

export const GA_CONFIG = {
  gaMeasurementId: '',
  gtmContainerId: 'GTM-T8RBCTTX'
};

export const GA_EVENTS = {
  Waitlist_form_step_1: 'Waitlist_form_step_1',
  Waitlist_form_step_2: 'Waitlist_form_step_2'
};

export const GA_DIMENSIONS = {
  testMode: 'testMode',
  debug_mode: 'debug_mode',
  debug_event: 'debug_event',
  traffic_type: 'traffic_type',
  ecommerce: 'ecommerce',
  cType: 'cType'
};
