import { OPTION_KEYS } from '../utils/constants/optionKeys';
import {
  FUND_FIELDS,
  COMPANY_PROFILE_FIELDS,
  USER_PROFILE_FIELDS,
  PORTFOLIO_FIELDS,
  SEARCH_FIELDS,
  SEARCH_SAVE_FIELDS,
  SAVED_SEARCH_VIEW_FIELDS
} from '../utils/constants/fields';
import { UI_OPTIONS } from '../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../utils/constants/uiOptionKeys';

const OPTIONS = {
  [OPTION_KEYS.COUNTRIES]: [
    { name: 'Austria', value: 1, region: 1 },
    { name: 'Belgium', value: 2, region: 1 },
    { name: 'Channel Islands ', value: 3, region: 1 },
    { name: 'Denmark', value: 4, region: 1 },
    { name: 'Finland', value: 5, region: 1 },
    { name: 'France', value: 6, region: 1 },
    { name: 'Germany', value: 7, region: 1 },
    { name: 'Iceland', value: 8, region: 1 },
    { name: 'Ireland', value: 9, region: 1 },
    { name: 'Italy', value: 10, region: 1 },
    { name: 'Luxembourg', value: 11, region: 1 },
    { name: 'Malta', value: 12, region: 1 },
    { name: 'Netherlands', value: 13, region: 1 },
    { name: 'Norway', value: 14, region: 1 },
    { name: 'Portugal', value: 15, region: 1 },
    { name: 'Spain', value: 16, region: 1 },
    { name: 'Sweden', value: 17, region: 1 },
    { name: 'Switzerland', value: 18, region: 1 },
    { name: 'United Kingdom', value: 19, region: 1 },
    { name: 'Northern Ireland', value: 20, region: 1 },
    { name: 'Albania', value: 21, region: 2 },
    { name: 'Belarus', value: 22, region: 2 },
    { name: 'Bosnia and Herzegovina ', value: 23, region: 2 },
    { name: 'Bulgaria', value: 24, region: 2 },
    { name: 'Croatia ', value: 25, region: 2 },
    { name: 'Czech Republic', value: 26, region: 2 },
    { name: 'Estonia ', value: 27, region: 2 },
    { name: 'Greece', value: 28, region: 2 },
    { name: 'Hungary', value: 29, region: 2 },
    { name: 'Latvia', value: 30, region: 2 },
    { name: 'Lithuania', value: 31, region: 2 },
    { name: 'Montenegro ', value: 32, region: 2 },
    { name: 'Poland', value: 33, region: 2 },
    { name: 'Republic of Moldova', value: 34, region: 2 },
    { name: 'Romania', value: 35, region: 2 },
    { name: 'Russian Federation', value: 36, region: 2 },
    { name: 'Serbia', value: 37, region: 2 },
    { name: 'Slovakia', value: 38, region: 2 },
    { name: 'Slovenia', value: 39, region: 2 },
    { name: 'Ukraine', value: 40, region: 2 },
    { name: 'United States of America', value: 41, region: 3 },
    { name: 'Canada', value: 42, region: 3 },
    { name: 'Aruba', value: 43, region: 4 },
    { name: 'Bahamas', value: 44, region: 4 },
    { name: 'Barbados', value: 45, region: 4 },
    { name: 'Cuba', value: 46, region: 4 },
    { name: 'Dominican Republic', value: 47, region: 4 },
    { name: 'Grenada', value: 48, region: 4 },
    { name: 'Guadeloupe', value: 49, region: 4 },
    { name: 'Haiti', value: 50, region: 4 },
    { name: 'Jamaica', value: 51, region: 4 },
    { name: 'Martinique', value: 52, region: 4 },
    { name: 'Netherlands Antilles', value: 53, region: 4 },
    { name: 'Puerto Rico', value: 54, region: 4 },
    { name: 'Saint Lucia', value: 55, region: 4 },
    { name: 'Saint Vincent and the Grenadines', value: 56, region: 4 },
    { name: 'Trinidad and Tobago', value: 57, region: 4 },
    { name: 'United States Virgin Islands', value: 58, region: 4 },
    { name: 'Belize', value: 59, region: 5 },
    { name: 'Costa Rica', value: 60, region: 5 },
    { name: 'El Salvador', value: 61, region: 5 },
    { name: 'Guatemala', value: 62, region: 5 },
    { name: 'Honduras', value: 63, region: 5 },
    { name: 'Mexico', value: 64, region: 5 },
    { name: 'Nicaragua', value: 65, region: 5 },
    { name: 'Panama', value: 66, region: 5 },
    { name: 'Argentina', value: 67, region: 6 },
    { name: 'Bolivia', value: 68, region: 6 },
    { name: 'Brazil', value: 69, region: 6 },
    { name: 'Chile', value: 70, region: 6 },
    { name: 'Colombia', value: 71, region: 6 },
    { name: 'Ecuador', value: 72, region: 6 },
    { name: 'French Guiana', value: 73, region: 6 },
    { name: 'Guyana', value: 74, region: 6 },
    { name: 'Paraguay', value: 75, region: 6 },
    { name: 'Peru', value: 76, region: 6 },
    { name: 'Suriname', value: 77, region: 6 },
    { name: 'Uruguay', value: 78, region: 6 },
    { name: 'Venezuela', value: 79, region: 6 },
    { name: 'Australia', value: 80, region: 7 },
    { name: 'New Zealand', value: 81, region: 7 },
    { name: 'Fiji', value: 82, region: 7 },
    { name: 'French Polynesia', value: 83, region: 7 },
    { name: 'Guam', value: 84, region: 7 },
    { name: 'Micronesia', value: 85, region: 7 },
    { name: 'New Caledonia', value: 86, region: 7 },
    { name: 'Papua New Guinea', value: 87, region: 7 },
    { name: 'Samoa', value: 88, region: 7 },
    { name: 'Solomon Islands', value: 89, region: 7 },
    { name: 'Tonga', value: 90, region: 7 },
    { name: 'Vanuatu', value: 91, region: 7 },
    { name: 'Kazakhstan', value: 92, region: 8 },
    { name: 'Kyrgyzstan', value: 93, region: 8 },
    { name: 'Tajikistan', value: 94, region: 8 },
    { name: 'Turkmenistan', value: 95, region: 8 },
    { name: 'Uzbekistan', value: 96, region: 8 },
    { name: 'China', value: 97, region: 9 },
    { name: 'Hong Kong', value: 98, region: 9 },
    { name: 'Macao', value: 99, region: 9 },
    { name: 'Mongolia', value: 100, region: 9 },
    { name: 'North korea', value: 101, region: 9 },
    { name: 'South Korea', value: 102, region: 9 },
    { name: 'Japan', value: 103, region: 9 },
    { name: 'Brunei Darussalam', value: 104, region: 10 },
    { name: 'Cambodia', value: 105, region: 10 },
    { name: 'Indonesia', value: 106, region: 10 },
    { name: 'Laos', value: 107, region: 10 },
    { name: 'Malaysia', value: 108, region: 10 },
    { name: 'Myanmar', value: 109, region: 10 },
    { name: 'Philippines', value: 110, region: 10 },
    { name: 'Singapore', value: 111, region: 10 },
    { name: 'Thailand', value: 112, region: 10 },
    { name: 'Timor-Leste', value: 113, region: 10 },
    { name: 'Vietnam', value: 114, region: 10 },
    { name: 'Afghanistan', value: 115, region: 11 },
    { name: 'Bangladesh', value: 116, region: 11 },
    { name: 'Bhutan', value: 117, region: 11 },
    { name: 'India', value: 118, region: 11 },
    { name: 'Iran', value: 119, region: 11 },
    { name: 'Maldives', value: 120, region: 11 },
    { name: 'Nepal', value: 121, region: 11 },
    { name: 'Pakistan', value: 122, region: 11 },
    { name: 'Sri Lanka', value: 123, region: 11 },
    { name: 'Armenia', value: 124, region: 12 },
    { name: 'Azerbaijan', value: 125, region: 12 },
    { name: 'Bahrain', value: 126, region: 12 },
    { name: 'Cyprus', value: 127, region: 12 },
    { name: 'Georgia', value: 128, region: 12 },
    { name: 'Iraq', value: 129, region: 12 },
    { name: 'Israel', value: 130, region: 12 },
    { name: 'Jordan', value: 131, region: 12 },
    { name: 'Kuwait', value: 132, region: 12 },
    { name: 'Lebanon', value: 133, region: 12 },
    { name: 'Oman', value: 134, region: 12 },
    { name: 'Qatar', value: 135, region: 12 },
    { name: 'Saudi Arabia', value: 136, region: 12 },
    { name: 'Syrian Arab Republic', value: 137, region: 12 },
    { name: 'Turkey', value: 138, region: 12 },
    { name: 'United Arab Emirates', value: 139, region: 12 },
    { name: 'Yemen', value: 140, region: 12 },
    { name: 'Algeria', value: 141, region: 13 },
    { name: 'Egypt', value: 142, region: 13 },
    { name: 'Libya', value: 143, region: 13 },
    { name: 'Morocco', value: 144, region: 13 },
    { name: 'Tunisia', value: 145, region: 13 },
    { name: 'Western Sahara', value: 146, region: 13 },
    { name: 'Burundi', value: 147, region: 14 },
    { name: 'Comoros', value: 148, region: 14 },
    { name: 'Djibouti', value: 149, region: 14 },
    { name: 'Eritrea', value: 150, region: 14 },
    { name: 'Ethiopia', value: 151, region: 14 },
    { name: 'Kenya', value: 152, region: 14 },
    { name: 'Madagascar', value: 153, region: 14 },
    { name: 'Malawi', value: 154, region: 14 },
    { name: 'Mauritius', value: 155, region: 14 },
    { name: 'Mayotte', value: 156, region: 14 },
    { name: 'Mozambique', value: 157, region: 14 },
    { name: 'Réunion', value: 158, region: 14 },
    { name: 'Rwanda', value: 159, region: 14 },
    { name: 'Somalia', value: 160, region: 14 },
    { name: 'Sudan', value: 161, region: 14 },
    { name: 'Uganda', value: 162, region: 14 },
    { name: 'United Republic of Tanzania', value: 163, region: 14 },
    { name: 'Zambia', value: 164, region: 14 },
    { name: 'Zimbabwe', value: 165, region: 14 },
    { name: 'Angola', value: 166, region: 15 },
    { name: 'Cameroon', value: 167, region: 15 },
    { name: 'Central African Republic', value: 168, region: 15 },
    { name: 'Chad', value: 169, region: 15 },
    { name: 'Congo', value: 170, region: 15 },
    { name: 'Democratic Republic of the Congo', value: 171, region: 15 },
    { name: 'Equatorial Guinea', value: 172, region: 15 },
    { name: 'Gabon', value: 173, region: 15 },
    { name: 'Sao Tome and Principe', value: 174, region: 15 },
    { name: 'Botswana', value: 175, region: 16 },
    { name: 'Lesotho', value: 176, region: 16 },
    { name: 'Namibia', value: 177, region: 16 },
    { name: 'South Africa', value: 178, region: 16 },
    { name: 'Swaziland', value: 179, region: 16 },
    { name: 'Benin', value: 180, region: 17 },
    { name: 'Burkina Faso', value: 181, region: 17 },
    { name: 'Cape Verde', value: 182, region: 17 },
    { name: 'Côte dIvoire', value: 183, region: 17 },
    { name: 'Gambia', value: 184, region: 17 },
    { name: 'Ghana', value: 185, region: 17 },
    { name: 'Guinea', value: 186, region: 17 },
    { name: 'Guinea-Bissau', value: 187, region: 17 },
    { name: 'Liberia', value: 188, region: 17 },
    { name: 'Mali', value: 189, region: 17 },
    { name: 'Mauritania', value: 190, region: 17 },
    { name: 'Niger', value: 191, region: 17 },
    { name: 'Nigeria', value: 192, region: 17 },
    { name: 'Senegal', value: 193, region: 17 },
    { name: 'Sierra Leone', value: 194, region: 17 },
    { name: 'Togo', value: 195, region: 17 }
  ],
  [OPTION_KEYS.PHONE_CODES]: [
    { name: 'DEU', value: '+49', country: 1 },
    { name: 'AUT', value: '+43', country: 2 },
    { name: 'CHE', value: '+41', country: 3 },
    { name: 'DOM', value: '+1809,+1829,+1849', country: 4 },
    { name: 'BGR', value: '+359', country: 5 }
  ],
  [OPTION_KEYS.GEOGRAPHIES]: [
    { name: 'Western Europe', value: 1 },
    { name: 'Eastern Europe', value: 2 },
    { name: 'North America', value: 3 },
    { name: 'Caribbean', value: 4 },
    { name: 'Central America', value: 5 },
    { name: 'South America', value: 6 },
    { name: 'Oceania', value: 7 },
    { name: 'Central Asia', value: 8 },
    { name: 'Eastern Asia', value: 9 },
    { name: 'South-Eastern Asia', value: 10 },
    { name: 'Southern Asia', value: 11 },
    { name: 'Western Asia', value: 12 },
    { name: 'Northern Africa', value: 13 },
    { name: 'Eastern Africa', value: 14 },
    { name: 'Middle Africa', value: 15 },
    { name: 'Southern Africa', value: 16 },
    { name: 'Western Africa', value: 17 }
  ],
  [OPTION_KEYS.COMPANY_TYPES]: [
    { name: 'Private equity firm', value: 1 },
    { name: 'Investment bank', value: 2 },
    { name: 'Corporate', value: 3 },
    { name: 'Other', value: 4 }
  ],
  [OPTION_KEYS.EQUITY_STAKES]: [
    { name: 'Minority stake', value: 1 },
    { name: 'Majority stake', value: 2 }
  ],
  [OPTION_KEYS.SITUATIONS]: [
    { name: 'Succession', value: 1 },
    { name: 'Management buy-in', value: 2 },
    { name: 'Management buyout', value: 3 },
    { name: 'Leveraged buyout', value: 4 },
    { name: 'Mid-market', value: 5 },
    { name: 'Buy and build / consolidation', value: 6 },
    { name: 'Growth', value: 7 },
    { name: 'Public to private', value: 8 },
    { name: 'Public', value: 9 },
    { name: 'Mezzanine', value: 10 },
    { name: 'Carve-out', value: 11 },
    { name: 'Divesture', value: 12 },
    { name: 'Distressed', value: 13 },
    { name: 'Restructuring', value: 14 },
    { name: 'Recapitalisation', value: 15 },
    { name: 'Insolvencies', value: 16 },
    { name: 'Partnerships', value: 17 },
    { name: 'Joint ventures', value: 18 },
    { name: 'Other', value: 19 }
  ],
  [OPTION_KEYS.INDUSTRIES]: [
    { name: 'Aerospace', value: 1 },
    { name: 'Defence', value: 2 },
    { name: 'Government', value: 3 },
    { name: 'Automotive', value: 4 },
    { name: 'Automotive supply', value: 5 },
    { name: 'Transport & logistics', value: 6 },
    { name: 'Real Estate', value: 7 },
    { name: 'Construction', value: 8 },
    { name: 'Infrastructure', value: 9 },
    { name: 'Mechanical & plant engineering', value: 10 },
    { name: 'Electrical engineering', value: 11 },
    { name: 'Electronics', value: 12 },
    { name: 'Chemicals', value: 13 },
    { name: 'Specialty chemicals', value: 14 },
    { name: 'Special Materials', value: 15 },
    { name: 'Mining', value: 16 },
    { name: 'Industrials', value: 17 },
    { name: 'Manufacturing', value: 18 },
    { name: 'Specialty manufacturing', value: 19 },
    { name: 'Pharmaceuticals', value: 20 },
    { name: 'Healthcare & life science', value: 21 },
    { name: 'Education', value: 22 },
    { name: 'Consumer & retail', value: 23 },
    { name: 'Luxury', value: 24 },
    { name: 'E-Commerce', value: 25 },
    { name: 'Fast moving consumer goods', value: 26 },
    { name: 'Tourism', value: 27 },
    { name: 'Franchise', value: 28 },
    { name: 'Trade & wholesale', value: 29 },
    { name: 'Business services', value: 30 },
    { name: 'Agriculture', value: 31 },
    { name: 'Food & beverage', value: 32 },
    { name: 'Distribution', value: 33 },
    { name: 'Automation', value: 34 },
    { name: 'Software', value: 35 },
    { name: 'Telecommunication', value: 36 },
    { name: 'Media', value: 37 },
    { name: 'Sports', value: 38 },
    { name: 'Entertainment', value: 39 },
    { name: 'Technology', value: 40 },
    { name: 'Medical technology', value: 41 },
    { name: 'Industrial technology', value: 42 },
    { name: 'Financial technology', value: 43 },
    { name: 'Real Estate technology', value: 44 },
    { name: 'Healthcare technology', value: 45 },
    { name: 'Robotics', value: 46 },
    { name: 'Mobility', value: 47 },
    { name: 'Smart industries', value: 48 },
    { name: 'Digitalisation', value: 49 },
    { name: 'Data & business services', value: 50 },
    { name: 'IT services', value: 51 },
    { name: 'Cybersecurity', value: 52 },
    { name: 'Security', value: 53 },
    { name: 'Financial services', value: 54 },
    { name: 'Insurance', value: 55 },
    { name: 'Energy', value: 56 },
    { name: 'Renewables', value: 57 },
    { name: 'Non-profit', value: 58 },
    { name: 'Industry agnostic', value: 59 },
    { name: 'Other', value: 60 }
  ]
};

// const FUND_ENTRIES = [];
// uncomment to simulate fund entries
const FUND_ENTRIES = [
  {
    id: 'aaa-bbb',
    [FUND_FIELDS.NAME.NAME]: '————————————————————',
    [FUND_FIELDS.VINTAGE.NAME]: '2023',
    [FUND_FIELDS.VOLUME.NAME]: 100,
    [FUND_FIELDS.DESCRIPTION.NAME]: 'Blah blah blah',
    [FUND_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][4].value],
    [FUND_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][0].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][11].value
    ],
    [FUND_FIELDS.SITUATION.NAME]: [
      OPTIONS[OPTION_KEYS.SITUATIONS][0].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][1].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][7].value
    ],
    [FUND_FIELDS.EQUITY.NAME]: [
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value,
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value
    ],
    [FUND_FIELDS.REVENUE_RANGE_MIN.NAME]: 10,
    [FUND_FIELDS.REVENUE_RANGE_MAX.NAME]: 20,
    [FUND_FIELDS.EBITDA_RANGE_MIN.NAME]: 10,
    [FUND_FIELDS.EBITDA_RANGE_MAX.NAME]: 20,
    [FUND_FIELDS.EBITDA_MARGIN.NAME]: 0.25,
    [FUND_FIELDS.EBIT_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.EBIT_RANGE_MAX.NAME]: 200,
    [FUND_FIELDS.EBIT_MARGIN.NAME]: 0.5,
    [FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 1_100,
    [FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 1_200,
    [FUND_FIELDS.EQUITY_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.EQUITY_RANGE_MAX.NAME]: 200
  },
  {
    id: 'ddd-eee',
    [FUND_FIELDS.NAME.NAME]: 'W—W—W—W—W—W—W—W—W—W—',
    [FUND_FIELDS.VINTAGE.NAME]: '2021',
    [FUND_FIELDS.VOLUME.NAME]: 80,
    [FUND_FIELDS.DESCRIPTION.NAME]: 'Blah blah',
    [FUND_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][2].value],
    [FUND_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][19].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][2].value
    ],
    [FUND_FIELDS.SITUATION.NAME]: [
      OPTIONS[OPTION_KEYS.SITUATIONS][1].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][7].value
    ],
    [FUND_FIELDS.EQUITY.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value],
    [FUND_FIELDS.REVENUE_RANGE_MIN.NAME]: 40,
    [FUND_FIELDS.REVENUE_RANGE_MAX.NAME]: 50,
    [FUND_FIELDS.EBITDA_RANGE_MIN.NAME]: 50,
    [FUND_FIELDS.EBITDA_RANGE_MAX.NAME]: 60,
    [FUND_FIELDS.EBITDA_MARGIN.NAME]: 0.16,
    [FUND_FIELDS.EBIT_RANGE_MIN.NAME]: 120,
    [FUND_FIELDS.EBIT_RANGE_MAX.NAME]: 130,
    [FUND_FIELDS.EBIT_MARGIN.NAME]: 0.3,
    [FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 120,
    [FUND_FIELDS.EQUITY_RANGE_MIN.NAME]: 115,
    [FUND_FIELDS.EQUITY_RANGE_MAX.NAME]: 120
  },
  {
    id: 'bbb-ccc',
    [FUND_FIELDS.NAME.NAME]: 'WWWWWWWWWWWWWWWWWWWW',
    [FUND_FIELDS.VINTAGE.NAME]: '2022',
    [FUND_FIELDS.VOLUME.NAME]: 100,
    [FUND_FIELDS.DESCRIPTION.NAME]: 'Blah blah blah BLAH',
    [FUND_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][1].value],
    [FUND_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][22].value
    ],
    [FUND_FIELDS.SITUATION.NAME]: [
      OPTIONS[OPTION_KEYS.SITUATIONS][7].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][1].value
    ],
    [FUND_FIELDS.EQUITY.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value],
    [FUND_FIELDS.REVENUE_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.REVENUE_RANGE_MAX.NAME]: 150,
    [FUND_FIELDS.EBITDA_RANGE_MIN.NAME]: 120,
    [FUND_FIELDS.EBITDA_RANGE_MAX.NAME]: 130,
    [FUND_FIELDS.EBITDA_MARGIN.NAME]: 0.2,
    [FUND_FIELDS.EBIT_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.EBIT_RANGE_MAX.NAME]: 200,
    [FUND_FIELDS.EBIT_MARGIN.NAME]: 0.4,
    [FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 200,
    [FUND_FIELDS.EQUITY_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.EQUITY_RANGE_MAX.NAME]: 101
  },
  {
    id: 'eee-fff',
    [FUND_FIELDS.NAME.NAME]: 'Fund 4',
    [FUND_FIELDS.VINTAGE.NAME]: '2021',
    [FUND_FIELDS.VOLUME.NAME]: 80,
    [FUND_FIELDS.DESCRIPTION.NAME]: 'Blah blah',
    [FUND_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][2].value],
    [FUND_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][19].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][2].value
    ],
    [FUND_FIELDS.SITUATION.NAME]: [
      OPTIONS[OPTION_KEYS.SITUATIONS][1].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][7].value
    ],
    [FUND_FIELDS.EQUITY.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value],
    [FUND_FIELDS.REVENUE_RANGE_MIN.NAME]: 40,
    [FUND_FIELDS.REVENUE_RANGE_MAX.NAME]: 50,
    [FUND_FIELDS.EBITDA_RANGE_MIN.NAME]: 50,
    [FUND_FIELDS.EBITDA_RANGE_MAX.NAME]: 60,
    [FUND_FIELDS.EBITDA_MARGIN.NAME]: 0.16,
    [FUND_FIELDS.EBIT_RANGE_MIN.NAME]: 120,
    [FUND_FIELDS.EBIT_RANGE_MAX.NAME]: 130,
    [FUND_FIELDS.EBIT_MARGIN.NAME]: 0.3,
    [FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 100,
    [FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 120,
    [FUND_FIELDS.EQUITY_RANGE_MIN.NAME]: 115,
    [FUND_FIELDS.EQUITY_RANGE_MAX.NAME]: 120
  }
];

// const PORTFOLIO_COMPANIES_ENTRIES = [];
// uncomment to simulate portfolio companies entries
const PORTFOLIO_COMPANIES_ENTRIES = [
  {
    id: 'aaa-bbb',
    [PORTFOLIO_FIELDS.NAME.NAME]: 'MAXTEXTSINGLEWORDDDD',
    [PORTFOLIO_FIELDS.REVENUE.NAME]: 100,
    [PORTFOLIO_FIELDS.EBITDA.NAME]: 100,
    [PORTFOLIO_FIELDS.DESCRIPTION.NAME]: 'Blah blah blah',
    [PORTFOLIO_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][4].value],
    [PORTFOLIO_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][11].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][19].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][5].value
    ],
    [PORTFOLIO_FIELDS.SITUATION.NAME]: [
      OPTIONS[OPTION_KEYS.SITUATIONS][0].value,
      OPTIONS[OPTION_KEYS.SITUATIONS][1].value
    ],
    [PORTFOLIO_FIELDS.EQUITY.NAME]: [
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value,
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value
    ],
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME]: 100,
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]: 200,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME]: 113,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]: 114,
    [PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME]: 0.251515151,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MIN.NAME]: 25,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MAX.NAME]: 30,
    [PORTFOLIO_FIELDS.EBIT_MARGIN.NAME]: 0.5,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 1_150,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 1_550,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME]: 18,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]: 19.56
  },
  {
    id: 'bbb-ccc',
    [PORTFOLIO_FIELDS.NAME.NAME]: 'WWWWWWWWWWWWWWWWWWWW',
    [PORTFOLIO_FIELDS.REVENUE.NAME]: 100,
    [PORTFOLIO_FIELDS.EBITDA.NAME]: 100,
    [PORTFOLIO_FIELDS.DESCRIPTION.NAME]: 'Blah blah blah BLAH',
    [PORTFOLIO_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][5].value],
    [PORTFOLIO_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][2].value
    ],
    [PORTFOLIO_FIELDS.SITUATION.NAME]: [OPTIONS[OPTION_KEYS.SITUATIONS][1].value],
    [PORTFOLIO_FIELDS.EQUITY.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value],
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME]: 112,
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]: 114,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME]: 125,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]: 130,
    [PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME]: 0.2,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MIN.NAME]: 120,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MAX.NAME]: 130,
    [PORTFOLIO_FIELDS.EBIT_MARGIN.NAME]: 0.4,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 145,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 155,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME]: 336,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]: 436
  },
  {
    id: 'ddd-eee',
    [PORTFOLIO_FIELDS.NAME.NAME]: 'PORTFOLIO MAX TEXT W',
    [PORTFOLIO_FIELDS.REVENUE.NAME]: 15,
    [PORTFOLIO_FIELDS.EBITDA.NAME]: 16,
    [PORTFOLIO_FIELDS.DESCRIPTION.NAME]: 'Blah blah',
    [PORTFOLIO_FIELDS.GEOGRAPHIES.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][6].value],
    [PORTFOLIO_FIELDS.INDUSTRIES.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][2].value
    ],
    [PORTFOLIO_FIELDS.SITUATION.NAME]: [OPTIONS[OPTION_KEYS.SITUATIONS][1].value],
    [PORTFOLIO_FIELDS.EQUITY.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value],
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME]: 14,
    [PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]: 15,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME]: 16,
    [PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]: 17,
    [PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME]: 0.1,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MIN.NAME]: 10,
    [PORTFOLIO_FIELDS.EBIT_RANGE_MAX.NAME]: 20,
    [PORTFOLIO_FIELDS.EBIT_MARGIN.NAME]: 0.3,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME]: 10,
    [PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]: 20,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME]: 12,
    [PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]: 13
  }
];

// const SAVED_SEARCH_RESULTS = [];
// uncomment to simulate saved search result entries
const SAVED_SEARCH_RESULTS = [
  {
    id: 'ssr-1',
    [SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.NAME]: 26,
    [SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]: new Date().getTime() - 86_400_000 * 2,
    [SEARCH_FIELDS.SEARCH_TYPE.NAME]: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds,
    [SEARCH_SAVE_FIELDS.NAME.NAME]: 'Saved search 1',
    [SEARCH_FIELDS.INDUSTRY.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][0].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value
    ],
    [SEARCH_FIELDS.HEADQUARTER.NAME]: OPTIONS[OPTION_KEYS.COUNTRIES][0].value,
    [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]: [
      OPTIONS[OPTION_KEYS.GEOGRAPHIES][0].value,
      OPTIONS[OPTION_KEYS.GEOGRAPHIES][1].value
    ],
    [SEARCH_FIELDS.REVENUE.NAME]: 10.565656,
    [SEARCH_FIELDS.EBITDA.NAME]: 8.2,
    [SEARCH_FIELDS.EBITDA_MARGIN.NAME]: 0.869,
    [SEARCH_FIELDS.EBIT.NAME]: 5,
    [SEARCH_FIELDS.EBIT_MARGIN.NAME]: 3.6,
    [SEARCH_FIELDS.EQUITY_STAKE.NAME]: [
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value,
      OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value
    ],
    [SEARCH_FIELDS.SITUATION.NAME]: OPTIONS[OPTION_KEYS.SITUATIONS][0].value,
    [SEARCH_FIELDS.ENTERPRISE_MIN.NAME]: 1_100,
    [SEARCH_FIELDS.ENTERPRISE_MAX.NAME]: 1_500,
    [SEARCH_FIELDS.EQUITY_MIN.NAME]: 400,
    [SEARCH_FIELDS.EQUITY_MAX.NAME]: 500
  },
  {
    id: 'ssr-2',
    [SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.NAME]: 5,
    [SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]: new Date().getTime() - 86_400_000,
    [SEARCH_FIELDS.SEARCH_TYPE.NAME]: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds,
    [SEARCH_SAVE_FIELDS.NAME.NAME]: 'Saved search 2',
    [SEARCH_FIELDS.INDUSTRY.NAME]: [
      OPTIONS[OPTION_KEYS.INDUSTRIES][0].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][1].value,
      OPTIONS[OPTION_KEYS.INDUSTRIES][2].value
    ],
    [SEARCH_FIELDS.HEADQUARTER.NAME]: OPTIONS[OPTION_KEYS.COUNTRIES][1].value,
    [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]: [
      OPTIONS[OPTION_KEYS.GEOGRAPHIES][0].value,
      OPTIONS[OPTION_KEYS.GEOGRAPHIES][1].value,
      OPTIONS[OPTION_KEYS.GEOGRAPHIES][2].value
    ],
    [SEARCH_FIELDS.REVENUE.NAME]: 10.565656,
    [SEARCH_FIELDS.EBITDA.NAME]: 8.2,
    [SEARCH_FIELDS.EBITDA_MARGIN.NAME]: 0.869,
    [SEARCH_FIELDS.EBIT.NAME]: 5,
    [SEARCH_FIELDS.EBIT_MARGIN.NAME]: 3.6,
    [SEARCH_FIELDS.EQUITY_STAKE.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][0].value],
    [SEARCH_FIELDS.SITUATION.NAME]: OPTIONS[OPTION_KEYS.SITUATIONS][1].value,
    [SEARCH_FIELDS.ENTERPRISE_MIN.NAME]: 1,
    [SEARCH_FIELDS.ENTERPRISE_MAX.NAME]: 2,
    [SEARCH_FIELDS.EQUITY_MIN.NAME]: 1,
    [SEARCH_FIELDS.EQUITY_MAX.NAME]: 2
  },
  {
    id: 'ssr-3',
    [SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.NAME]: 50,
    [SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]: new Date().getTime() - 86_400_000 * 3,
    [SEARCH_FIELDS.SEARCH_TYPE.NAME]: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Portfolios,
    [SEARCH_SAVE_FIELDS.NAME.NAME]: 'Saved search 3',
    [SEARCH_FIELDS.INDUSTRY.NAME]: [OPTIONS[OPTION_KEYS.INDUSTRIES][0].value],
    [SEARCH_FIELDS.HEADQUARTER.NAME]: OPTIONS[OPTION_KEYS.COUNTRIES][2].value,
    [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]: [OPTIONS[OPTION_KEYS.GEOGRAPHIES][0].value],
    [SEARCH_FIELDS.REVENUE.NAME]: 10.565656,
    [SEARCH_FIELDS.EBITDA.NAME]: 8.2,
    [SEARCH_FIELDS.EBITDA_MARGIN.NAME]: 0.869,
    [SEARCH_FIELDS.EBIT.NAME]: 5,
    [SEARCH_FIELDS.EBIT_MARGIN.NAME]: 3.6,
    [SEARCH_FIELDS.EQUITY_STAKE.NAME]: [OPTIONS[OPTION_KEYS.EQUITY_STAKES][1].value],
    [SEARCH_FIELDS.SITUATION.NAME]: OPTIONS[OPTION_KEYS.SITUATIONS][2].value,
    [SEARCH_FIELDS.ENTERPRISE_MIN.NAME]: 1,
    [SEARCH_FIELDS.ENTERPRISE_MAX.NAME]: 2,
    [SEARCH_FIELDS.EQUITY_MIN.NAME]: 1,
    [SEARCH_FIELDS.EQUITY_MAX.NAME]: 2
  }
];

// let COMPANY_PROFILE = {
//   [COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]: 'test company',
//   [COMPANY_PROFILE_FIELDS.COMPANY_TYPE.NAME]: OPTIONS[OPTION_KEYS.COMPANY_TYPES][0].value
// };
// uncomment to simulate filled-in Company Profile
let COMPANY_PROFILE = {
  [COMPANY_PROFILE_FIELDS.CITY.NAME]: 'Berlin',
  [COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]: 'test company',
  [COMPANY_PROFILE_FIELDS.COMPANY_TYPE.NAME]: OPTIONS[OPTION_KEYS.COMPANY_TYPES][0].value,
  [COMPANY_PROFILE_FIELDS.CONTACT_EMAIL.NAME]: 'test@test.test',
  [COMPANY_PROFILE_FIELDS.COUNTRY.NAME]: OPTIONS[OPTION_KEYS.COUNTRIES][0].value,
  [COMPANY_PROFILE_FIELDS.PHONE_NUMBER.NAME]: '+491711234567',
  [COMPANY_PROFILE_FIELDS.TAGLINE.NAME]: 'tag'
};

const setCompanyProfile = (data) => {
  COMPANY_PROFILE = JSON.parse(JSON.stringify(data));
};

let USER_PROFILE = {
  [USER_PROFILE_FIELDS.FIRST_NAME.NAME]: 'Angel',
  [USER_PROFILE_FIELDS.LAST_NAME.NAME]: 'Zlatanov',
  [USER_PROFILE_FIELDS.EMPLOYER.NAME]: 'test company',
  [USER_PROFILE_FIELDS.EMAIL.NAME]: 'whatever@whatever.com',
  [USER_PROFILE_FIELDS.PHONE_NUMBER.NAME]: '+359888861815',
  [USER_PROFILE_FIELDS.CITY.NAME]: null,
  [USER_PROFILE_FIELDS.COUNTRY.NAME]: null
};

const setUserProfile = (data) => {
  USER_PROFILE = JSON.parse(JSON.stringify(data));
};

export {
  OPTIONS,
  FUND_ENTRIES,
  PORTFOLIO_COMPANIES_ENTRIES,
  SAVED_SEARCH_RESULTS,
  COMPANY_PROFILE,
  setCompanyProfile,
  USER_PROFILE,
  setUserProfile
};
