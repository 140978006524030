import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { useEffect } from 'react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import MobileFilters from './components/MobileFilters';
import {
  SEARCH_FIELDS,
  getErrorFields,
  PORTFOLIO_FIELDS,
  FUND_FIELDS,
  compareFields,
  OUTREACH_FIELDS,
  trimStateFields
} from '../../../utils/constants/fields';
import { getFilledSearchCategoriesCount, mapFieldsToState } from '../../../utils/utils';
import { mapData } from '../../../api/dataMappers';
import FundResult from './components/FundResult';
import PortfolioResult from './components/PortfolioResult';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { SEARCH_CATEGORIES } from '../../../utils/constants/searchCategories';
import { runInAction } from 'mobx';
import { DETAILS_POUP_TABS } from './constants/tabs';
import DetailsPopup from './components/DetailsPopup';
import MessagePopup from './components/MessagePopup';
import DesktopFilters from './components/DesktopFilters';
import { getResultSearchFields } from './constants/resultSearchFields';
import SaveSearchPopup from './components/SaveSearchPopup';
import NoResults from './components/NoResults';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';
import { Link } from 'react-router-dom';

const pageSize = 8;

const SearchResults = observer(() => {
  const { navigate, location } = useHistory();
  const { searchStore, makeRequest } = useStore();

  const state = useLocalObservable(() => ({
    isRendered: false,
    setIsRendered: (value = false) => (state.isRendered = value),
    searchType: null,
    results: [],
    totalResults: 0,
    searchId: null,
    hasNext: false,
    hasPrev: false,
    isLoading: false,
    isInitialLoading: true,
    isMobileFiltersDisplayed: false,
    toggleMobileFilters: () => {
      state.isMobileFiltersDisplayed = !state.isMobileFiltersDisplayed;
    },
    detailsPopupDisplayed: null,
    displayDetailsPopup: (entry) => {
      state.detailsPopupDisplayed = entry;
    },
    detailsPopupSelectedTab: DETAILS_POUP_TABS.GENERAL,
    setDetailsPopupSelectedTab: (value) => {
      state.detailsPopupSelectedTab = value;
    },
    messagePopupDisplayed: null,
    displayMessagePopup: (entry) => {
      state.messagePopupDisplayed = entry;
    },
    saveSearchPopupDisplayed: null,
    displaySaveSearchPopup: (entry) => {
      state.saveSearchPopupDisplayed = entry;
    },
    resultsError: null,
    failedReqParams: null,
    getResults: (searchFields = {}, asc = true, from = 0, searchId = null) => {
      state.isLoading = true;
      state.resultsError = false;
      state.searchId = null;
      const body = { ...mapData(searchFields, SEARCH_FIELDS, true), asc, from, pageSize };
      if (['number', 'string'].includes(typeof searchId)) {
        body.searchId = searchId;
      }

      makeRequest({
        endpoint: API_ENDPOINTS.GET_SEARCH_RESULTS,
        body,
        onSuccess: ({ data, hasNext, total, searchId: newSearchId }) => {
          if (!state.isRendered) {
            return;
          }

          const mapFields =
            state.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
              ? FUND_FIELDS
              : PORTFOLIO_FIELDS;

          state.currentSearch = JSON.parse(JSON.stringify(searchFields));
          state.searchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME];
          state.failedReqParams = null;
          state.results = mapData(data, mapFields);
          state.totalResults = total;
          state.searchId = newSearchId;
          if (!asc) {
            // going back
            state.results.reverse();
            state.hasPrev = hasNext;
            state.hasNext = true;
          } else {
            // loading page 1 or going next
            if (from === 0) {
              // loading page1
              state.hasPrev = false;
              state.hasNext = hasNext;
            } else {
              // going next
              state.hasPrev = true;
              state.hasNext = hasNext;
            }
          }
        },
        onError: (errorMessage) => {
          if (!state.isRendered) {
            return;
          }

          state.resultsError = errorMessage || 'Failed to obtain search results';
          state.totalResults = 0;
          state.hasNext = false;
          state.hasPrev = false;
          state.failedReqParams = JSON.parse(JSON.stringify([searchFields, asc, from, searchId]));
        },
        onFinally: () => {
          if (!state.isRendered) {
            return;
          }

          state.isLoading = false;
          if (state.isInitialLoading) {
            state.isInitialLoading = false;
          }
        }
      });
    },
    fundsInAction: {},
    portfoliosInAction: {},
    currentSearch: {},
    fields: mapFieldsToState(SEARCH_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    get fieldsCount() {
      return getFilledSearchCategoriesCount(state.fields);
    },
    searchSectionsToggle: Object.fromEntries(
      Object.values(SEARCH_CATEGORIES).map((section) => [section, false])
    ),
    toggleSearchSection: (section) => {
      state.searchSectionsToggle[section] = !state.searchSectionsToggle[section];
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    isSavingSearch: false,
    get validationFields() {
      return getErrorFields(Object.values(SEARCH_FIELDS), state.fields);
    },
    get isSearchDisabled() {
      return (
        state.isLoading ||
        state.isInitialLoading ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
        !compareFields(state.fields, state.currentSearch)
      );
    },
    onSearch: (e) => {
      e?.preventDefault?.();

      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      if (state.isMobileFiltersDisplayed) {
        state.toggleMobileFilters();
      }

      const fieldsParams = Object.entries(state.fields)
        .filter((field) => field[1].length)
        .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
        .join('&');

      navigate(paths.SEARCH_RESULTS + `?${fieldsParams}`);
      document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
    },
    onPaging: (next = true) => {
      state.getResults(
        state.currentSearch,
        next,
        next ? state.results[state.results.length - 1].id : state.results[0].id,
        state.searchId
      );
    },
    onSubmitMessage: (id, subject, message, onSuccess, onError) => {
      const isFund = state.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
      const actionObjKey = isFund ? 'fundsInAction' : 'portfoliosInAction';
      const actionObj = state[actionObjKey];

      actionObj[id] = true;
      makeRequest({
        endpoint: API_ENDPOINTS.POST_OUTREACH_MESSAGE,
        body: {
          [SEARCH_FIELDS.SEARCH_TYPE.NAME]: state.currentSearch[SEARCH_FIELDS.SEARCH_TYPE.NAME],
          id,
          [OUTREACH_FIELDS.SUBJECT.NAME]: subject,
          [OUTREACH_FIELDS.MESSAGE.NAME]: message
        },
        onSuccess,
        onError,
        onFinally: () => {
          actionObj[id] = false;
        }
      });
    },
    onSaveSearch: (searchName, onSuccess, onError, onFinally) => {
      state.isSavingSearch = true;
      searchStore.saveSearch(searchName, state.currentSearch, onSuccess, onError, () => {
        state.isSavingSearch = false;
        onFinally();
      });
    }
  }));

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let hasInvalidParam = false;
    let searchFields = null;
    try {
      searchFields = Object.values(SEARCH_FIELDS).reduce((fields, f) => {
        fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
        if (f.REQUIRED && fields[f.NAME] === null) {
          hasInvalidParam = true;
        }
        return fields;
      }, {});
    } catch (err) {
      hasInvalidParam = true;
    }

    if (hasInvalidParam) {
      runInAction(() => {
        state.resultsError = 'Oops. It seems that invalid search params were found in the URL.';
        state.totalResults = 0;
        state.hasNext = false;
        state.hasPrev = false;
        if (state.isInitialLoading) {
          state.isInitialLoading = false;
        }
      });
      return;
    } else {
      if (!state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME]) {
        runInAction(() => {
          const initial = mapFieldsToState(SEARCH_FIELDS);
          Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
            state.fields[fieldName] = fieldValue || initial[fieldName];
          });
        });
      }

      state.getResults(searchFields, true, 0);
    }
  }, [state, location.search]);

  const isTypeSelected = !!state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME];
  const fields = getResultSearchFields(state, isTypeSelected);

  const isLoading = state.isLoading || state.isInitialLoading;
  const hasResults = !!state.totalResults;
  const hasError = !!state.resultsError;
  const showDetailsPopup = !!state.detailsPopupDisplayed;
  const showMessagePopup = !!state.messagePopupDisplayed;
  const showSaveSearchPopup = !!state.saveSearchPopupDisplayed;
  const showNoResults = !isLoading && !hasError && !hasResults;
  const showResults = !isLoading && !hasError && hasResults;
  const showPaging = showResults && state.totalResults > pageSize;
  const showDownload = ['number', 'string'].includes(typeof state.searchId) && hasResults;

  return (
    <StaticPageLayout page="search-listing" hideMobileFooter={true}>
      {showDetailsPopup && (
        <DetailsPopup
          searchType={state.searchType}
          detailsPopupDisplayed={state.detailsPopupDisplayed}
          detailsPopupSelectedTab={state.detailsPopupSelectedTab}
          setDetailsPopupSelectedTab={state.setDetailsPopupSelectedTab}
          displayDetailsPopup={state.displayDetailsPopup}
        />
      )}
      {showMessagePopup && (
        <MessagePopup
          messagePopupDisplayed={state.messagePopupDisplayed}
          displayMessagePopup={state.displayMessagePopup}
          submitMessage={state.onSubmitMessage}
          actionObj={
            state.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
              ? state.fundsInAction
              : state.portfoliosInAction
          }
        />
      )}
      {showSaveSearchPopup && (
        <SaveSearchPopup
          displaySaveSearchPopup={state.displaySaveSearchPopup}
          onSaveSearch={state.onSaveSearch}
        />
      )}
      {state.isMobileFiltersDisplayed && (
        <MobileFilters
          onToggle={state.toggleMobileFilters}
          fields={fields}
          isSearchDisabled={state.isSearchDisabled}
          onSearch={state.onSearch}
        />
      )}
      {!state.isMobileFiltersDisplayed && (
        <div className="listing-layout">
          <DesktopFilters
            fields={fields}
            fieldsCount={state.fieldsCount}
            searchSectionsToggle={state.searchSectionsToggle}
            toggleSearchSection={state.toggleSearchSection}
            onSearch={state.onSearch}
            isSearchDisabled={state.isSearchDisabled}
          />
          <div className="results">
            {/* This should go in a separate component start */}
            <div className="mobile-filters">
              <div className="total-number">
                {(hasError && !state.saveSearchPopupDisplayed) || !state.isInitialLoading
                  ? `${state.totalResults} results`
                  : ' '}
              </div>
              <div className="filters-download-container">
                <div className="filters-btn" onClick={state.toggleMobileFilters}>
                  Filters
                </div>
                <div className="actions">
                  {/* TODO: uncomment Save search for v2 */}
                  {/* <button
                    id="save-reach-btn-mobile"
                    className="btn icon-save-search"
                    onClick={() => state.displaySaveSearchPopup(true)}
                    disabled={!hasResults || state.isSavingSearch}
                  /> */}
                  {showDownload && (
                    <Link
                      className="btn icon-download-search"
                      to={`${process.env.REACT_APP_API_BASEURL}/public/downloadSearch/${state.searchId}`}
                      target="_blank"
                      rel="noopener noreferrer"></Link>
                  )}
                  {!showDownload && <button className="btn icon-download-search" disabled={true} />}
                </div>
              </div>
            </div>

            <div className="results-header">
              <div className="cols">
                <div className="col">
                  {state.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                    ? 'Fund name'
                    : 'Portfolio name'}
                </div>
                <div className="col">Industry focus</div>
                <div className="col">Geo focus</div>
                <div className="col">Enterprise Value</div>
                <div className="col">Equity Value</div>
              </div>
              <div className="actions">
                <div className="total-num">
                  {(hasError && !state.saveSearchPopupDisplayed) || !state.isInitialLoading
                    ? `${state.totalResults} results`
                    : ' '}
                </div>
                <div className="buttons">
                  {/* TODO: uncomment Save search for v2 */}
                  {/* <button
                    id="save-reach-btn"
                    className="btn btn-link-light icon-save-search"
                    onClick={() => state.displaySaveSearchPopup(true)}
                    disabled={!hasResults || state.isSavingSearch}>
                    Save search
                  </button> */}
                  {showDownload && (
                    <Link
                      className="btn btn-link-light icon-download-search"
                      to={`${process.env.REACT_APP_API_BASEURL}/public/downloadSearch/${state.searchId}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      Download search
                    </Link>
                  )}
                  {!showDownload && (
                    <button className="btn btn-link-light icon-download-search" disabled={true}>
                      Download search
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* This should go in a separate component end */}

            {isLoading && <GeneralLoader />}

            {hasError && (
              <GeneralError
                errorMessage={state.resultsError}
                withGoBackButton
                actionMessage="You may want to:"
                {...(state.failedReqParams
                  ? {
                      actionButtontext: 'Try again',
                      onActionButtonClick: () => state.getResults(...state.failedReqParams)
                    }
                  : { withHomePageButton: true })}
              />
            )}

            {showNoResults && <NoResults />}

            {showResults &&
              state.results.map((entry) =>
                state.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
                  <FundResult
                    key={entry.id}
                    entry={entry}
                    displayMessagePopup={state.displayMessagePopup}
                    displayDetailsPopup={state.displayDetailsPopup}
                  />
                ) : (
                  <PortfolioResult
                    key={entry.id}
                    entry={entry}
                    displayMessagePopup={state.displayMessagePopup}
                    displayDetailsPopup={state.displayDetailsPopup}
                  />
                )
              )}
            {showPaging && (
              <div className="paging">
                <button
                  className="btn btn-outline-light"
                  onClick={() => state.onPaging(false)}
                  disabled={!state.hasPrev}>
                  Prev
                </button>
                <button
                  className="btn btn-outline-light"
                  onClick={() => state.onPaging(true)}
                  disabled={!state.hasNext}>
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </StaticPageLayout>
  );
});

export default SearchResults;
