import { useLocalObservable, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { SIGNUP_FIELDS, getErrorFields, trimStateFields } from '../../../utils/constants/fields';
import { useEffect } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { paths } from '../../../utils/constants/routes';
import Input from '../../../components/inputs/Input';
import Select from '../../../components/inputs/Select';
import CheckBox from '../../../components/inputs/CheckBox';
import SignLayout from '../../../components/layouts/SignLayout';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { phone } from 'phone';
import { generateMessage, mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { runInAction } from 'mobx';

const Signup = observer(() => {
  const { authStore, utilsStore, isWaitlistActivated } = useStore();
  const { navigate } = useHistory();

  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(SIGNUP_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    // recaptcha: false,
    // validateRecapatcha: (value) => (state.recaptcha = value),
    step1onSubmitErrorState: false,
    setStep1onSubmitErrorState: (value = false) => (state.step1onSubmitErrorState = value),
    step2onSubmitErrorState: false,
    setStep2onSubmitErrorState: (value = false) => (state.step2onSubmitErrorState = value),
    get step1ValidationFields() {
      const validations = getErrorFields(
        [
          SIGNUP_FIELDS.FIRST_NAME,
          SIGNUP_FIELDS.LAST_NAME,
          SIGNUP_FIELDS.COMPANY_NAME,
          SIGNUP_FIELDS.COMPANY_TYPE,
          SIGNUP_FIELDS.EMAIL,
          SIGNUP_FIELDS.PHONE_CODE,
          SIGNUP_FIELDS.PHONE_NUMBER,
          SIGNUP_FIELDS.PASSWORD,
          SIGNUP_FIELDS.RE_PASSWORD,
          SIGNUP_FIELDS.TOS
        ],
        state.fields,
        [SIGNUP_FIELDS.EMAIL, SIGNUP_FIELDS.TOS, SIGNUP_FIELDS.PASSWORD, SIGNUP_FIELDS.RE_PASSWORD]
      );

      // if (!state.recaptcha) {
      //   validations.invalidFields.push({ name: 'recaptcha' });
      // }

      const code = state.fields[SIGNUP_FIELDS.PHONE_CODE.NAME];
      const _phone = state.fields[SIGNUP_FIELDS.PHONE_NUMBER.NAME];

      const [pwd, repwd] = [
        state.fields[SIGNUP_FIELDS.PASSWORD.NAME],
        state.fields[SIGNUP_FIELDS.RE_PASSWORD.NAME]
      ];
      if (pwd !== repwd) {
        validations.messages.push(
          generateMessage("Passwords don't match", false, SIGNUP_FIELDS.RE_PASSWORD.NAME, true)
        );
        validations.invalidFields.push({
          name: SIGNUP_FIELDS.RE_PASSWORD.NAME,
          isOnSubmit: true
        });
      }

      if (_phone.length) {
        if (!code) {
          validations.messages.push(
            generateMessage(
              'Please select a phone code',
              false,
              SIGNUP_FIELDS.PHONE_NUMBER.NAME,
              true
            )
          );
          validations.invalidFields.push({
            name: SIGNUP_FIELDS.PHONE_NUMBER.NAME,
            isOnSubmit: true
          });
          validations.messages.push(
            generateMessage('', false, SIGNUP_FIELDS.PHONE_CODE.NAME, true)
          );
          validations.invalidFields.push({
            name: SIGNUP_FIELDS.PHONE_CODE.NAME,
            isOnSubmit: true
          });
        }
        // dropping phone validation for now
        // else if (!phone(code + _phone, { strictDetection: true }).isValid) {
        //   validations.messages.push(
        //     generateMessage(
        //       'Please enter valid phone number',
        //       false,
        //       SIGNUP_FIELDS.PHONE_NUMBER.NAME,
        //       true
        //     )
        //   );
        //   validations.invalidFields.push({
        //     name: SIGNUP_FIELDS.PHONE_NUMBER.NAME,
        //     isOnSubmit: true
        //   });
        // }
      } else if (code.length) {
        validations.messages.push(
          generateMessage('Please enter a phone number', false, SIGNUP_FIELDS.PHONE_CODE.NAME, true)
        );
        validations.invalidFields.push({
          name: SIGNUP_FIELDS.PHONE_CODE.NAME,
          isOnSubmit: true
        });
        validations.messages.push(
          generateMessage('', false, SIGNUP_FIELDS.PHONE_NUMBER.NAME, true)
        );
        validations.invalidFields.push({
          name: SIGNUP_FIELDS.PHONE_NUMBER.NAME,
          isOnSubmit: true
        });
      }

      return validations;
    },
    get step2ValidationFields() {
      return getErrorFields([SIGNUP_FIELDS.VERIF_CODE], state.fields);
    }
  }));

  useEffect(() => {
    return () => {
      authStore.cleanUpAuthPage();
    };
  }, [authStore]);

  // const { setFieldValue, fields, recaptcha, validateRecapatcha } = state;
  const { setFieldValue, fields } = state;

  const onSubmitStep1 = (e) => {
    e.preventDefault();
    // if (!recaptcha) {
    //   return;
    // }

    trimStateFields(state.fields);
    if (state.step1ValidationFields.invalidFields.length) {
      if (!state.step1onSubmitErrorState) {
        state.setStep1onSubmitErrorState(true);
      }
      return;
    }

    const cognitoFields = {
      given_name: fields[SIGNUP_FIELDS.FIRST_NAME.NAME],
      family_name: fields[SIGNUP_FIELDS.LAST_NAME.NAME],
      company_name: fields[SIGNUP_FIELDS.COMPANY_NAME.NAME],
      company_type: fields[SIGNUP_FIELDS.COMPANY_TYPE.NAME],
      phone_code: fields[SIGNUP_FIELDS.PHONE_CODE.NAME],
      phone_number: fields[SIGNUP_FIELDS.PHONE_NUMBER.NAME],
      email: fields[SIGNUP_FIELDS.EMAIL.NAME],
      password: fields[SIGNUP_FIELDS.PASSWORD.NAME],
      name: `${fields[SIGNUP_FIELDS.FIRST_NAME.NAME]} ${fields[SIGNUP_FIELDS.LAST_NAME.NAME]}`
      // marketing_consent: fields[SIGNUP_FIELDS.MARKETING_CONSENT.NAME] ? 'true' : 'false'
    };

    // for waitlist we don't have password inputs, set a random password
    // later users will be either password reset or go through the forgotten password flow
    // cognitoFields.password = Math.random().toString(36).slice(-8) + 'Aa1!';

    authStore.signup(cognitoFields);
  };

  const onSubmitStep2 = (e) => {
    e.preventDefault();

    trimStateFields(state.fields);
    if (state.step2ValidationFields.invalidFields.length) {
      if (!state.step2onSubmitErrorState) {
        state.setStep2onSubmitErrorState(true);
      }
      return;
    }

    authStore.confirmSignUp(
      SIGNUP_FIELDS.VERIF_CODE.DEFORMAT(fields[SIGNUP_FIELDS.VERIF_CODE.NAME]),
      fields[SIGNUP_FIELDS.EMAIL.NAME],
      fields[SIGNUP_FIELDS.PASSWORD.NAME]
    );
  };

  const resetEmailCode = (e) => {
    e.preventDefault();
    authStore.resendSignUpCode();
  };

  return (
    <>
      {authStore.signUpStep === 1 && (
        <SignLayout>
          <form onSubmit={onSubmitStep1}>
            <div className="form-body-scroll">
              <div className="form-row-halves">
                <Input
                  {...{
                    field: SIGNUP_FIELDS.FIRST_NAME,
                    value: fields[SIGNUP_FIELDS.FIRST_NAME.NAME],
                    setFieldValue,
                    messages: state.step1ValidationFields.messages,
                    showOnSubmitErrorState: true
                  }}
                />
                <Input
                  {...{
                    field: SIGNUP_FIELDS.LAST_NAME,
                    value: fields[SIGNUP_FIELDS.LAST_NAME.NAME],
                    setFieldValue,
                    messages: state.step1ValidationFields.messages,
                    showOnSubmitErrorState: true
                  }}
                />
              </div>
              <Input
                {...{
                  field: SIGNUP_FIELDS.COMPANY_NAME,
                  value: fields[SIGNUP_FIELDS.COMPANY_NAME.NAME],
                  setFieldValue,
                  messages: state.step1ValidationFields.messages,
                  showOnSubmitErrorState: true
                }}
              />
              <Select
                {...{
                  field: SIGNUP_FIELDS.COMPANY_TYPE,
                  value: fields[SIGNUP_FIELDS.COMPANY_TYPE.NAME],
                  setFieldValue
                }}
              />
              <Input
                {...{
                  field: SIGNUP_FIELDS.EMAIL,
                  value: fields[SIGNUP_FIELDS.EMAIL.NAME],
                  setFieldValue,
                  showOnSubmitErrorState: state.step1onSubmitErrorState,
                  messages: state.step1ValidationFields.messages
                }}
              />
              <Input
                {...{
                  field: SIGNUP_FIELDS.PASSWORD,
                  value: fields[SIGNUP_FIELDS.PASSWORD.NAME],
                  setFieldValue,
                  showOnSubmitErrorState: state.step1onSubmitErrorState,
                  messages: state.step1ValidationFields.messages
                }}
              />
              <Input
                {...{
                  field: SIGNUP_FIELDS.RE_PASSWORD,
                  value: fields[SIGNUP_FIELDS.RE_PASSWORD.NAME],
                  setFieldValue,
                  showOnSubmitErrorState: state.step1onSubmitErrorState,
                  messages: state.step1ValidationFields.messages
                }}
              />
              <div className="form-row-halves mobile-halves">
                <Select
                  {...{
                    field: SIGNUP_FIELDS.PHONE_CODE,
                    value: fields[SIGNUP_FIELDS.PHONE_CODE.NAME],
                    setFieldValue,
                    showOnSubmitErrorState: state.step1onSubmitErrorState,
                    messages: state.step1ValidationFields.messages
                  }}
                />
                <Input
                  {...{
                    field: SIGNUP_FIELDS.PHONE_NUMBER,
                    value: fields[SIGNUP_FIELDS.PHONE_NUMBER.NAME],
                    setFieldValue,
                    showOnSubmitErrorState: state.step1onSubmitErrorState,
                    messages: state.step1ValidationFields.messages
                  }}
                />
              </div>

              <CheckBox
                {...{
                  field: SIGNUP_FIELDS.TOS,
                  value: fields[SIGNUP_FIELDS.TOS.NAME],
                  setFieldValue,
                  showOnSubmitErrorState: state.step1onSubmitErrorState,
                  messages: state.step1ValidationFields.messages
                }}>
                <div>
                  Terms and conditions: I have read and accept the terms & conditions from
                  transact.digital GmbH{' '}
                  <Link to={paths.TERMS_N_CONDITIONS} target="_blank">
                    Read More
                  </Link>
                </div>
              </CheckBox>
              {/* <CheckBox
                {...{
                  field: SIGNUP_FIELDS.MARKETING_CONSENT,
                  value: fields[SIGNUP_FIELDS.MARKETING_CONSENT.NAME],
                  setFieldValue
                }}
              /> */}
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPATCHA_SITE_KEY}
                onChange={validateRecapatcha}
                className="recaptcha"
              /> */}
            </div>

            <div
              className={`form-footer ${utilsStore.isMobileHamburgerOpen ? 'mobile-hidden' : ''}`}>
              <div className="mandatory-text-mobile">*mandatory fields</div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  authStore.isAuthenticating ||
                  state.step1ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
                  (state.step1onSubmitErrorState &&
                    state.step1ValidationFields.invalidFields.length)
                }>
                {authStore.isAuthenticating
                  ? 'Loading...'
                  : isWaitlistActivated
                  ? 'Join the waiting list'
                  : 'Sign up'}
              </button>
              {authStore.authError && <div className="error">{authStore.authError}</div>}
              {!!state.step1ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length &&
                state.step1ValidationFields.messages
                  .filter((m) => !m.isOnSubmit)
                  .map((m, idx) => (
                    <div className={m.isValid ? 'success' : 'error'} key={idx}>
                      {m.msg}
                    </div>
                  ))}
              {authStore.authErrors.messages.map((m, idx) => (
                <div className={m.isValid ? 'success' : 'error'} key={idx}>
                  {m.msg}
                </div>
              ))}
            </div>
          </form>
          <div className="mandatory-text-desktop">*mandatory fields</div>
        </SignLayout>
      )}

      {authStore.signUpStep === 2 && (
        <StaticPageLayout page="signup-layout-confirm-code">
          <form onSubmit={onSubmitStep2}>
            <h4>Please verify your email</h4>
            <div className="check-text">
              <div className="icon-check-circle"></div>
              <div>We have sent a verification code to your email.</div>
            </div>
            <Input
              {...{
                field: SIGNUP_FIELDS.VERIF_CODE,
                value: fields[SIGNUP_FIELDS.VERIF_CODE.NAME],
                setFieldValue
              }}
            />
            <div className="subtext">
              If the email doesn’t show up soon, check your spam folder.
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                authStore.isAuthenticating ||
                state.step2ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length
              }>
              {authStore.isAuthenticating ? 'Loading...' : 'Submit code'}
            </button>
            {authStore.authError && <div className="error">{authStore.authError}</div>}
            {!!state.step2ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length &&
              state.step2ValidationFields.messages
                .filter((m) => !m.isOnSubmit)
                .map((m, idx) => (
                  <div className={m.isValid ? 'success' : 'error'} key={idx}>
                    {m.msg}
                  </div>
                ))}
            {authStore.authErrors.messages.map((m, idx) => (
              <div className={m.isValid ? 'success' : 'error'} key={idx}>
                {m.msg}
              </div>
            ))}
            <div className="resend">
              <a href="" onClick={resetEmailCode}>
                Resend email
              </a>
            </div>
          </form>
        </StaticPageLayout>
      )}
      {authStore.signUpStep === 3 &&
        (isWaitlistActivated ? (
          <StaticPageLayout page="signup-layout-success">
            <div className="title-container">
              <h1>
                You have <span className="action-color">joined the waiting list</span>
                <br /> successfully.
              </h1>
              <h2>We will reach out to you as soon as your information has been verified.</h2>
            </div>
          </StaticPageLayout>
        ) : (
          <StaticPageLayout page="signup-layout-success">
            <div className="title-container">
              <h1>
                You have <span className="action-color">completed your registration</span>
                <br /> successfully.
              </h1>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate(paths.HOME);
                  runInAction(() => {
                    authStore.showLoginVerifyScreen = false;
                  });
                }}
                disabled={authStore.isAuthenticating}>
                Go to login
              </button>
            </div>
          </StaticPageLayout>
        ))}
    </>
  );
});

export default Signup;
