import { REQ_METHODS } from './methods';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../utils/constants/auth';
import {
  FUND_ENTRIES,
  COMPANY_PROFILE,
  setCompanyProfile,
  PORTFOLIO_COMPANIES_ENTRIES,
  OPTIONS,
  USER_PROFILE,
  setUserProfile,
  SAVED_SEARCH_RESULTS
} from './mockData';
import {
  SEARCH_FIELDS,
  SAVED_SEARCH_VIEW_FIELDS,
  FUND_FIELDS,
  PORTFOLIO_FIELDS
} from '../utils/constants/fields';
import { UI_OPTIONS } from '../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../utils/constants/uiOptionKeys';
import { listsPreResponseMapper } from './dataMappers';

export const API_ENDPOINTS = {
  GET_PUBLIC_OPTIONS: {
    path: '/public/nomenclature',
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(OPTIONS));
    // }
  },
  GET_ACCOUNT_VERIFICATION_PROGRESS: {
    path: '/account-verification-progress',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return ACCOUNT_VERIF_TYPES.APPROVED;
    // }
  },
  GET_ACCOUNT_TYPE: {
    path: '/account-type',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return ACCOUNT_TYPES.AGENT;
    // }
  },
  GET_USER_INFO: {
    path: '/user-info',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(USER_PROFILE));
    // }
  },
  EDIT_USER_INFO: {
    path: '/user-info',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   setUserProfile({ ...USER_PROFILE, ...body });
    //   return JSON.parse(JSON.stringify(USER_PROFILE));
    // }
  },
  DEACTIVATE_USER_ACCOUNT: {
    path: '/deactivate-user',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: () => {
    //   return true;
    // }
  },
  GET_PROFILE_COMPANY: {
    path: '/profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.GET
    // mockResponseData: () => {
    //   return JSON.parse(JSON.stringify(COMPANY_PROFILE));
    // }
  },
  EDIT_PROFILE_COMPANY: {
    path: '/profile-company',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   setCompanyProfile({ ...COMPANY_PROFILE, ...body });
    //   return JSON.parse(JSON.stringify(COMPANY_PROFILE));
    // }
  },
  GET_SEARCH_RESULTS: {
    path: '/get-search-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const isFund =
    //     body[SEARCH_FIELDS.SEARCH_TYPE.NAME] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
    //   const list = [];
    //   for (let i = 0; i < 20; i++) {
    //     list.push(
    //       ...JSON.parse(
    //         JSON.stringify(
    //           body[SEARCH_FIELDS.SEARCH_TYPE.NAME] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
    //             ? FUND_ENTRIES
    //             : PORTFOLIO_COMPANIES_ENTRIES
    //         )
    //       )
    //     );
    //   }

    //   const allData = list.map((r, i) => {
    //     r[isFund ? FUND_FIELDS.NAME.NAME : PORTFOLIO_FIELDS.NAME.NAME] = `${
    //       isFund ? 'Fund' : 'Portfolio'
    //     } ${i + 1}`;
    //     r.id = i + 3;
    //     return r;
    //   });

    //   const asc = body.asc;
    //   if (!asc) {
    //     allData.reverse();
    //   }

    //   let index = allData.findIndex((r) => r.id === body.from) + 1;
    //   const results = allData.slice(index, index + body.pageSize);
    //   const hasNext = allData[allData.length - 1].id !== results[results.length - 1].id;
    //   results.forEach((r) => (r.contactInfo = { firstName: 'John', lastName: 'Doe' }));
    //   return {
    //     total: allData.length,
    //     hasNext,
    //     data: results
    //   };
    // }
  },
  GET_SAVED_SEARCH_RESULTS: {
    path: '/saved-search-results',
    withCognitoToken: true,
    method: REQ_METHODS.GET,
    mockResponseData: () => {
      const results = JSON.parse(JSON.stringify(SAVED_SEARCH_RESULTS));
      return results;
    }
  },
  POST_SAVED_SEARCH_RESULTS: {
    path: '/saved-search-results',
    withCognitoToken: true,
    method: REQ_METHODS.POST,
    mockResponseData: (body = {}) => {
      const [minimum, maximum] = [0, 1000];
      const newEntry = {
        ...body,
        id: `ssr-${SAVED_SEARCH_RESULTS.length + 1}`,
        [SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.NAME]:
          Math.floor(Math.random() * (maximum - minimum + 1)) + minimum,
        [SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]: new Date().getTime()
      };
      SAVED_SEARCH_RESULTS.push(newEntry);
      return JSON.parse(JSON.stringify(newEntry));
    }
  },
  POST_OUTREACH_MESSAGE: {
    path: '/outreach',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: () => true
  },
  GET_FUNDS: {
    path: '/funds',
    withCognitoToken: true,
    method: REQ_METHODS.GET,
    preResponseMap: listsPreResponseMapper
    // mockResponseData: () => {
    //   const entries = JSON.parse(JSON.stringify(FUND_ENTRIES));
    //   return entries;
    // }
  },
  EDIT_FUND: {
    path: '/funds',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   const foundEntry = FUND_ENTRIES.find((f) => f.id === body.id);
    //   if (foundEntry) {
    //     Object.entries(body).forEach(([key, value]) => {
    //       foundEntry[key] = value;
    //     });
    //   }
    //   return JSON.parse(JSON.stringify(foundEntry));
    // }
  },
  CREATE_FUND: {
    path: '/funds',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const id = 'abc' + Math.random();
    //   FUND_ENTRIES.push(JSON.parse(JSON.stringify({ id, ...body })));
    //   return JSON.parse(JSON.stringify(FUND_ENTRIES.find((f) => f.id === id)));
    // }
  },
  DELETE_FUND: {
    path: '/funds',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
    // mockResponseData: (body = {}) => {
    //   const foundIndex = FUND_ENTRIES.findIndex((f) => f.id === body.id);
    //   if (foundIndex !== -1) {
    //     FUND_ENTRIES.splice(foundIndex, 1);
    //   }
    //   return true;
    // }
  },
  GET_PORTFOLIO_COMPANIES: {
    path: '/company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.GET,
    preResponseMap: listsPreResponseMapper
    // mockResponseData: () => {
    //   const entries = JSON.parse(JSON.stringify(PORTFOLIO_COMPANIES_ENTRIES));
    //   return entries;
    // }
  },
  EDIT_PORTFOLIO_COMPANY: {
    path: '/company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.PUT
    // mockResponseData: (body = {}) => {
    //   const foundEntry = PORTFOLIO_COMPANIES_ENTRIES.find((f) => f.id === body.id);
    //   if (foundEntry) {
    //     Object.entries(body).forEach(([key, value]) => {
    //       foundEntry[key] = value;
    //     });
    //   }
    //   return JSON.parse(JSON.stringify(foundEntry));
    // }
  },
  CREATE_PORTFOLIO_COMPANY: {
    path: '/company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.POST
    // mockResponseData: (body = {}) => {
    //   const id = 'asd' + Math.random();
    //   PORTFOLIO_COMPANIES_ENTRIES.push(JSON.parse(JSON.stringify({ id, ...body })));
    //   return JSON.parse(JSON.stringify(PORTFOLIO_COMPANIES_ENTRIES.find((f) => f.id === id)));
    // }
  },
  DELETE_PORTFOLIO_COMPANY: {
    path: '/company-portfolios',
    withCognitoToken: true,
    method: REQ_METHODS.DELETE
    // mockResponseData: (body = {}) => {
    //   const foundIndex = PORTFOLIO_COMPANIES_ENTRIES.findIndex((f) => f.id === body.id);
    //   if (foundIndex !== -1) {
    //     PORTFOLIO_COMPANIES_ENTRIES.splice(foundIndex, 1);
    //   }
    //   return true;
    // }
  }
};
