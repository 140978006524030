import { Link } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { paths } from '../../utils/constants/routes';
import { useStore } from '../../store/store';
import { useEffect, useRef } from 'react';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import * as Portal from '@radix-ui/react-portal';

const Header = observer(() => {
  const { authStore, utilsStore } = useStore();
  const menuContainerRef = useRef(null);
  const logoutContainerRef = useRef(null);
  const state = useLocalObservable(() => ({
    isLogoutConfirmationOpen: false,
    setIsLogoutConfirmationOpen: (value = false) => (state.isLogoutConfirmationOpen = value)
  }));

  const toggleHamburger = () => {
    utilsStore.setIsMobileHamburgerOpen(!utilsStore.isMobileHamburgerOpen);
  };

  const toggleDesktopMenu = () => {
    utilsStore.setIsDesktopMenuOpen(!utilsStore.isDesktopMenuOpen);
  };

  useEffect(() => {
    const clickEvent = (e) => {
      if (
        menuContainerRef?.current &&
        e?.target?.id !== 'desktop-menu-button' &&
        !menuContainerRef.current.contains(e.target) &&
        utilsStore.isDesktopMenuOpen
      ) {
        utilsStore.setIsDesktopMenuOpen(false);
      }

      if (
        logoutContainerRef?.current &&
        e?.target?.id !== 'logout-menu-button' &&
        // e?.target?.id !== 'desktop-menu-button' &&
        !logoutContainerRef.current.contains(e.target) &&
        state.isLogoutConfirmationOpen
      ) {
        state.setIsLogoutConfirmationOpen();
      }
    };
    document.addEventListener('click', clickEvent);
    return () => {
      document.removeEventListener('click', clickEvent);
    };
  }, []);

  const { user, userAccountType, hasUserProfileCompany, userVerificationStatus, logout } =
    authStore;

  const notificationClassess = ['notification'];
  if (utilsStore.headerMessage.isError) {
    notificationClassess.push('status-error');
  } else {
    notificationClassess.push('status-success');
  }
  if (utilsStore.headerMessage.isFading) {
    notificationClassess.push('fadeout');
  }

  const desktopMenuBtnClassess = ['desktop-menu-btn'];
  if (user) {
    desktopMenuBtnClassess.push('logged-in');
  } else {
    desktopMenuBtnClassess.push('logged-out');
  }
  if (utilsStore.isDesktopMenuOpen) {
    desktopMenuBtnClassess.push('opened');
  }

  const showMenu = userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED && hasUserProfileCompany;

  return (
    <>
      <header>
        <div className="container">
          <div className="logo">
            <Link to={paths.HOME}>
              <img className="only-desktop" src="/images/header-logo-desktop.svg" alt="logo" />
              <img className="only-mobile" src="/images/header-logo-mobile.svg" alt="logo" />
            </Link>
          </div>
          {!!utilsStore.headerMessage.message && (
            <div className={notificationClassess.join(' ')}>{utilsStore.headerMessage.message}</div>
          )}
          {/* just keep an empty div replacing profile menu to keep flex layout */}
          {!user && <div className="empty"></div>}
          <div
            id="desktop-menu-button"
            className={desktopMenuBtnClassess.join(' ')}
            onClick={toggleDesktopMenu}>
            <div className="menu-container" ref={menuContainerRef}>
              <div className="links">
                {showMenu && (
                  <>
                    <Link to={paths.USER_PROFILE}>My account</Link>
                    <Link to={paths.COMPANY_PROFILE}>My company</Link>
                    {userAccountType === ACCOUNT_TYPES.INVESTOR && (
                      <Link to={paths.FUNDS}>My funds & portfolio</Link>
                    )}
                    {/* TODO: uncomment when Saved searches are needed */}
                    {/* {userAccountType === ACCOUNT_TYPES.AGENT && (
                      <Link to={paths.SAVED_SEARCH_RESULTS}>My saved searches</Link>
                    )} */}
                    <div className="separator"></div>
                  </>
                )}
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen(true)}
                  id="logout-menu-button">
                  Logout
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`mobile-menu ${utilsStore.isMobileHamburgerOpen ? 'opened' : ''}`}
            onClick={toggleHamburger}></div>
        </div>
      </header>
      {state.isLogoutConfirmationOpen && (
        <Portal.Root>
          <div className="transparent-overlay">
            <div className="container" ref={logoutContainerRef}>
              <div className="title">Do you really want to log out of the platform?</div>
              <div className="actions">
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen()}>
                  No, stay logged in
                </Link>
                <a
                  className="btn btn-outline"
                  onClick={() => logout() & state.setIsLogoutConfirmationOpen()}>
                  Yes, log me out
                </a>
              </div>
            </div>
          </div>
        </Portal.Root>
      )}
    </>
  );
});

export default Header;
