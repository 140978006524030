import { observer, useLocalObservable } from 'mobx-react';
import { useMemo } from 'react';
import { FIELDS_TYPES } from '../../utils/constants/fields';
import InputWrapper from './components/InputWrapper';

const Input = observer(
  ({
    field,
    value = '',
    setFieldValue,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    inputWrap = { enable: false, className: '' }
  }) => {
    const state = useLocalObservable(() => ({
      inputType: field.TYPE === FIELDS_TYPES.TYPE_NUMBER ? FIELDS_TYPES.TYPE_TEXT : field.TYPE,
      isVisible: !field.VISIBILITY_TOGGLE,
      toggleVisibility: () => {
        [state.isVisible, state.inputType] = [
          !state.isVisible,
          state.inputType === field.TYPE ? FIELDS_TYPES.TYPE_TEXT : field.TYPE
        ];
      }
    }));

    const props = useMemo(() => {
      const [inputProps, labelProps] = [{}, {}];

      inputProps.className = field.INPUT_CLASSNAME || 'form-control';

      if (field.VISIBILITY_TOGGLE) {
        inputProps.className += ' showable-password';
      }

      if (typeof field.MINLENGTH === 'number') {
        inputProps.minLength = `${field.MINLENGTH}`;
      }

      if (typeof field.MAXLENGTH === 'number') {
        inputProps.maxLength = `${field.MAXLENGTH}`;
      }

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      return { inputProps, labelProps };
    }, [field]);

    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;

    const view = (
      <div
        className={field.WRAP_CLASSNAME || 'form-group'}
        {...{ 'validation-state': validationState }}>
        {!!field.LABEL && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div className="input-container">
            <input
              type={state.inputType}
              id={field.ID}
              placeholder={field.PLACEHOLDER}
              required={field.REQUIRED}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                setFieldValue(
                  field,
                  typeof field.FORMAT === 'function'
                    ? field.FORMAT(e.target.value)
                    : field.TYPE === FIELDS_TYPES.TYPE_NUMBER
                    ? ((newValue, oldValue, field) => {
                        if (
                          (newValue === '-' && (typeof field.MIN !== 'number' || field.MIN < 0)) ||
                          newValue === ''
                        ) {
                          return newValue;
                        }

                        let num = newValue.replace(',', '.');
                        if (field.INTEGER) {
                          if (!/^-?\d+$/.test(num)) {
                            return oldValue;
                          }
                        } else {
                          if (!/^-?\d+\.?(\d{1,2})?$/.test(num)) {
                            return oldValue;
                          }
                        }

                        let numArray = num.split('');

                        while (
                          (numArray[0] === '0' &&
                            typeof numArray[1] === 'string' &&
                            !['.', ','].includes(numArray[1])) ||
                          (numArray[0] === '-' &&
                            numArray[1] === '0' &&
                            typeof numArray[2] === 'string' &&
                            !['.', ','].includes(numArray[2]))
                        ) {
                          numArray.splice(1, 1);
                        }

                        num = numArray.join('');

                        return num + '';
                      })(e.target.value, value, field)
                    : e.target.value
                );
              }}
              {...props.inputProps}
            />
            {field.VISIBILITY_TOGGLE && (
              <div
                className={`show-password-btn password-${state.isVisible ? 'visible' : 'hidden'}`}
                onClick={state.toggleVisibility}
              />
            )}
          </div>
          {!hideErrorMessages &&
            onSubmitErrorMessages
              .filter(({ msg }) => msg.length)
              .map(({ msg }, idx) => (
                <div
                  key={idx}
                  className={
                    field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-input'
                  }>
                  {msg}
                </div>
              ))}
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default Input;
