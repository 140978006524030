import { Link } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { paths } from '../../utils/constants/routes';
import { useRef, useEffect } from 'react';
import { useStore } from '../../store/store';
import useHistory from '../../hooks/useHistory';
import * as Portal from '@radix-ui/react-portal';

const GeneralError = observer(
  ({
    errorMessage = '',
    actionMessage = '',
    withGoBackButton = false,
    withHomePageButton = false,
    withRefreshPageButton = false,
    withLogoutButton = false,
    actionButtontext = '',
    onActionButtonClick = null
  }) => {
    const {
      authStore: { logout }
    } = useStore();
    const { navigate, goBack, lastHref, prevHref } = useHistory();
    const logoutContainerRef = useRef(null);
    const state = useLocalObservable(() => ({
      isLogoutConfirmationOpen: false,
      setIsLogoutConfirmationOpen: (value = false) => (state.isLogoutConfirmationOpen = value)
    }));

    useEffect(() => {
      const clickEvent = (e) => {
        if (
          logoutContainerRef?.current &&
          e?.target?.id !== 'ge-logout-menu-button' &&
          !logoutContainerRef.current.contains(e.target) &&
          state.isLogoutConfirmationOpen
        ) {
          state.setIsLogoutConfirmationOpen();
        }
      };
      document.addEventListener('click', clickEvent);
      return () => {
        document.removeEventListener('click', clickEvent);
      };
    }, []);

    const onBackClick = (e) => {
      e?.preventDefault?.();
      goBack();
    };

    const onHomeClick = (e) => {
      e?.preventDefault?.();
      navigate(paths.HOME);
    };

    const onRefreshClick = (e) => {
      e?.preventDefault?.();
      window.location.reload();
    };

    const onLogoutClick = (e) => {
      e?.preventDefault?.();
      state.setIsLogoutConfirmationOpen(true);
    };

    const onActionClick = (e) => {
      e?.preventDefault?.();
      onActionButtonClick();
    };

    const showActionButton = !!actionButtontext && typeof onActionButtonClick === 'function';

    return (
      <>
        <div className="error-wrapper">
          <div className="general-error">
            <div>
              <img src="/icons/bug.svg" />
            </div>
            <div className="big-text">
              {errorMessage ||
                'Oops. It seems that there is a problem loading this page or something has gone wrong..'}
            </div>
            <div>{actionMessage || 'In the meantime you can:'}</div>
            <div className="actions">
              {withGoBackButton && (
                <div>
                  <Link to={prevHref} className="backlink" onClick={onBackClick}>
                    Go back
                  </Link>
                </div>
              )}
              {withHomePageButton && (
                <div>
                  <Link to={paths.HOME} onClick={onHomeClick}>
                    Go to homepage
                  </Link>
                </div>
              )}
              {withRefreshPageButton && (
                <div>
                  <Link to={lastHref} className="refresh" onClick={onRefreshClick}>
                    Refresh the page
                  </Link>
                </div>
              )}
              {withLogoutButton && (
                <div>
                  <Link
                    to={undefined}
                    className="logout"
                    onClick={onLogoutClick}
                    id="ge-logout-menu-button">
                    Logout
                  </Link>
                </div>
              )}
              {showActionButton && (
                <div>
                  <Link to={lastHref} onClick={onActionClick}>
                    {actionButtontext}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        {state.isLogoutConfirmationOpen && (
          <Portal.Root>
            <div className="transparent-overlay">
              <div className="container" ref={logoutContainerRef}>
                <div className="title">Do you really want to log out of the platform?</div>
                <div className="actions">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen()}>
                    No, stay logged in
                  </Link>
                  <a
                    className="btn btn-outline"
                    onClick={() => logout() & state.setIsLogoutConfirmationOpen()}>
                    Yes, log me out
                  </a>
                </div>
              </div>
            </div>
          </Portal.Root>
        )}
      </>
    );
  }
);

export default GeneralError;
