import { observer } from 'mobx-react';
import { FUND_FIELDS } from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { formatCurrencyRange } from '../../../../utils/utils';

const FundResult = observer(({ entry, displayDetailsPopup, displayMessagePopup }) => {
  const { utilsStore } = useStore();

  // let badgeClass = 'success';
  // if (entry.match === 'Average match') {
  //   badgeClass = 'info';
  // }
  // if (entry.match === 'Good match') {
  //   badgeClass = 'warning';
  // }

  return (
    <div key={entry.id} className="item">
      <div className="cols">
        {/* <div className="col no-desktop mobile-badge no-border">
          <div className={`badge ${badgeClass}`}>{entry.match}</div>
        </div> */}
        <div className="col no-mobile">
          <div className="label">Fund name</div>
          <div className="value">{entry[FUND_FIELDS.NAME.NAME]}</div>
        </div>
        <div className="col">
          <div className="label">Industry</div>
          <div className="value">
            {utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES).join(', ')}
          </div>
        </div>
        <div className="col">
          <div className="label">Geographical focus</div>
          <div className="value">
            {utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES).join(', ')}
          </div>
        </div>
        <div className="col no-mobile">
          <div className="label">Enterprise value</div>
          <div className="value">
            {formatCurrencyRange(
              entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
              entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
            )}
          </div>
        </div>
        <div className="col no-mobile">
          <div className="label">Equity value</div>
          <div className="value">
            {formatCurrencyRange(
              entry[FUND_FIELDS.EQUITY_RANGE_MIN.NAME],
              entry[FUND_FIELDS.EQUITY_RANGE_MAX.NAME]
            )}
          </div>
        </div>
        <div className="col no-desktop">
          <div className="label">Stake</div>
          <div className="value">
            {utilsStore.getOptionName(entry, FUND_FIELDS.EQUITY).join(', ')}
          </div>
        </div>
        <div className="col no-desktop no-border">
          <div className="label">Revenue</div>
          <div className="value">
            {formatCurrencyRange(
              entry[FUND_FIELDS.REVENUE_RANGE_MIN.NAME],
              entry[FUND_FIELDS.REVENUE_RANGE_MAX.NAME]
            )}
          </div>
        </div>
        {/* <div className="col no-mobile">
        <div className="label">Match</div>
        <div className={`value badge ${badgeClass}`}>{entry.match}</div>
      </div> */}
      </div>
      <div className="actions">
        <div className="col no-border">
          <div
            id={`view-search-result-details-${entry.id}`}
            className="btn btn-primary btn-block"
            onClick={() => displayDetailsPopup(entry)}>
            View details
          </div>
        </div>
        <div className="col no-border">
          <div
            id={`message-search-result-details-${entry.id}`}
            className="btn btn-outline btn-block"
            onClick={() => displayMessagePopup(entry)}>
            Message
          </div>
        </div>
      </div>
    </div>
  );
});

export default FundResult;
