import PropTypes from 'prop-types';

const SplashScreen = ({ onHide }) => (
  <div className="overlay">
    <div className="container">
      <div className="header icon-text">
        <img src="/icons/hand.svg" alt="hand" />
        <div className="text">Getting started with your company profile</div>
      </div>
      <div className="body">
        <p>
          Hi there. In order for us to grant you access to the full functionality of the platform,
          we kindly request you to complete your company profile information.
        </p>
        <p>You will see this progress bar indicator when your company profile is all set.</p>
        <div className="demo">
          <div className="progress"></div>
          <div className="icon-text">
            <img src="/icons/check-circle-blue.svg" alt="complete" />
            <div className="text">Company profile complete</div>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="btn btn-primary" onClick={onHide}>
          Start now
        </button>
      </div>
    </div>
  </div>
);

SplashScreen.propTypes = {
  onHide: PropTypes.func.isRequired
};

export default SplashScreen;
