import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { ACCOUNT_TYPES } from '../../utils/constants/auth';
import Signup from '../auth/Signup';
import Login from '../auth/Login';
import InvestorView from './components/InvestorView';
import AgentView from './components/AgentView';

const Home = observer(() => {
  const { authStore, isWaitlistActivated } = useStore();

  if (!authStore.user) {
    return isWaitlistActivated ? <Signup /> : <Login />;
  }

  if (authStore.userAccountType === ACCOUNT_TYPES.INVESTOR) {
    return <InvestorView />;
  } else if (authStore.userAccountType === ACCOUNT_TYPES.AGENT) {
    return <AgentView />;
  }
});

export default Home;
