import StaticPageLayout from '../../../components/layouts/StaticPageLayout';

const PrivacyPolicy = () => {
  return (
    <StaticPageLayout page="tos">
      <h1>Privacy Policy</h1>
      <div className="content-header">1. An overview of data protection</div>
      <div className="content-body">
        <div className="content-large">General information</div>
        The following information will provide you with an easy to navigate overview of what will
        happen with your personal data when you visit this website. The term “personal data”
        comprises all data that can be used to personally identify you. For detailed information
        about the subject matter of data protection, please consult our Data Protection Declaration,
        which we have included beneath this copy.
      </div>
      <div className="content-body">
        <div className="content-large">Data recording on this website</div>
      </div>
      <div className="content-body">
        <div className="content-large">
          Who is the responsible party for the recording of data on this website (i.e., the
          “controller”)?
        </div>
        The data on this website is processed by the operator of the website, whose contact
        information is available under section “Information about the responsible party (referred to
        as the “controller” in the GDPR)” in this Privacy Policy.
      </div>
      <div className="content-body">
        <div className="content-large">How do we record your data?</div>
        We collect your data as a result of your sharing of your data with us. This may, for
        instance be information you enter into our contact form.
        <br />
        Other data shall be recorded by our IT systems automatically or after you consent to its
        recording during your website visit. This data comprises primarily technical information
        (e.g., web browser, operating system, or time the site was accessed). This information is
        recorded automatically when you access this website.
        <br />
      </div>
      <div className="content-body">
        <div className="content-large">What are the purposes we use your data for?</div>A portion of
        the information is generated to guarantee the error free provision of the website. Other
        data may be used to analyze your user patterns.
      </div>
      <div className="content-body">
        <div className="content-large">
          What rights do you have as far as your information is concerned?
        </div>
        You have the right to receive information about the source, recipients, and purposes of your
        archived personal data at any time without having to pay a fee for such disclosures. You
        also have the right to demand that your data are rectified or eradicated. If you have
        consented to data processing, you have the option to revoke this consent at any time, which
        shall affect all future data processing. Moreover, you have the right to demand that the
        processing of your data be restricted under certain circumstances. Furthermore, you have the
        right to log a complaint with the competent supervising agency.
        <br />
        Please do not hesitate to contact us at any time if you have questions about this or any
        other data protection related issues.
      </div>
      <div className="content-body">
        <div className="content-large">Analysis tools and tools provided by third parties</div>
        There is a possibility that your browsing patterns will be statistically analyzed when your
        visit this website. Such analyses are performed primarily with what we refer to as analysis
        programs.
        <br />
        For detailed information about these analysis programs please consult our Data Protection
        Declaration below.
      </div>
      <div className="content-body">
        <div className="content-large">2. Hosting and Content Delivery Networks (CDN)</div>
        We are hosting the content of our website at the following provider:
      </div>
      <div className="content-body">
        <div className="content-large">Amazon Web Services (AWS)</div>
        The provider is the Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855
        Luxembourg (hereinafter referred to as “AWS”).
        <br />
        When you visit our website, your personal data will be processed on AWS servers. This may
        also result in the transfer of personal data to the parent company of AWS in the United
        States. The transfer of data to the US is based on the EU’s standard contractual clauses.
        For details please consult:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/">
          https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
        </a>
        .
        <br />
        For more information, please see the AWS Data Privacy Policy:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://aws.amazon.com/de/privacy/?nc1=f_pr">
          https://aws.amazon.com/de/privacy/?nc1=f_pr
        </a>
        .
        <br />
        AWS is used on the basis of Art. 6(1)(f) GDPR. We have a legitimate interest in a depiction
        of our website that is as reliable as possible. If appropriate consent has been obtained,
        the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
        TTDSG, insofar the consent includes the storage of cookies or the access to information in
        the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This
        consent can be revoked at any time.
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
        </a>
      </div>
      <div className="content-body">
        <div className="content-large">Data processing</div>
        We have concluded a data processing agreement (DPA) for the use of the above-mentioned
        service. This is a contract mandated by data privacy laws that guarantees that they process
        personal data of our website visitors only based on our instructions and in compliance with
        the GDPR.
      </div>
      <div className="content-body">
        <div className="content-large">Amazon CloudFront CDN</div>
        We use the Content Delivery Network Amazon CloudFront CDN. The provider is Amazon Web
        Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxembourg (hereinafter referred to
        as “Amazon”).
        <br />
        Amazon CloudFront CDN is a globally distributed Content Delivery Network. During these
        transactions, the information transfer between your browser and our website is technically
        routed via the Content Delivery Network. This enables us to boost the global availability
        and performance capabilities of our website.
        <br />
        The use of Amazon CloudFront CDN is based on our legitimate interest in keeping the
        presentation of our web services as error free and secure as possible (Art. 6(1)(f) GDPR).
        <br />
        The data transfer to the United States is based on the Standard Contract Clauses of the EU
        Commission. You can find the details here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/">
          https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
        </a>
        .
        <br />
        For more information on Amazon CloudFront CDN please follow this link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf">
          https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
        </a>
        .
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
        </a>
        <br />
      </div>
      <div className="content-body">
        <div className="content-large">Data processing</div>
        We have concluded a data processing agreement (DPA) for the use of the above-mentioned
        service. This is a contract mandated by data privacy laws that guarantees that they process
        personal data of our website visitors only based on our instructions and in compliance with
        the GDPR.
      </div>
      <div className="content-body">
        <div className="content-large">3. General information and mandatory information</div>
      </div>
      <div className="content-body">
        <div className="content-large">Data protection</div>
        The operators of this website and its pages take the protection of your personal data very
        seriously. Hence, we handle your personal data as confidential information and in compliance
        with the statutory data protection regulations and this Data Protection Declaration.
        <br />
        Whenever you use this website, a variety of personal information will be collected. Personal
        data comprises data that can be used to personally identify you. This Data Protection
        Declaration explains which data we collect as well as the purposes we use this data for. It
        also explains how, and for which purpose the information is collected.
        <br />
        We herewith advise you that the transmission of data via the Internet (i.e., through e-mail
        communications) may be prone to security gaps. It is not possible to completely protect data
        against third-party access.
      </div>
      <div className="content-body">
        <div className="content-large">
          Information about the responsible party (referred to as the “controller” in the GDPR)
        </div>
        The data processing controller on this website is: <br />
        transact.digital GmbH i. G.
        <br />
        <br />
        c/o WeWork, Neue Rothofstraße 13-19
        <br />
        60313 Frankfurt
        <br />
        Germany <br />
        <br />
        Phone: +49 69 66779969
        <br />
        E-mail: info@transact.digital
        <br />
        The controller is the natural person or legal entity that single-handedly or jointly with
        others makes decisions as to the purposes of and resources for the processing of personal
        data (e.g., names, e-mail addresses, etc.).
      </div>
      <div className="content-body">
        <div className="content-large">Storage duration</div>
        Unless a more specific storage period has been specified in this privacy policy, your
        personal data will remain with us until the purpose for which it was collected no longer
        applies. If you assert a justified request for deletion or revoke your consent to data
        processing, your data will be deleted, unless we have other legally permissible reasons for
        storing your personal data (e.g., tax or commercial law retention periods); in the latter
        case, the deletion will take place after these reasons cease to apply.
      </div>
      <div className="content-body">
        <div className="content-large">
          General information on the legal basis for the data processing on this website
        </div>
        If you have consented to data processing, we process your personal data on the basis of Art.
        6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed according to
        Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of personal data to third
        countries, the data processing is also based on Art. 49 (1)(a) GDPR. If you have consented
        to the storage of cookies or to the access to information in your end device (e.g., via
        device fingerprinting), the data processing is additionally based on § 25 (1) TTDSG. The
        consent can be revoked at any time. If your data is required for the fulfillment of a
        contract or for the implementation of pre-contractual measures, we process your data on the
        basis of Art. 6(1)(b) GDPR. Furthermore, if your data is required for the fulfillment of a
        legal obligation, we process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data
        processing may be carried out on the basis of our legitimate interest according to Art.
        6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in
        the following paragraphs of this privacy policy.
      </div>
      <div className="content-body">
        <div className="content-large">
          Information on the data transfer to third-party countries that are not secure under data
          protection law and the transfer to US companies that are not DPF-certified
        </div>
        We use, among other technologies, tools from companies located in third-party countries that
        are not safe under data protection law, as well as US tools whose providers are not
        certified under the EU-US Data Privacy Framework (DPF). If these tools are enabled, your
        personal data may be transferred to and processed in these countries. We would like you to
        note that no level of data protection comparable to that in the EU can be guaranteed in
        third countries that are insecure in terms of data protection law.
        <br />
        We would like to point out that the US, as a secure third-party country, generally has a
        level of data protection comparable to that of the EU. Data transfer to the US is therefore
        permitted if the recipient is certified under the “EU-US Data Privacy Framework” (DPF) or
        has appropriate additional assurances. Information on transfers to third-party countries,
        including the data recipients, can be found in this Privacy Policy.
      </div>
      <div className="content-body">
        <div className="content-large">Recipients of personal data</div>
        In the scope of our business activities, we cooperate with various external parties. In some
        cases, this also requires the transfer of personal data to these external parties. We only
        disclose personal data to external parties if this is required as part of the fulfillment of
        a contract, if we are legally obligated to do so (e.g., disclosure of data to tax
        authorities), if we have a legitimate interest in the disclosure pursuant to Art. 6 (1)(f)
        GDPR, or if another legal basis permits the disclosure of this data. When using processors,
        we only disclose personal data of our customers on the basis of a valid contract on data
        processing. In the case of joint processing, a joint processing agreement is concluded.
      </div>
      <div className="content-body">
        <div className="content-large">Revocation of your consent to the processing of data</div>A
        wide range of data processing transactions are possible only subject to your express
        consent. You can also revoke at any time any consent you have already given us. This shall
        be without prejudice to the lawfulness of any data collection that occurred prior to your
        revocation.
      </div>
      <div className="content-body">
        <div className="content-large">
          Right to object to the collection of data in special cases; right to object to direct
          advertising (Art. 21 GDPR)
        </div>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE
        RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING
        FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO
        DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS
        DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR
        AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY
        GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS
        OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL
        ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
        <br />
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE
        THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF
        SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS
        AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY
        NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).
      </div>
      <div className="content-body">
        <div className="content-large">
          Right to log a complaint with the competent supervisory agency
        </div>
        In the event of violations of the GDPR, data subjects are entitled to log a complaint with a
        supervisory agency, in particular in the member state where they usually maintain their
        domicile, place of work or at the place where the alleged violation occurred. The right to
        log a complaint is in effect regardless of any other administrative or court proceedings
        available as legal recourses.
      </div>
      <div className="content-body">
        <div className="content-large">Right to data portability</div>
        You have the right to have data that we process automatically on the basis of your consent
        or in fulfillment of a contract handed over to you or to a third party in a common,
        machine-readable format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </div>
      <div className="content-body">
        <div className="content-large">
          Information about, rectification and eradication of data
        </div>
        Within the scope of the applicable statutory provisions, you have the right to demand
        information about your archived personal data, their source and recipients as well as the
        purpose of the processing of your data at any time. You may also have a right to have your
        data rectified or eradicated. If you have questions about this subject matter or any other
        questions about personal data, please do not hesitate to contact us at any time.
      </div>
      <div className="content-body">
        <div className="content-large">Right to demand processing restrictions</div>
        You have the right to demand the imposition of restrictions as far as the processing of your
        personal data is concerned. To do so, you may contact us at any time. The right to demand
        restriction of processing applies in the following cases:
        <ul>
          <li>
            In the event that you should dispute the correctness of your data archived by us, we
            will usually need some time to verify this claim. During the time that this
            investigation is ongoing, you have the right to demand that we restrict the processing
            of your personal data.
          </li>
          <li>
            If the processing of your personal data was/is conducted in an unlawful manner, you have
            the option to demand the restriction of the processing of your data instead of demanding
            the eradication of this data.
          </li>
          <li>
            If we do not need your personal data any longer and you need it to exercise, defend or
            claim legal entitlements, you have the right to demand the restriction of the processing
            of your personal data instead of its eradication.
          </li>
          If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights
          will have to be weighed against each other. As long as it has not been determined whose
          interests prevail, you have the right to demand a restriction of the processing of your
          personal data.
        </ul>
        If you have restricted the processing of your personal data, these data – with the exception
        of their archiving – may be processed only subject to your consent or to claim, exercise or
        defend legal entitlements or to protect the rights of other natural persons or legal
        entities or for important public interest reasons cited by the European Union or a member
        state of the EU.
      </div>
      <div className="content-body">
        <div className="content-large">SSL and/or TLS encryption</div>
        For security reasons and to protect the transmission of confidential content, such as
        purchase orders or inquiries you submit to us as the website operator, this website uses
        either an SSL or a TLS encryption program. You can recognize an encrypted connection by
        checking whether the address line of the browser switches from “http://” to “https://” and
        also by the appearance of the lock icon in the browser line.
        <br />
        If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third
        parties.
      </div>
      <div className="content-body">
        <div className="content-large">Rejection of unsolicited e-mails</div>
        We herewith object to the use of contact information published in conjunction with the
        mandatory information to be provided in our Site Notice to send us promotional and
        information material that we have not expressly requested. The operators of this website and
        its pages reserve the express right to take legal action in the event of the unsolicited
        sending of promotional information, for instance via SPAM messages.
      </div>

      <div className="content-body">
        <div className="content-large">4. Recording of data on this website</div>
      </div>
      <div className="content-body">
        <div className="content-large">Cookies</div>
        Our websites and pages use what the industry refers to as “cookies.” Cookies are small data
        packages that do not cause any damage to your device. They are either stored temporarily for
        the duration of a session (session cookies) or they are permanently archived on your device
        (permanent cookies). Session cookies are automatically deleted once you terminate your
        visit. Permanent cookies remain archived on your device until you actively delete them, or
        they are automatically eradicated by your web browser.
        <br />
        Cookies can be issued by us (first-party cookies) or by third-party companies (so-called
        third-party cookies). Third-party cookies enable the integration of certain services of
        third-party companies into websites (e.g., cookies for handling payment services).
        <br />
        Cookies have a variety of functions. Many cookies are technically essential since certain
        website functions would not work in the absence of these cookies (e.g., the shopping cart
        function or the display of videos). Other cookies may be used to analyze user behavior or
        for promotional purposes.
        <br />
        Cookies, which are required for the performance of electronic communication transactions,
        for the provision of certain functions you want to use (e.g., for the shopping cart
        function) or those that are necessary for the optimization (required cookies) of the website
        (e.g., cookies that provide measurable insights into the web audience), shall be stored on
        the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The operator of the
        website has a legitimate interest in the storage of required cookies to ensure the
        technically error-free and optimized provision of the operator’s services. If your consent
        to the storage of the cookies and similar recognition technologies has been requested, the
        processing occurs exclusively on the basis of the consent obtained (Art. 6(1)(a) GDPR and §
        25 (1) TTDSG); this consent may be revoked at any time.
        <br />
        You have the option to set up your browser in such a manner that you will be notified any
        time cookies are placed and to permit the acceptance of cookies only in specific cases. You
        may also exclude the acceptance of cookies in certain cases or in general or activate the
        delete-function for the automatic eradication of cookies when the browser closes. If cookies
        are deactivated, the functions of this website may be limited.
        <br />
        Which cookies and services are used on this website can be found in this privacy policy.
      </div>
      <div className="content-body">
        <div className="content-large">Consent with Usercentrics</div>
        This website uses the consent technology of Usercentrics to obtain your consent to the
        storage of certain cookies on your device or for the use of specific technologies, and to
        document the former in a data protection compliant manner. The party offering this
        technology is Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Germany, website:
        <a target="_blank" rel="noopener noreferrer" href="https://usercentrics.com/">
          https://usercentrics.com/
        </a>
        (hereinafter referred to as “Usercentrics”). Whenever you visit our website, the following
        personal data will be transferred to Usercentrics:
        <ul>
          <li>
            Your declaration(s) of consent or your revocation of your declaration(s) of consent
          </li>
          <li>Your IP address</li>
          <li>Information about your browser</li>
          <li>Information about your device</li>
          <li>The date and time you visited our website</li>
        </ul>
        Moreover, Usercentrics shall store a cookie in your browser to be able to allocate your
        declaration(s) of consent or any revocations of the former. The data that are recorded in
        this manner shall be stored until you ask us to eradicate them, delete the Usercentrics
        cookie or until the purpose for archiving the data no longer exists. This shall be without
        prejudice to any mandatory legal retention periods. The Usercentrics banner on this website
        has been configured with the assistance of eRecht24. This can be identified by the eRecht24
        logo. To display the eRecht24 logo in the banner, a connection to the image server of
        eRecht24 will be established. In conjunction with this, the IP address is also transferred;
        however, is only stored in anonymized form in the server logs. The image server of eRecht24
        is located in Germany with a German provider. The banner as such is provided exclusively by
        Usercentrics. Usercentrics uses cookies to obtain the declarations of consent mandated by
        law. The legal basis for the use of specific technologies is Art. 6(1)(c) GDPR.
      </div>
      <div className="content-body">
        <div className="content-large">Data processing</div>
        We have concluded a data processing agreement (DPA) for the use of the above-mentioned
        service. This is a contract mandated by data privacy laws that guarantees that they process
        personal data of our website visitors only based on our instructions and in compliance with
        the GDPR.
      </div>
      <div className="content-body">
        <div className="content-large">Request by e-mail, telephone, or fax</div>
        If you contact us by e-mail, telephone or fax, your request, including all resulting
        personal data (name, request) will be stored and processed by us for the purpose of
        processing your request. We do not pass these data on without your consent.
        <br />
        These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the
        fulfillment of a contract or is required for the performance of pre-contractual measures. In
        all other cases, the data are processed on the basis of our legitimate interest in the
        effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on the basis of your
        consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be revoked at any time.
        <br />
        The data sent by you to us via contact requests remain with us until you request us to
        delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g.
        after completion of your request). Mandatory statutory provisions - in particular statutory
        retention periods - remain unaffected.
      </div>
      <div className="content-body">
        <div className="content-large">Registration on this website</div>
        You have the option to register on this website to be able to use additional website
        functions. We shall use the data you enter only for the purpose of using the respective
        offer or service you have registered for. The required information we request at the time of
        registration must be entered in full. Otherwise, we shall reject the registration.
        <br />
        To notify you of any important changes to the scope of our portfolio or in the event of
        technical modifications, we shall use the e-mail address provided during the registration
        process.
        <br />
        We shall process the data entered during the registration process on the basis of your
        consent (Art. 6(1)(a) GDPR).
        <br />
        The data recorded during the registration process shall be stored by us as long as you are
        registered on this website. Subsequently, such data shall be deleted. This shall be without
        prejudice to mandatory statutory retention obligations.
      </div>
      <div className="content-body">
        <div className="content-large">5. Social media</div>
      </div>
      <div className="content-body">
        <div className="content-large">LinkedIn</div>
        This website uses elements of the LinkedIn network. The provider is LinkedIn Ireland
        Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland.
        <br />
        Any time you access a page of this website that contains elements of LinkedIn, a connection
        to LinkedIn’s servers is established. LinkedIn is notified that you have visited this
        website with your IP address. If you click on LinkedIn’s “Recommend” button and are logged
        into your LinkedIn account at the time, LinkedIn will be in a position to allocate your
        visit to this website to your user account. We have to point out that we as the provider of
        the websites do not have any knowledge of the content of the transferred data and its use by
        LinkedIn.
        <br />
        If your approval (consent) has been obtained the use of the abovementioned service shall
        occur on the basis of Art. 6 (1)(a) GDPR and § 25 TTDSG (German Telecommunications Act).
        Such consent may be revoked at any time. If your consent was not obtained, the use of the
        service will occur on the basis of our legitimate interest in making our information as
        comprehensively visible as possible on social media.
        <br />
        Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
        European Commission. Details can be found here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en">
          https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en
        </a>
        .
        <br />
        For further information on this subject, please consult LinkedIn’s Data Privacy Declaration
        at:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/legal/privacy-policy">
          https://www.linkedin.com/legal/privacy-policy
        </a>
        .
      </div>
      <div className="content-body">
        <div className="content-large">6. Analysis tools and advertising</div>
      </div>
      <div className="content-body">
        <div className="content-large">Google Tag Manager</div>
        We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Ireland
        <br />
        The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools
        and other technologies on our website. The Google Tag Manager itself does not create any
        user profiles, does not store cookies, and does not carry out any independent analyses. It
        only manages and runs the tools integrated via it. However, the Google Tag Manager does
        collect your IP address, which may also be transferred to Google’s parent company in the
        United States.
        <br />
        The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a
        legitimate interest in the quick and uncomplicated integration and administration of various
        tools on his website. If appropriate consent has been obtained, the processing is carried
        out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent
        includes the storage of cookies or the access to information in the user’s end device (e.g.,
        device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any
        time.
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </div>
      <div className="content-body">
        <div className="content-large">Google Analytics</div>
        This website uses functions of the web analysis service Google Analytics. The provider of
        this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4,
        Ireland.
        <br />
        Google Analytics enables the website operator to analyze the behavior patterns of website
        visitors. To that end, the website operator receives a variety of user data, such as pages
        accessed, time spent on the page, the utilized operating system and the user’s origin. This
        data is summarized in a user-ID and assigned to the respective end device of the website
        visitor.
        <br />
        Furthermore, Google Analytics allows us to record your mouse and scroll movements and
        clicks, among other things. Google Analytics uses various modeling approaches to augment the
        collected data sets and uses machine learning technologies in data analysis.
        <br />
        Google Analytics uses technologies that make the recognition of the user for the purpose of
        analyzing the user behavior patterns (e.g., cookies or device fingerprinting). The website
        use information recorded by Google is, as a rule transferred to a Google server in the
        United States, where it is stored.
        <br />
        The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR
        and § 25(1) TTDSG. You may revoke your consent at any time.
        <br />
        Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
        European Commission. Details can be found here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://privacy.google.com/businesses/controllerterms/mccs/">
          https://privacy.google.com/businesses/controllerterms/mccs/
        </a>
        .
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </div>
      <div className="content-body">
        <div className="content-large">Browser plug-in</div>
        You can prevent the recording and processing of your data by Google by downloading and
        installing the browser plugin available under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://tools.google.com/dlpage/gaoptout?hl=en">
          https://tools.google.com/dlpage/gaoptout?hl=en{' '}
        </a>
        .
        <br />
        For more information about the handling of user data by Google Analytics, please consult
        Google’s Data Privacy Declaration at:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/analytics/answer/6004245?hl=en">
          https://support.google.com/analytics/answer/6004245?hl=en{' '}
        </a>
        .
      </div>
      <div className="content-body">
        <div className="content-large">Google Signals</div>
        We use Google Signals. Whenever you visit our website, Google Analytics records, among other
        things, your location, the progression of your search and YouTube progression as well as
        demographic data (site visitor data). This data may be used for customized advertising with
        the assistance of Google Signal. If you have a Google account, your site visitor information
        will be linked to your Google account by Google Signal and used to send you customized
        promotional messages. The data is also used to compile anonymized statistics of our users’
        online patterns.
      </div>
      <div className="content-body">
        <div className="content-large">Contract data processing</div>
        We have executed a contract data processing agreement with Google and are implementing the
        stringent provisions of the German data protection agencies to the fullest when using Google
        Analytics.
      </div>
      <div className="content-body">
        <div className="content-large">Google Ads</div>
        The website operator uses Google Ads. Google Ads is an online promotional program of Google
        Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
        <br />
        Google Ads enables us to display ads in the Google search engine or on third-party websites,
        if the user enters certain search terms into Google (keyword targeting). It is also possible
        to place targeted ads based on the user data Google has in its possession (e.g., location
        data and interests; target group targeting). As the website operator, we can analyze these
        data quantitatively, for instance by analyzing which search terms resulted in the display of
        our ads and how many ads led to respective clicks.
        <br />
        The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR
        and § 25(1) TTDSG. You may revoke your consent at any time.
        <br />
        Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
        European Commission. Details can be found here:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com/privacy/frameworks">
          https://policies.google.com/privacy/frameworks
        </a>{' '}
        and
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://privacy.google.com/businesses/controllerterms/mccs/">
          https://privacy.google.com/businesses/controllerterms/mccs/
        </a>
        .
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </div>
      <div className="content-body">
        <div className="content-large">Google Conversion-Tracking</div>
        This website uses Google Conversion Tracking. The provider of this service is Google Ireland
        Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
        <br />
        With the assistance of Google Conversion Tracking, we are in a position to recognize whether
        the user has completed certain actions. For instance, we can analyze the how frequently
        which buttons on our website have been clicked and which products are reviewed or purchased
        with particular frequency. The purpose of this information is to compile conversion
        statistics. We learn how many users have clicked on our ads and which actions they have
        completed. We do not receive any information that would allow us to personally identify the
        users. Google as such uses cookies or comparable recognition technologies for identification
        purposes.
        <br />
        The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR
        and § 25(1) TTDSG. You may revoke your consent at any time.
        <br />
        For more information about Google Conversion Tracking, please review Google’s data
        protection policy at:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com/privacy?hl=en">
          https://policies.google.com/privacy?hl=en
        </a>
        <br />
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The
        DPF is an agreement between the European Union and the US, which is intended to ensure
        compliance with European data protection standards for data processing in the US. Every
        company certified under the DPF is obliged to comply with these data protection standards.
        For more information, please contact the provider under the following link:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </div>
      <div className="content-body">
        <div className="content-large">7. Plug-ins and Tools</div>
      </div>
      <div className="content-body">
        <div className="content-large">Google Fonts (local embedding)</div>
        This website uses so-called Google Fonts provided by Google to ensure the uniform use of
        fonts on this site. These Google fonts are locally installed so that a connection to
        Google’s servers will not be established in conjunction with this application.
        <br />
        For more information on Google Fonts, please follow this link:
        <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/fonts/faq">
          https://developers.google.com/fonts/faq
        </a>{' '}
        and consult Google’s Data Privacy Declaration under:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com/privacy?hl=en">
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </div>
      <div className="content-body">
        <div className="content-large">Friendly Captcha</div>
        We use Friendly Captcha (hereinafter referred to as “Friendly Captcha”) on this website. The
        provider is Friendly Captcha GmbH, Am Anger 3-5, 82237 Woerthsee, Germany.
        <br />
        Friendly Captcha is used to verify whether the entry of data into this website (e.g., into a
        contact form) is being processed by a person or an automated program. For this purpose,
        Friendly Captcha analyzes the behavior patterns of website visitors based on numerous
        characteristics. For the analysis, Friendly Captcha examines a wide range of information
        (e.g., anonymized IP address, referrer, time of the visit, etc.). For more related
        information please visit:
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://friendlycaptcha.com/legal/privacy-end-users/">
          https://friendlycaptcha.com/legal/privacy-end-users/
        </a>
        .
        <br />
        The storage and analysis of the data occurs on the basis of Art. 6 (1)(f) GDPR. The website
        operator has a legitimate interest in protecting the operator’s web presentations against
        abusive automatic spying and SPAM. In the event that respective consent has been obtained,
        the data will be processed exclusively on the basis of Art. 6 (1)(a) GDPR and § 25 (1)
        TTDSG, if the consent comprises the storage of cookies or access to information on the
        user’s device (e.g., device fingerprinting) as defined in the TTDSG (German
        Telecommunications Act). Such consent may be revoked at any time.
      </div>
      <div className="content-body">
        <div className="content-large">Data processing</div>
        We have concluded a data processing agreement (DPA) for the use of the above-mentioned
        service. This is a contract mandated by data privacy laws that guarantees that they process
        personal data of our website visitors only based on our instructions and in compliance with
        the GDPR.
      </div>
      <div className="content-body">
        <div className="content-large">8. Processing of Customer and Contract Data</div>
        We collect, process, and use personal customer and contract data for the establishment,
        content arrangement and modification of our contractual relationships. Data with personal
        references to the use of this website (usage data) will be collected, processed, and used
        only if this is necessary to enable the user to use our services or required for billing
        purposes. The legal basis for these processes is Art. 6(1)(b) GDPR.
        <br />
        The collected customer data shall be deleted upon completion of the order or termination of
        the business relationship and upon expiration of any existing statutory archiving periods.
        This shall be without prejudice to any statutory archiving periods.
      </div>
    </StaticPageLayout>
  );
};

export default PrivacyPolicy;
