import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { useEffect, useMemo } from 'react';
import { ACCOUNT_VERIF_TYPES } from '../../../utils/constants/auth';
import Reroute from '../../../components/navigation/Reroute';
import { paths } from '../../../utils/constants/routes';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';

const REDIRECT_TO_HOME_TIMEOUT_MS = 3_000; // 3 seconds

const VerificationProgress = observer(() => {
  const { authStore } = useStore();

  const state = useLocalObservable(() => ({
    isRendered: true,
    setIsRendered: (value = false) => (state.isRendered = value),
    isRerouteTimeoutActive: false,
    setIsRerouteTimeoutActive: (value = false) => (state.isRerouteTimeoutActive = value),
    reroute: null,
    setReroute: (value = null) => (state.reroute = value)
  }));

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    if (
      authStore.userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED &&
      !state.isRerouteTimeoutActive
    ) {
      state.setIsRerouteTimeoutActive(true);
      setTimeout(() => {
        if (state.isRendered) {
          state.setReroute(
            authStore.hasUserProfileCompany ? paths.HOME : paths.CREATE_COMPANY_PROFILE
          );
        }
      }, REDIRECT_TO_HOME_TIMEOUT_MS);
    }
  }, [state, authStore.userVerificationStatus]);

  const stepClass = useMemo(() => {
    const classes = new Array(3).fill('complete');

    if (authStore.userVerificationStatus === ACCOUNT_VERIF_TYPES.VERIFIED) {
      classes[2] = 'awaiting';
    } else if (authStore.userVerificationStatus === ACCOUNT_VERIF_TYPES.CREATED) {
      [classes[1], classes[2]] = ['awaiting', 'unavailable'];
    }

    return classes;
  }, [authStore.userVerificationStatus]);

  if (typeof state.reroute === 'string') {
    return <Reroute path={state.reroute} />;
  }

  return (
    <StaticPageLayout page="login-layout-verification-progress">
      <div className="container-verification">
        <div className="verification-header">
          <h4>You have successfully logged in.</h4>
          <h6>
            You can track the progress you’ve made until your account will be validated by our team.
          </h6>
        </div>
        <div className="steps">
          {['Account created', 'Account validated', 'Account is set'].map((step, idx) => (
            <div className={`step step${idx + 1} ` + stepClass[idx]} key={idx}>
              <div className="visual"></div>
              <div className="text">{step}</div>
            </div>
          ))}
        </div>
        {authStore.userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED && (
          <div className="info">
            You will be automatically redirected to the platform in 3 seconds...
          </div>
        )}
      </div>
    </StaticPageLayout>
  );
});

export default VerificationProgress;
