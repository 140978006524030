const FUNDS_PATH_COMPONENTS = {
  PORTFOLIO_COMPANIES: 'portfolio-companies',
  CREATE_FUND: 'create-fund',
  EDIT_FUND: 'edit-fund',
  CREATE_PORTOLIO_COMPANY: 'create-portfolio-company',
  EDIT_PORTFOLIO_COMPANY: 'edit-portfolio-company'
};

const FUNDS_VIEW_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE'
};

const FUNDS_VIEW_TABS = {
  INFORMATION: 'INFORMATION',
  GEO_INDUSTRIES: 'GEO_INDUSTRIES',
  FINANCIAL_METRICS: 'FINANCIAL_METRICS',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  VALUATION: 'VALUATION'
};

const PORTFOLIOS_VIEW_TABS = {
  INFORMATION: 'INFORMATION',
  GEO_INDUSTRIES: 'GEO_INDUSTRIES',
  FINANCIAL_METRICS: 'FINANCIAL_METRICS',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  VALUATION: 'VALUATION'
};

export { FUNDS_PATH_COMPONENTS, FUNDS_VIEW_MODE, FUNDS_VIEW_TABS, PORTFOLIOS_VIEW_TABS };
