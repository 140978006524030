import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import Funds from './investor/Funds';
import OnboardingSteps from './investor/OnboardingSteps';
import PortfolioCompanies from './investor/PortfolioCompanies';
import GeneralError from '../../../components/errors/GeneralError';

const InvestorView = observer(() => {
  const store = useStore();
  const { user } = store.authStore;
  const userPayload = user.getIdToken()?.payload;
  const { fundsStore } = store;

  const showLoadError = !!fundsStore.loadDataError;
  const showContent = !showLoadError;

  return (
    <StaticPageLayout page="investor-default">
      <div className="investor-cover">
        <h1>
          Welcome back,{' '}
          <span className="name-break">
            {userPayload?.given_name || 'First'} {userPayload?.family_name || 'Last'}
          </span>
        </h1>
      </div>
      <div className="investor-container">
        {showLoadError && (
          <GeneralError
            errorMessage={fundsStore.loadDataError}
            actionMessage={'You may want to:'}
            actionButtontext={'Try again'}
            onActionButtonClick={fundsStore.loadFunds}
          />
        )}
        {showContent && (
          <>
            <div className="funds-container">
              <Funds />
            </div>
            <div className="funds-container">
              <PortfolioCompanies />
            </div>
            <div className="onboarding-container">
              <OnboardingSteps />
            </div>
          </>
        )}
      </div>
    </StaticPageLayout>
  );
});

export default InvestorView;
