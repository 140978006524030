import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../../store/store';
import { paths } from '../../../../utils/constants/routes';
import { FUND_FIELDS } from '../../../../utils/constants/fields';
import { formatCurrency, formatCurrencyRange } from '../../../../utils/utils';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';
import { PUNCTUATION } from '../../../../utils/constants/notation';

const Funds = observer(() => {
  const { fundsStore, utilsStore } = useStore();

  useEffect(() => {
    if (!fundsStore.funds.length && fundsStore.initialLoad && !fundsStore.isLoading) {
      fundsStore.loadFunds();
    }
  }, [fundsStore]);

  const funds = fundsStore.funds;

  const [fundPropertyMap, getFundPropertyValue] = useMemo(() => {
    const propertyMap = {
      [FUND_FIELDS.NAME.NAME]: { label: 'Fund name' },
      [FUND_FIELDS.VINTAGE.NAME]: {
        label: 'Year',
        format: (value) => value || PUNCTUATION.EMPTY_VALUE
      },
      [FUND_FIELDS.VOLUME.NAME]: {
        label: 'Volume',
        format: (value) => formatCurrency(value)
      },
      [FUND_FIELDS.GEOGRAPHIES.NAME]: {
        label: 'Geo focus',
        format: (value) => utilsStore.getOptionName(value, FUND_FIELDS.GEOGRAPHIES).join(', ')
      },
      [FUND_FIELDS.INDUSTRIES.NAME]: {
        label: 'Industries',
        format: (value) => utilsStore.getOptionName(value, FUND_FIELDS.INDUSTRIES).join(', ')
      },
      evTicket: {
        label: 'EV ticket',
        format: (_, entry) =>
          formatCurrencyRange(
            entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
            entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
          )
      }
    };

    const getPropertyValue = (key, value, entry) => {
      return typeof propertyMap[key].format === 'function'
        ? propertyMap[key].format(value, entry)
        : value;
    };

    return [propertyMap, getPropertyValue];
  }, [utilsStore, utilsStore.options]);

  if (fundsStore.isLoadingData) {
    return <GeneralLoader />;
  }

  return (
    <>
      <div className="title-container">
        <div className="title-num">
          <h2>Your active funds</h2>
          {!!funds.length && (
            <div className="num">
              ({funds.length > 1 ? '2' : '1'} of {funds.length})
            </div>
          )}
        </div>
        {!!funds.length && (
          <Link to={paths.FUNDS} className="btn btn-link btn-short">
            View all
          </Link>
        )}
      </div>
      {!!funds.length && (
        <div className="funds-listing">
          {funds.slice(0, 2).map((fund) => (
            <div className="fund-card" key={fund.id}>
              {Object.entries(fundPropertyMap).map(([key, prop]) => (
                <div className="row" key={key}>
                  <div className="label">{prop.label}</div>
                  <div className="value">{getFundPropertyValue(key, fund[key], fund)}</div>
                </div>
              ))}
            </div>
          ))}
          {funds.length === 1 && (
            <div className="fund-card empty">
              <Link className="central-row" to={paths.FUNDS_CREATE_FUND}>
                Create new fund
              </Link>
            </div>
          )}
        </div>
      )}
      {!funds.length && (
        <div className="gray-panel">
          <div className="text">
            Set up your active fund profiles to be found by investment banks.
          </div>
          <Link to={paths.FUNDS_CREATE_FUND}>
            <button className="btn btn-primary btn-short">Create a fund profile</button>
          </Link>
        </div>
      )}
    </>
  );
});

export default Funds;
