import { observer } from 'mobx-react';
import { useMemo } from 'react';
import InputWrapper from './components/InputWrapper';

const TextArea = observer(
  ({
    field,
    value = '',
    setFieldValue,
    formId = null,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    inputWrap = { enable: false, className: '' }
  }) => {
    const props = useMemo(() => {
      const [inputProps, labelProps] = [{}, {}];

      inputProps.className = field.INPUT_CLASSNAME || 'form-control';

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      if (typeof field.ROWS === 'number') {
        inputProps.rows = `${field.ROWS}`;
      }

      if (typeof field.COLS === 'number') {
        inputProps.cols = `${field.COLS}`;
      }

      if (typeof field.MAXLENGTH === 'number') {
        inputProps.maxLength = `${field.MAXLENGTH}`;
      }

      if (formId || field.FORM_ID) {
        inputProps.form = formId || field.FORM_ID;
      }

      if (field.READ_ONLY) {
        inputProps.readOnly = true;
      }

      if (field.WRAP) {
        inputProps.wrap = field.WRAP;
      }

      return { inputProps, labelProps };
    }, [field, formId]);

    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;

    const view = (
      <div
        className={field.WRAP_CLASSNAME || 'form-group'}
        {...{ 'validation-state': validationState }}>
        {!!field.LABEL && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div className="input-container">
            <textarea
              id={field.ID}
              placeholder={field.PLACEHOLDER}
              required={field.REQUIRED}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                setFieldValue(
                  field,
                  typeof field.FORMAT === 'function' ? field.FORMAT(e.target.value) : e.target.value
                );
              }}
              {...props.inputProps}
            />
          </div>
          {onSubmitErrorMessages.map(({ msg }, idx) => (
            <div
              key={idx}
              className={field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-input'}>
              {msg}
            </div>
          ))}
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default TextArea;
