import { useStore } from '../store/store';
import { useLocation } from 'react-router';
import { paths, routes } from '../utils/constants/routes';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../utils/constants/auth';
import Reroute from '../components/navigation/Reroute';
import AppLoader from '../components/loaders/AppLoader';
import AppError from '../components/errors/AppError';
import { matchPath } from '../utils/utils';

const staticPaths = [
  paths.TERMS_N_CONDITIONS,
  paths.COOKIE_POLICY,
  paths.PRIVACY_POLICY,
  paths.IMPRINT,
  paths.GDPR
];

const whitelistPaths = [...staticPaths, paths.HOME];

const freeAuthPaths = [
  ...staticPaths,
  paths.HOME,
  paths.FORGOTTEN_PASSWORD,
  paths.SIGNUP,
  paths.LOGIN
];

const nonApprovalPaths = [...staticPaths, paths.USER_VERIF_PROGRESS];

const nonCompanyPaths = [...staticPaths, paths.USER_VERIF_PROGRESS, paths.CREATE_COMPANY_PROFILE];

const blockAuthPaths = [paths.FORGOTTEN_PASSWORD, paths.SIGNUP, paths.LOGIN];

const agentOnlyPaths = [paths.SEARCH_RESULTS, routes.SAVED_SEARCH_RESULTS_PATH];

const investorOnlyPaths = [routes.FUNDS_PATH];

const useRouteGuard = () => {
  const { authStore, isWaitlistActivated } = useStore();
  const location = useLocation();
  const { isLoadingAccountDetails, accountInfoError, userVerificationStatus } = authStore;
  const { user, userAccountType, hasUserProfileCompany } = authStore;

  if (isLoadingAccountDetails) {
    return <AppLoader />;
  }

  if (accountInfoError) {
    return <AppError />;
  }

  if (isWaitlistActivated && !matchPath(whitelistPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  if (
    user &&
    userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED &&
    !matchPath(nonApprovalPaths, location)
  ) {
    return <Reroute path={paths.USER_VERIF_PROGRESS} />;
  }

  if (
    user &&
    !isLoadingAccountDetails &&
    !hasUserProfileCompany &&
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED &&
    !matchPath(nonCompanyPaths, location)
  ) {
    return <Reroute path={paths.CREATE_COMPANY_PROFILE} />;
  }

  if (!user && !matchPath(freeAuthPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  if (user && matchPath(blockAuthPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  if (userAccountType !== ACCOUNT_TYPES.INVESTOR && matchPath(investorOnlyPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  if (userAccountType !== ACCOUNT_TYPES.AGENT && matchPath(agentOnlyPaths, location)) {
    return <Reroute path={paths.HOME} />;
  }

  return null;
};

export default useRouteGuard;
