import { observer } from 'mobx-react';
import { formatDateDDMMYYYY } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
// import ReactPaginate from 'react-paginate';
import { paths } from '../../../../utils/constants/routes';
import { SEARCH_SAVE_FIELDS, SAVED_SEARCH_VIEW_FIELDS } from '../../../../utils/constants/fields';
import useHistory from '../../../../hooks/useHistory';

const SavedSearchesList = observer(({ list = [], page = 1, pages = 1, itemsPerPage = 8 }) => {
  const { navigate } = useHistory();
  return (
    <div className="results">
      <div className="results-header">
        <div className="cols">
          <div className="col">{SEARCH_SAVE_FIELDS.NAME.LABEL}</div>
          <div className="col">{SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.LABEL}</div>
          <div className="col">{SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.LABEL}</div>
        </div>
        <div className="actions">{list.length} results</div>
      </div>
      {list.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, idx) => (
        <div key={`${item.id}${idx}`} className="item">
          <div className="cols">
            <div className="col search-name">{item[SEARCH_SAVE_FIELDS.NAME.NAME]}</div>
            <div className="col mobile-row">
              <div className="label">{SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.LABEL}</div>
              <div className="value">
                {formatDateDDMMYYYY(item[SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME])}
              </div>
            </div>
            <div className="col mobile-row">
              <div className="label">{SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.LABEL}</div>
              <div className="value">{item[SAVED_SEARCH_VIEW_FIELDS.RESULTS_COUNT.NAME]}</div>
            </div>
          </div>
          <div className="actions">
            <Link
              to={`${paths.SAVED_SEARCH_RESULTS}/${item.id}`}
              className="btn btn-primary btn-short">
              View details
            </Link>
          </div>
        </div>
      ))}
      {list.length > itemsPerPage && (
        <div className="paging">
          <button
            className="btn btn-outline-light"
            onClick={() => navigate(`${paths.SAVED_SEARCH_RESULTS}?page=${page - 1}`)}
            disabled={page === 1}>
            Prev
          </button>
          <button
            className="btn btn-outline-light"
            onClick={() => navigate(`${paths.SAVED_SEARCH_RESULTS}?page=${page + 1}`)}
            disabled={page === pages}>
            Next
          </button>
        </div>
      )}
      {/* maybe at some other time */}
      {/* {list.length > itemsPerPage && (
        <ReactPaginate
          onPageChange={({ selected }) =>
            navigate(`${paths.SAVED_SEARCH_RESULTS}?page=${selected + 1}`)
          }
          forcePage={page - 1}
          pageRangeDisplayed={itemsPerPage}
          pageCount={pages}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Prev"
          containerClassName="pagination-container"
          pageClassName="page"
          pageLinkClassName="pageLink"
          nextClassName="next"
          nextLinkClassName="nextLink"
          previousClassName="prev"
          previousLinkClassName="prevLink"
          breakClassName="break"
          breakLinkClassName="breakLink"
        />
      )} */}
    </div>
  );
});

export default SavedSearchesList;
