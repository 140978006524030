import { observer, useLocalObservable } from 'mobx-react';
import { SEARCH_SAVE_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { paths } from '../../../../utils/constants/routes';
import { Link } from 'react-router-dom';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { formatCurrency, formatCurrencyRange, formatPercent } from '../../../../utils/utils';
import useHistory from '../../../../hooks/useHistory';

const SavedSearchView = observer(({ search = {}, page = 0 }) => {
  const { goBack } = useHistory();
  const { utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    activeTab: 'mandatory', // mandatory, optional
    setActiveTab: (value = '') => (state.activeTab = value)
  }));

  const backLink = paths.SAVED_SEARCH_RESULTS + (page > 1 ? `?page=${page}` : '');
  const onBackClick = (e) => {
    e?.preventDefault?.();
    goBack(paths.SAVED_SEARCH_RESULTS);
  };

  return (
    <div className="details">
      <Link to={backLink} className="backlink" onClick={onBackClick}>
        Back to saved searches
      </Link>
      <div className="title">{search[SEARCH_SAVE_FIELDS.NAME.NAME]}</div>
      <div className="tabs mobile-only">
        <div
          className={`tab ${state.activeTab === 'mandatory' ? 'active' : ''}`}
          onClick={() => state.setActiveTab('mandatory')}>
          Mandatory attributes*
        </div>
        <div
          className={`tab ${state.activeTab === 'optional' ? 'active' : ''}`}
          onClick={() => state.setActiveTab('optional')}>
          Optional attributes
        </div>
      </div>
      <section
        className={`mandatory-fields ${
          state.activeTab === 'mandatory' ? 'mobile-visible' : 'mobile-hidden'
        }`}>
        <div className="section-title">Mandatory attributes*</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.SEARCH_TYPE.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.SEARCH_TYPE)}
            </div>
          </div>
        </div>
        <div className="group-title">{SEARCH_CATEGORIES.GENERAL}</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.INDUSTRY.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.INDUSTRY).join(', ')}
            </div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.GEOGRAPHICAL_FOCUS).join(', ')}
            </div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.HEADQUARTER.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.HEADQUARTER)}
            </div>
          </div>
        </div>
        <div className="group-title">{SEARCH_CATEGORIES.FINANCIALS}</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.REVENUE.LABEL_COMPACT}</div>
            <div className="value">{formatCurrency(search[SEARCH_FIELDS.REVENUE.NAME])}</div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EBITDA.LABEL_COMPACT}</div>
            <div className="value">{formatCurrency(search[SEARCH_FIELDS.EBITDA.NAME])}</div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EBITDA_MARGIN.LABEL_COMPACT}</div>
            <div className="value">{formatPercent(search[SEARCH_FIELDS.EBITDA_MARGIN.NAME])}</div>
          </div>
        </div>
        <div className="group-title">{SEARCH_CATEGORIES.TRANSACTION}</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EQUITY_STAKE.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.EQUITY_STAKE).join(', ')}
            </div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.SITUATION.LABEL}</div>
            <div className="value">{utilsStore.getOptionName(search, SEARCH_FIELDS.SITUATION)}</div>
          </div>
        </div>
        <div className="group-title">{SEARCH_CATEGORIES.VALUATION}</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.ENTERPRISE_MIN.LABEL_COMPACT}</div>
            <div className="value">
              {formatCurrencyRange(
                search[SEARCH_FIELDS.ENTERPRISE_MIN.NAME],
                search[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]
              )}
            </div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EQUITY_MIN.LABEL_COMPACT}</div>
            <div className="value">
              {formatCurrencyRange(
                search[SEARCH_FIELDS.EQUITY_MIN.NAME],
                search[SEARCH_FIELDS.EQUITY_MIN.NAME]
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        className={`optional-fields ${
          state.activeTab === 'optional' ? 'mobile-visible' : 'mobile-hidden'
        }`}>
        <div className="section-title">Optional attributes</div>
        {/* Note: this group is duplicated for mobile view */}
        <div className="group mobile-only">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.SEARCH_TYPE.LABEL}</div>
            <div className="value">
              {utilsStore.getOptionName(search, SEARCH_FIELDS.SEARCH_TYPE)}
            </div>
          </div>
        </div>
        <div className="group-title">{SEARCH_CATEGORIES.FINANCIALS}</div>
        <div className="group">
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EBIT.LABEL_COMPACT}</div>
            <div className="value">{formatCurrency(search[SEARCH_FIELDS.EBIT.NAME])}</div>
          </div>
          <div className="field">
            <div className="label">{SEARCH_FIELDS.EBIT_MARGIN.LABEL_COMPACT}</div>
            <div className="value">{formatPercent(search[SEARCH_FIELDS.EBIT_MARGIN.NAME])}</div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default SavedSearchView;
