import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { mapData } from '../../api/dataMappers';
import { FUND_FIELDS, PORTFOLIO_FIELDS } from '../../utils/constants/fields';

class FundsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.funds = [];
    this.portfolios = [];
    this.loadDataError = null;
    this.isLoading = false;
    this.initialLoad = true;
    this.fundsInAction = {};
    this.portfoliosInAction = {};
    this.isCreatingFund = false;
    this.isCreatingPortfolio = false;
  };

  funds = [];
  portfolios = [];
  loadDataError = null;
  isLoading = false;
  initialLoad = true;
  fundsInAction = {};
  portfoliosInAction = {};
  isCreatingFund = false;
  isCreatingPortfolio = false;
  get isLoadingData() {
    return this.isLoading || this.initialLoad;
  }

  loadFunds = () => {
    this.isLoading = true;
    this.loadDataError = null;
    this.root.makeRequests({
      requests: [
        { endpoint: API_ENDPOINTS.GET_FUNDS },
        { endpoint: API_ENDPOINTS.GET_PORTFOLIO_COMPANIES }
      ],
      onSuccess: (response) => {
        this.funds = mapData(response[0], FUND_FIELDS);
        this.portfolios = mapData(response[1], PORTFOLIO_FIELDS);
      },
      onError: (errorMessage) => {
        this.loadDataError = errorMessage || 'Failed to obtain funds and portoflio companies';
      },
      onFinally: () => {
        this.isLoading = false;
        if (this.initialLoad) {
          this.initialLoad = false;
        }
      }
    });
  };

  editFund = (entry = {}, updatedValues = {}, callback = () => {}) => {
    if (this.fundsInAction[entry.id]) {
      return;
    }

    this.fundsInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_FUND,
      body: { ...entry, ...mapData(updatedValues, FUND_FIELDS, true) },
      onSuccess: (response) => {
        const index = this.funds.findIndex((f) => f.id === entry.id);
        this.funds[index] = { ...this.funds[index], ...mapData(response, FUND_FIELDS) };
        this.root.utilsStore.setHeaderMessage('Successfully edited fund.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(errorMessage || 'Failed to edit fund.', true);
      },
      onFinally: () => {
        this.fundsInAction[entry.id] = false;
      }
    });
  };

  createFund = (fundData = {}, callback = () => {}) => {
    if (this.isCreatingFund) {
      return;
    }

    this.isCreatingFund = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.CREATE_FUND,
      body: { ...mapData(fundData, FUND_FIELDS, true) },
      onSuccess: (response) => {
        this.funds.push(mapData(response, FUND_FIELDS));
        this.root.utilsStore.setHeaderMessage('Successfully created fund.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(errorMessage || 'Failed to create fund.', true);
      },
      onFinally: () => {
        this.isCreatingFund = false;
      }
    });
  };

  deleteFund = (entry = {}, callback = () => {}) => {
    if (this.fundsInAction[entry.id]) {
      return;
    }

    this.fundsInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_FUND,
      body: { id: entry.id },
      onSuccess: () => {
        const index = this.funds.findIndex((f) => f.id === entry.id);
        this.funds.splice(index, 1);
        this.root.utilsStore.setHeaderMessage('Successfully deleted fund.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(errorMessage || 'Failed to delete fund.', true);
      },
      onFinally: () => {
        this.fundsInAction[entry.id] = false;
      }
    });
  };

  editPortfolio = (entry = {}, updatedValues = {}, callback = () => {}) => {
    if (this.portfoliosInAction[entry.id]) {
      return;
    }

    this.portfoliosInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_PORTFOLIO_COMPANY,
      body: { ...entry, ...mapData(updatedValues, PORTFOLIO_FIELDS, true) },
      onSuccess: (response) => {
        const index = this.portfolios.findIndex((p) => p.id === entry.id);
        this.portfolios[index] = {
          ...this.portfolios[index],
          ...mapData(response, PORTFOLIO_FIELDS)
        };
        this.root.utilsStore.setHeaderMessage('Successfully edited portfolio company.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to edit portfolio company.',
          true
        );
      },
      onFinally: () => {
        this.portfoliosInAction[entry.id] = false;
      }
    });
  };

  createPortfolio = (portfolioData = {}, callback = () => {}) => {
    this.isCreatingPortfolio = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.CREATE_PORTFOLIO_COMPANY,
      body: { ...mapData(portfolioData, PORTFOLIO_FIELDS, true) },
      onSuccess: (response) => {
        this.portfolios.push(mapData(response, PORTFOLIO_FIELDS));
        this.root.utilsStore.setHeaderMessage('Successfully created portoflio company.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to create portoflio company.',
          true
        );
      },
      onFinally: () => {
        this.isCreatingPortfolio = false;
      }
    });
  };

  deletePortfolio = (entry = {}, callback = () => {}) => {
    if (this.portfoliosInAction[entry.id]) {
      return;
    }

    this.portfoliosInAction[entry.id] = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_PORTFOLIO_COMPANY,
      body: { id: entry.id },
      onSuccess: () => {
        const index = this.portfolios.findIndex((p) => p.id === entry.id);
        this.portfolios.splice(index, 1);
        this.root.utilsStore.setHeaderMessage('Successfully deleted portfolio company.');
        callback();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to delete portfolio company.',
          true
        );
      },
      onFinally: () => {
        this.portfoliosInAction[entry.id] = false;
      }
    });
  };
}

export default FundsStore;
