import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { PORTFOLIO_FIELDS } from '../../../utils/constants/fields';
import { formatCurrency, formatCurrencyRange } from '../../../utils/utils';
import GeneralLoader from '../../../components/loaders/GeneralLoader';

const PortfoliosList = observer(() => {
  const { fundsStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    deleteConfirmations: {},
    toggleDeleteConfirmation: (id) =>
      (state.deleteConfirmations[id] = !state.deleteConfirmations[id])
  }));

  return (
    <>
      {fundsStore.portfolios.length !== 0 && (
        <div className="table-responsive">
          <div className="header">
            <div className="item">Portfolio name</div>
            <div className="item">Revenue</div>
            <div className="item">EBITDA</div>
            <div className="item">Geo focus</div>
            <div className="item">Industries</div>
            <div className="item">EV ticket</div>
          </div>
          {fundsStore.portfolios.map((entry) => {
            const isLoading = fundsStore.portfoliosInAction[entry.id];
            const showDeleteConfirm = !isLoading && state.deleteConfirmations[entry.id];
            const showActionButtons = !isLoading && !showDeleteConfirm;
            return (
              <div key={entry.id} className="row">
                <div className="item strong">
                  <div className="label">Portfolio name</div>
                  <div className="value">{entry[PORTFOLIO_FIELDS.NAME.NAME]}</div>
                </div>
                <div className="item">
                  <div className="label">Revenue</div>
                  <div className="value">
                    {formatCurrency(entry[PORTFOLIO_FIELDS.REVENUE.NAME])}
                  </div>
                </div>
                <div className="item">
                  <div className="label">EBITDA</div>
                  <div className="value">{formatCurrency(entry[PORTFOLIO_FIELDS.EBITDA.NAME])}</div>
                </div>
                <div className="item">
                  <div className="label">Geo focus</div>
                  <div className="value">
                    {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.GEOGRAPHIES).join(', ')}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Industries</div>
                  <div className="value">
                    {utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.INDUSTRIES).join(', ')}
                  </div>
                </div>
                <div className="item">
                  <div className="label">EV ticket</div>
                  <div className="value">
                    {formatCurrencyRange(
                      entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                      entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
                <div className="actions">
                  {isLoading && <GeneralLoader />}
                  {showActionButtons && (
                    <>
                      <Link
                        className="btn btn-primary btn-short"
                        to={`${paths.FUNDS_EDIT_PORTFOLIO_COMPANY}?portfolioId=${entry.id}`}>
                        Edit
                      </Link>
                      <div
                        className="icon-trash"
                        onClick={() => state.toggleDeleteConfirmation(entry.id)}></div>
                    </>
                  )}
                  {showDeleteConfirm && (
                    <>
                      <Link
                        className="btn btn-outline btn-short"
                        to="#"
                        onClick={(e) =>
                          e.preventDefault() & state.toggleDeleteConfirmation(entry.id)
                        }>
                        Cancel
                      </Link>
                      <Link
                        className="btn btn-danger btn-short"
                        to="#"
                        onClick={(e) => e.preventDefault() & fundsStore.deletePortfolio(entry)}>
                        Delete
                      </Link>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Link className="create-fund-panel" to={paths.FUNDS_CREATE_PORTFOLIO_COMPANY}>
        <div className="icon-add"></div>
        <div className="text">Create new portfolio</div>
      </Link>
    </>
  );
});

export default PortfoliosList;
