import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import Select from '../../../../components/inputs/Select';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import Input from '../../../../components/inputs/Input';
import RangeInput from '../../../../components/inputs/RangeInput';

export const getResultSearchFields = (state, isTypeSelected) => {
  return {
    [SEARCH_FIELDS.SEARCH_TYPE.ID]: (
      <Select
        field={SEARCH_FIELDS.SEARCH_TYPE}
        value={state.fields[SEARCH_FIELDS.SEARCH_TYPE.NAME]}
        setFieldValue={state.setFieldValue}
        inputWrap={{ enable: true, className: 'col' }}
      />
    ),
    [SEARCH_CATEGORIES.GENERAL]: {
      [SEARCH_FIELDS.INDUSTRY.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.INDUSTRY}
          value={state.fields[SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.HEADQUARTER.ID]: (
        <Select
          field={SEARCH_FIELDS.HEADQUARTER}
          value={state.fields[SEARCH_FIELDS.HEADQUARTER.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.GEOGRAPHICAL_FOCUS}
          value={state.fields[SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.FINANCIALS]: {
      [SEARCH_FIELDS.REVENUE.ID]: (
        <Input
          field={SEARCH_FIELDS.REVENUE}
          value={state.fields[SEARCH_FIELDS.REVENUE.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EBITDA.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA}
          value={state.fields[SEARCH_FIELDS.EBITDA.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EBITDA_MARGIN.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA_MARGIN}
          value={state.fields[SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EBIT.ID]: (
        <Input
          field={SEARCH_FIELDS.EBIT}
          value={state.fields[SEARCH_FIELDS.EBIT.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EBIT_MARGIN.ID]: (
        <Input
          field={SEARCH_FIELDS.EBIT_MARGIN}
          value={state.fields[SEARCH_FIELDS.EBIT_MARGIN.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.TRANSACTION]: {
      [SEARCH_FIELDS.SITUATION.ID]: (
        <Select
          field={SEARCH_FIELDS.SITUATION}
          value={state.fields[SEARCH_FIELDS.SITUATION.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EQUITY_STAKE.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.EQUITY_STAKE}
          value={state.fields[SEARCH_FIELDS.EQUITY_STAKE.NAME]}
          setFieldValue={state.setFieldValue}
          disabled={!isTypeSelected}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.VALUATION]: {
      [SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID]: (
        <RangeInput
          fieldMin={SEARCH_FIELDS.ENTERPRISE_MIN}
          fieldMax={SEARCH_FIELDS.ENTERPRISE_MAX}
          valueMin={state.fields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME]}
          valueMax={state.fields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]}
          disabled={!isTypeSelected}
          setFieldValue={state.setFieldValue}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      ),
      [SEARCH_FIELDS.EQUITY_MIN.ID + SEARCH_FIELDS.EQUITY_MAX.ID]: (
        <RangeInput
          fieldMin={SEARCH_FIELDS.EQUITY_MIN}
          fieldMax={SEARCH_FIELDS.EQUITY_MAX}
          valueMin={state.fields[SEARCH_FIELDS.EQUITY_MIN.NAME]}
          valueMax={state.fields[SEARCH_FIELDS.EQUITY_MAX.NAME]}
          disabled={!isTypeSelected}
          setFieldValue={state.setFieldValue}
          messages={state.validationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      )
    }
  };
};
