import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import { useMemo } from 'react';
import { USER_PROFILE_FIELDS } from '../../../../utils/constants/fields';
import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/constants/routes';

const OnboardingSteps = observer(() => {
  const { authStore } = useStore();

  const hasFilledUserInfo = useMemo(() => {
    return !Object.values(USER_PROFILE_FIELDS).find((f) => !authStore.userInfo?.[f.NAME]);
  }, [authStore.userInfo]);

  return (
    <>
      <h2>Onboarding</h2>
      <div className="cards-container">
        <div className="card">
          <div className="heading">
            <div className="icon-onboarding step-1"></div>
            <div className="badge success">Completed</div>
          </div>
          <div className="body">
            <h6>Complete your company profile</h6>
            <p>Enhance your online presence by adding further profile data.</p>
          </div>
        </div>
        <div className="card">
          <div className="heading">
            <div className="icon-onboarding step-2"></div>
            {hasFilledUserInfo ? (
              <div className="badge success">Completed</div>
            ) : (
              <div className="badge info">Pending</div>
            )}
          </div>
          <div className="body">
            {hasFilledUserInfo ? (
              <h6>Complete your user profile</h6>
            ) : (
              <Link to={paths.USER_PROFILE}>
                <h6>Complete your user profile</h6>
              </Link>
            )}
            <p>Enhance your platform presence with a complete user profile.</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default OnboardingSteps;
