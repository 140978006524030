import { useStore } from '../store/store';
import { useMemo } from 'react';

const useAppWrapClass = () => {
  const { authStore, isWaitlistActivated } = useStore();
  const className = useMemo(() => {
    const classes = ['app-wrapper'];
    if (isWaitlistActivated) {
      classes.push('waitlist');
    }

    if (authStore.user) {
      classes.push('user-authorized');
    } else {
      classes.push('user-unknown');
    }

    return classes.join(' ');
  }, [authStore.user, isWaitlistActivated]);

  return className;
};

export default useAppWrapClass;
