import { makeAutoObservable, runInAction } from 'mobx';
import { createContext, useContext } from 'react';
import { request } from '../api/api';
import { getUserPool } from '../api/cognito';
import UtilsStore from './stores/UtilsStore';
import AuthStore from './stores/AuthStore';
import FundsStore from './stores/FundsStore';
import SearchStore from './stores/SearchStore';

class Store {
  constructor() {
    makeAutoObservable(this);

    this.utilsStore = new UtilsStore(this);
    this.authStore = new AuthStore(this);
    this.fundsStore = new FundsStore(this);
    this.searchStore = new SearchStore(this);
  }

  isWaitlistActivated = process.env.REACT_APP_WAITLIST_MODE_ENABLED === 'true';

  resetStore = () => {
    runInAction(() => {
      this.authStore.reset();
      this.fundsStore.reset();
      this.searchStore.reset();
    });
  };

  getToken = async () => {
    return new Promise((resolve) => {
      const cognitoUser = getUserPool().getCurrentUser();
      cognitoUser.getSession((err, result) => {
        if (err) {
          return resolve(null);
        }

        return resolve(result?.idToken?.jwtToken);
      });
    });
  };

  makeRequest = async ({
    endpoint,
    body = {},
    headers = {},
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  }) => {
    if (endpoint.withCognitoToken) {
      const token = await this.getToken();
      if (!token) {
        this.utilsStore.setIsSessionExpired(true);
        return;
      }

      headers['Authorization'] = token;
    }

    request({ endpoint, body, headers })
      .then((response) =>
        runInAction(() => {
          if (response.success) {
            onSuccess(response.data);
          } else {
            onError((response?.errors?.errorMessages || []).join('\n'));
          }
          onFinally();
        })
      )
      .catch((err) => {
        console.error(err);
        // expected API calls to allways return 200 OK
        // this error should mean the gateway returned unauthtorized
        this.utilsStore.setIsSessionExpired(true);
      });
  };

  makeRequests = async ({
    requests = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  }) => {
    let token = null;

    if (requests.findIndex(({ endpoint }) => endpoint.withCognitoToken) !== -1) {
      token = await this.getToken();
      if (!token) {
        this.utilsStore.setIsSessionExpired(true);
        return;
      }
    }

    return Promise.all(
      requests.map(({ endpoint, body = {}, headers = {} }) => {
        if (endpoint.withCognitoToken) {
          headers['Authorization'] = token;
        }

        return request({ endpoint, body, headers });
      })
    )
      .then((responses) =>
        runInAction(() => {
          const errorResponses = responses.filter((r) => !r.success);
          if (errorResponses.length) {
            onError(
              (errorResponses.map((r) => (r?.errors?.errorMessages || []).join('\n')) || []).join(
                '\n'
              )
            );
          } else {
            onSuccess(responses.map((r) => r.data));
          }
          onFinally();
        })
      )
      .catch((err) => {
        console.error(err);
        // expected API calls to allways return 200 OK
        // this error should mean the gateway returned unauthtorized
        this.utilsStore.setIsSessionExpired(true);
      });
  };
}

const store = new Store();

const StoreContext = createContext(store);
const useStore = () => useContext(StoreContext);

export { Store, store, StoreContext, useStore };
