import StaticPageLayout from '../layouts/StaticPageLayout';
import GeneralError from './GeneralError';

const AppError = () => (
  <div className="app-wrapper">
    <StaticPageLayout>
      <GeneralError
        errorMessage="Oops. It seems, that there is а problem loading your user related information or something has gone wrong..."
        actionMessage="You may want to:"
        withRefreshPageButton
        withLogoutButton
      />
    </StaticPageLayout>
  </div>
);

export default AppError;
