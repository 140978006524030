import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { UI_OPTIONS_UTILS } from '../../utils/constants/uiOptions';
import { mapOptions } from '../../api/dataMappers';

class UtilsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
    this.loadOptions();
  }

  isLoadingOptions = false;
  options = null;

  loadOptions = () => {
    if (this.isLoadingOptions) {
      return;
    }

    this.isLoadingOptions = true;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_PUBLIC_OPTIONS,
      onSuccess: (response) => {
        this.options = { ...UI_OPTIONS_UTILS, ...mapOptions(response) };
      },
      onError: () => {
        this.loadOptions(); // non stop requests untill we get the options
      },
      onFinally: () => {
        this.isLoadingOptions = false;
      }
    });
  };

  getOptionName = (data = {}, fieldConfig = {}, optionKey = '') => {
    const options = this.options?.[optionKey || fieldConfig.VALUES_KEY];
    const fieldData = data?.[fieldConfig.NAME] || data;
    if (Array.isArray(fieldData)) {
      return fieldData?.map?.((v) => options?.find?.((o) => o.value === v)?.name || v) || [];
    } else {
      return options?.find?.((o) => o.value === fieldData)?.name || fieldData;
    }
  };

  isMobileHamburgerOpen = false;
  isMobileFooterHidden = false;
  isDesktopMenuOpen = false;

  headerMessageTimestamp = 0;
  headerMessage = { message: '', isError: false, isFading: false };

  isSessionExpired = false;
  setIsSessionExpired = (value = false) => {
    if (value === this.isSessionExpired) {
      return;
    }

    runInAction(() => {
      this.isSessionExpired = value;
    });
  };

  setIsMobileHamburgerOpen = (value = false) => {
    if (value !== this.isMobileHamburgerOpen) {
      runInAction(() => {
        this.isMobileHamburgerOpen = value;
      });
    }
  };

  setIsDesktopMenuOpen = (value = false) => {
    if (value !== this.isDesktopMenuOpen) {
      runInAction(() => {
        this.isDesktopMenuOpen = value;
      });
    }
  };

  setHeaderMessage = (message = '', isError = false) => {
    runInAction(() => {
      this.headerMessage = { message, isError, isFading: false };
      this.headerMessageTimestamp = new Date().getTime();
    });

    // TODO: configurable durations for fadeout and disappear
    setTimeout(() => {
      if (this.headerMessageTimestamp + 3_000 <= new Date().getTime()) {
        runInAction(() => {
          this.headerMessage = { ...this.headerMessage, isFading: true };
        });
      }
    }, 3_001);

    setTimeout(() => {
      if (this.headerMessageTimestamp + 4_000 <= new Date().getTime()) {
        runInAction(() => {
          this.headerMessage = { message: '', isError: false };
        });
      }
    }, 4_001);
  };

  setIsMobileFooterHidden = (value = false) => {
    if (value !== this.isMobileFooterHidden) {
      runInAction(() => {
        this.isMobileFooterHidden = value;
      });
    }
  };
}

export default UtilsStore;
