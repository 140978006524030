import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { ACCOUNT_TYPES, ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import { paths } from '../../utils/constants/routes';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const LOGOUT_ITEMS = [
  { href: paths.TERMS_N_CONDITIONS, name: 'Terms & conditions' },
  // { href: paths.COOKIE_POLICY, name: 'Cookie Policy' },
  { href: paths.PRIVACY_POLICY, name: 'Privacy Policy' },
  { href: paths.IMPRINT, name: 'Imprint' }
];

const LOGIN_ITEMS = [
  { href: paths.USER_PROFILE, name: 'My account' },
  { href: paths.COMPANY_PROFILE, name: 'My company' },
  { href: paths.FUNDS, name: 'My funds & portfolios', investorOnly: true }
  // TODO: uncomment when Saved searches are needed
  // { href: paths.SAVED_SEARCH_RESULTS, name: 'My saved searches', agentOnly: true }
];

const MobileNavigation = observer(() => {
  const { utilsStore, authStore } = useStore();

  const { userAccountType, user, logout, userVerificationStatus, hasUserProfileCompany } =
    authStore;

  const state = useLocalObservable(() => ({
    isLogoutConfirmationOpen: false,
    setIsLogoutConfirmationOpen: (value = false) => (state.isLogoutConfirmationOpen = value)
  }));

  useEffect(() => {
    if (utilsStore.isMobileHamburgerOpen && state.isLogoutConfirmationOpen) {
      state.setIsLogoutConfirmationOpen();
    }
  }, [utilsStore.isMobileHamburgerOpen]);

  const renderMenuItem = ({ href, name, investorOnly, agentOnly }, idx) => {
    const selected = window.location.pathname === href;
    let className = selected ? 'selected' : '';
    if (investorOnly && userAccountType !== ACCOUNT_TYPES.INVESTOR) {
      className += ' hidden';
    }
    if (agentOnly && userAccountType !== ACCOUNT_TYPES.AGENT) {
      className += ' hidden';
    }
    return (
      <li key={idx} className={className}>
        <Link to={href} onClick={() => utilsStore.setIsMobileHamburgerOpen()}>
          {name}
        </Link>
      </li>
    );
  };

  const showLoginItems =
    userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED && hasUserProfileCompany;

  return (
    <nav className={`${utilsStore.isMobileHamburgerOpen ? 'opened' : 'closed'}`}>
      {state.isLogoutConfirmationOpen ? (
        <div className="confirm-logout">
          <div className="text">Do you really want to log out of the platform?</div>
          <div className="actions">
            <a
              to="#"
              className="btn btn-primary"
              onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen()}>
              No, stay logged in
            </a>
            <a
              className="btn btn-outline"
              onClick={() =>
                logout() &
                state.setIsLogoutConfirmationOpen() &
                utilsStore.setIsMobileHamburgerOpen()
              }>
              Yes, log me out
            </a>
          </div>
        </div>
      ) : (
        <>
          <ul>
            {showLoginItems && LOGIN_ITEMS.map(renderMenuItem)}
            {!showLoginItems && LOGOUT_ITEMS.map(renderMenuItem)}
          </ul>
          {user && (
            <div className="logout">
              <a
                href="#"
                onClick={(e) => e.preventDefault() & state.setIsLogoutConfirmationOpen(true)}
                // id="logout-menu-button"
              >
                Log out
              </a>
            </div>
          )}
        </>
      )}
    </nav>
  );
});

export default MobileNavigation;
