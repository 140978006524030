import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import { Fragment } from 'react';

const DesktopFilters = observer(
  ({
    fields,
    fieldsCount,
    searchSectionsToggle,
    toggleSearchSection,
    onSearch,
    isSearchDisabled
  }) => {
    return (
      <div className="desktop-filters">
        {fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
        {Object.values(SEARCH_CATEGORIES).map((section) => {
          const { filled, total } = fieldsCount[section];
          return (
            <div className="filter-category" key={section}>
              <button onClick={() => toggleSearchSection(section)}>
                <span>
                  {section} {filled}/{total}
                </span>
                <img
                  src="/icons/chevron-down.svg"
                  className={`${searchSectionsToggle[section] ? 'expanded' : 'collapsed'}`}
                />
              </button>
              {searchSectionsToggle[section] &&
                Object.values(fields[section]).map((field, idx) => (
                  <Fragment key={idx}>{field}</Fragment>
                ))}
            </div>
          );
        })}
        <button
          className="btn btn-primary disable-fade-primary"
          onClick={onSearch}
          disabled={isSearchDisabled}>
          Search
        </button>
      </div>
    );
  }
);

export default DesktopFilters;
