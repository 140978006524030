import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { paths } from '../../utils/constants/routes';
import { Link } from 'react-router-dom';
import { ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';

const FOOTER_ITEMS = [
  { href: paths.TERMS_N_CONDITIONS, name: 'Terms & conditions' },
  // { href: paths.COOKIE_POLICY, name: 'Cookie Policy' },
  { href: paths.PRIVACY_POLICY, name: 'Privacy Policy' },
  { href: paths.IMPRINT, name: 'Imprint' }
];

const Footer = observer(() => {
  const { authStore, utilsStore } = useStore();
  const { userAccountType, user, logout, userVerificationStatus, hasUserProfileCompany } =
    authStore;

  const classNames = [];
  if (authStore.user && userVerificationStatus === ACCOUNT_VERIF_TYPES.APPROVED) {
    classNames.push('logged-in');
  } else {
    classNames.push('logged-out');
  }
  if (utilsStore.isMobileFooterHidden && !utilsStore.isMobileHamburgerOpen) {
    classNames.push('hidden');
  }

  return (
    <>
      <footer className={classNames.join(' ')}>
        <div className="container">
          <div className="logo">
            <Link to={paths.HOME}>
              <strong>transact.</strong>digital
            </Link>
          </div>
          <div className="links">
            {FOOTER_ITEMS.map(({ href, name }, idx) => (
              <Link
                to={href}
                key={idx}
                onClick={() => {
                  utilsStore.isMobileHamburgerOpen && utilsStore.setIsMobileHamburgerOpen();
                }}>
                {name}
              </Link>
            ))}
            <div className="copyright">© transact.digital GmbH i. G.</div>
          </div>
        </div>
      </footer>
    </>
  );
});

export default Footer;
