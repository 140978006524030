import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import Reroute from '../../components/navigation/Reroute';
import { paths } from '../../utils/constants/routes';
import { useEffect, useMemo } from 'react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import FundsList from './components/FundsList';
import PortfoliosList from './components/PortfoliosList';
import { FUNDS_PATH_COMPONENTS, FUNDS_VIEW_MODE } from './constants';
import FundView from './components/FundView';
import PortfolioView from './components/PortfolioView';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import GeneralError from '../../components/errors/GeneralError';
import useHistory from '../../hooks/useHistory';

const Funds = observer(() => {
  const { fundsStore } = useStore();
  const { location, params, goBack } = useHistory();

  useEffect(() => {
    if (fundsStore.initialLoad && !fundsStore.isLoading) {
      fundsStore.loadFunds();
    }
  }, [fundsStore]);

  const [fundId, editFundEntry, portfolioId, editPortfolioEntry] = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const [fundId, portfolioId] = [urlParams.get('fundId'), urlParams.get('portfolioId')];
    const [editFundEntry, editPortfolioEntry] = [
      fundsStore.funds.find((f) => f.id == fundId),
      fundsStore.portfolios.find((f) => f.id == portfolioId)
    ];
    return [fundId, editFundEntry, portfolioId, editPortfolioEntry];
  }, [fundsStore, location.search, fundsStore.isLoading]);

  const component = params.component;
  const showFundsList = !component;
  const showPrtofliosList = component === FUNDS_PATH_COMPONENTS.PORTFOLIO_COMPANIES;
  const showFundView = [
    FUNDS_PATH_COMPONENTS.CREATE_FUND,
    FUNDS_PATH_COMPONENTS.EDIT_FUND
  ].includes(component);
  const showPortfolioView = [
    FUNDS_PATH_COMPONENTS.CREATE_PORTOLIO_COMPANY,
    FUNDS_PATH_COMPONENTS.EDIT_PORTFOLIO_COMPANY
  ].includes(component);
  const hideMobileFooter = !(showFundsList || showPrtofliosList);

  if (fundsStore.isLoadingData) {
    return <GeneralLoader />;
  }

  if (fundsStore.loadDataError) {
    return (
      <StaticPageLayout
        page="investor-funds-listing"
        hideMobileFooter={hideMobileFooter}
        component={component || 'default'}>
        <GeneralError
          errorMessage={fundsStore.loadDataError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext={'Try again'}
          onActionButtonClick={fundsStore.loadFunds}
        />
      </StaticPageLayout>
    );
  }

  if (component === FUNDS_PATH_COMPONENTS.EDIT_FUND && (!fundId || !editFundEntry)) {
    return <Reroute path={paths.FUNDS} />;
  }

  if (
    component === FUNDS_PATH_COMPONENTS.EDIT_PORTFOLIO_COMPANY &&
    (!portfolioId || !editPortfolioEntry)
  ) {
    return <Reroute path={paths.FUNDS_PORTOFLIO_COMPANIES} />;
  }

  return (
    <StaticPageLayout
      page="investor-funds-listing"
      hideMobileFooter={hideMobileFooter}
      component={component || 'default'}>
      {(showFundsList || showPrtofliosList) && (
        <>
          <div className="gray-title-panel">
            <div className="title-section">
              <h4>
                {component === FUNDS_PATH_COMPONENTS.PORTFOLIO_COMPANIES
                  ? 'Portfolio companies'
                  : 'Funds'}
              </h4>
              <h6>
                {component === FUNDS_PATH_COMPONENTS.PORTFOLIO_COMPANIES
                  ? 'Control your portfolio companies at any time'
                  : 'Control your funds at any time'}
              </h6>
            </div>
          </div>
          <div className="tabs">
            <div
              className={`tab ${showFundsList ? 'active' : ''}`}
              onClick={() => goBack(paths.FUNDS)}>
              Funds
            </div>
            <div
              className={`tab ${showPrtofliosList ? 'active' : ''}`}
              onClick={() => goBack(paths.FUNDS_PORTOFLIO_COMPANIES)}>
              <div className="desktop-text">Portfolio companies</div>
              <div className="mobile-text">Companies</div>
            </div>
          </div>
        </>
      )}
      {showFundsList && <FundsList />}
      {showPrtofliosList && <PortfoliosList />}
      {showFundView && (
        <FundView
          fund={editFundEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE_FUND === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE_FUND === component ? 'c-f' : 'e-f') +
            (editFundEntry?.id || '')
          }
        />
      )}
      {showPortfolioView && (
        <PortfolioView
          portfolio={editPortfolioEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE_PORTOLIO_COMPANY === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE_PORTOLIO_COMPANY === component ? 'c-p' : 'e-p') +
            (editPortfolioEntry?.id || '')
          }
        />
      )}
    </StaticPageLayout>
  );
});

export default Funds;
