import { Store } from '../store'; // eslint-disable-line no-unused-vars
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { makeAutoObservable, runInAction } from 'mobx';
import { getUserPool, exchangeCodeForTokens, getCognitoUser } from '../../api/cognito';
import {
  COMPANY_PROFILE_FIELDS,
  USER_PROFILE_FIELDS,
  USER_CHANGE_PASSWORD_FIELDS
} from '../../utils/constants/fields';
import { generateMessage } from '../../utils/utils';
import { API_ENDPOINTS } from '../../api/endpoints';
import { ACCOUNT_VERIF_TYPES, ACCOUNT_TYPES } from '../../utils/constants/auth';
import { mapData } from '../../api/dataMappers';
import { eventWaitlistFormStep2, eventWaitlistFormStep1 } from '../../ga4/ga4';

class AuthStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;

    let [persistentCheck, linkedinCheck] = [false, false];

    // check if user is logged in
    const cognitoUser = getUserPool().getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        persistentCheck = true;
        if (!error) {
          runInAction(() => {
            this.user = session;
          });
        }
        if (persistentCheck && linkedinCheck) {
          this.getAccountDetails();
        }
      });
    } else {
      persistentCheck = true;
      if (persistentCheck && linkedinCheck) {
        this.getAccountDetails();
      }
    }

    // linkedin authorization code flow
    // check if url for authenticate&code=f8c9a8f8-e63d-41d8-83c5-9dc9fa6a3d56
    // if yes, then get the code and exchange it for access token
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authenticate = urlParams.get('authenticate');
      const code = urlParams.get('code');
      if (code && typeof authenticate !== 'undefined') {
        const session = await exchangeCodeForTokens(code);
        runInAction(() => {
          this.user = session;
        });
        // clear url params
        window.history.replaceState({}, document.title, window.location.pathname);
      }
      linkedinCheck = true;
      if (persistentCheck && linkedinCheck) {
        this.getAccountDetails();
      }
    })();
  }

  reset = () => {
    this.showLoginVerifyScreen = null;
    this.user = null;
    this.alreadyVerifiedEmail = null;
    this.userInfo = null;
    this.accountInfoError = null;
    this.isUpdatingUserInfo = false;
    this.userAccountType = ACCOUNT_TYPES.NOT_OBTAINED;
    this.userVerificationStatus = ACCOUNT_VERIF_TYPES.NOT_OBTAINED;
    this.userCompanyType = null;
    this.hasUserProfileCompany = false;
    this.userProfileCompany = null;
    this.isAuthenticating = false;
    this.authErrors = { messages: [], invalidFields: [] };
    this.signUpStep = 1;
    this.signUpCognitoUser = null;
    this.forgottenPwdStep = 1;
  };

  showLoginVerifyScreen = null;
  user = null;
  alreadyVerifiedEmail = null;
  userInfo = null;
  accountInfoError = null;
  isUpdatingUserInfo = false;
  userAccountType = ACCOUNT_TYPES.OBTAINING;
  userVerificationStatus = ACCOUNT_VERIF_TYPES.OBTAINING;
  userCompanyType = null;
  hasUserProfileCompany = false;
  userProfileCompany = null;
  isAuthenticating = true; // auth action flag for every auth page
  authErrors = { messages: [], invalidFields: [] }; // auth error for every auth page

  signUpStep = 1; // 1 - register, 2 - verify code, 3 - all done
  signUpCognitoUser = null;

  forgottenPwdStep = 1;

  get isLoadingAccountDetails() {
    return (
      this.userVerificationStatus === ACCOUNT_VERIF_TYPES.OBTAINING ||
      this.userAccountType === ACCOUNT_TYPES.OBTAINING
    );
  }

  cleanUpAuthPage = () => {
    runInAction(() => {
      this.authErrors = { messages: [], invalidFields: [] };

      this.signUpStep = 1;
      this.signUpCognitoUser = null;

      this.forgottenPwdStep = 1;
    });
  };

  signup = (fields) => {
    const { email, password } = fields;
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
    });

    let messages = [];
    let invalidFields = [];

    if (invalidFields.length) {
      runInAction(() => {
        this.isAuthenticating = false;
        this.authErrors = { messages, invalidFields };
      });
      return;
    }

    // validation is OK

    const userPool = getUserPool();
    const attributeList = [];
    const skipFields = ['password'];
    const customFields = ['company_name', 'company_type', 'phone_code' /*'marketing_consent'*/];
    for (const [key, value] of Object.entries(fields)) {
      let keyName = key;
      if (skipFields.includes(key)) {
        continue;
      }
      if (customFields.includes(key)) {
        keyName = `custom:${key}`;
      }
      if (keyName === 'phone_number') {
        attributeList.push(
          new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: keyName,
            Value: fields['phone_code'] + value
          })
        );
      } else {
        if (value) {
          attributeList.push(
            new AmazonCognitoIdentity.CognitoUserAttribute({
              Name: keyName,
              Value: value
            })
          );
        }
      }
    }
    const opts = this.root.utilsStore?.options?.companyTypes;
    const companyTypeReadable = opts?.find((opt) => opt.value == fields['company_type'])?.name;
    this.userCompanyType = companyTypeReadable;

    // TODO: any captcha to be sent as validationData
    // const validationData = [{ Name: 'foo', Value: 'bar' }];
    const validationData = null;

    userPool.signUp(email, password, attributeList, validationData, (error, result) => {
      if (error) {
        if (error.name === 'UsernameExistsException') {
          // directly send a new verification code and send user to step 2
          // ideally we should check user status and if it's unconfirmed, then we should send a new verification code
          // for the waitlist, it might be too much effort
          runInAction(() => {
            this.signUpCognitoUser = getCognitoUser(email);
            this.signUpStep = 2;
          });

          this.resendSignUpCode(true);
          return;
        }

        messages.push(generateMessage(error.message, false));
        runInAction(() => {
          this.isAuthenticating = false;
          this.authErrors = { messages, invalidFields };
        });
      } else {
        eventWaitlistFormStep1(this.userCompanyType);

        runInAction(() => {
          this.isAuthenticating = false;
          this.signUpCognitoUser = result.user;
          this.signUpStep = 2;
        });
      }
    });
  };

  resendSignUpCode = (UsernameExistsException = false) => {
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
    });

    this.signUpCognitoUser.resendConfirmationCode((error) => {
      if (error) {
        if (error.name === 'InvalidParameterException' && UsernameExistsException) {
          runInAction(() => {
            this.isAuthenticating = false;
            this.alreadyVerifiedEmail = this.signUpCognitoUser.username;
          });
        } else {
          runInAction(() => {
            this.isAuthenticating = false;
            this.authErrors = {
              messages: [generateMessage(error.message, false)],
              invalidFields: []
            };
          });
          return;
        }
      }

      runInAction(() => {
        this.isAuthenticating = false;
      });
    });
  };

  confirmSignUp = (verificationCode, email, password) => {
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
      this.signUpCognitoUser.confirmRegistration(verificationCode, true, (error) => {
        if (error) {
          runInAction(() => {
            this.isAuthenticating = false;
            this.authErrors = {
              messages: [generateMessage(error.message, false)],
              invalidFields: []
            };
          });
          return;
        }

        eventWaitlistFormStep2(this.userCompanyType);

        runInAction(() => {
          this.isAuthenticating = false;
          this.signUpStep = 3;

          if (
            !this.root.isWaitlistActivated &&
            (email || this.showLoginVerifyScreen?.email) &&
            (password || this.showLoginVerifyScreen?.password)
          ) {
            this.login(
              email || this.showLoginVerifyScreen?.email,
              password || this.showLoginVerifyScreen?.password
            );
          }
        });
      });
    });
  };

  login = (email = '', password = '') => {
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
    });

    const authenticationData = {
      Username: email,
      Password: password
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );

    const cognitoUser = getCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        runInAction(() => {
          this.user = result;
          this.isAuthenticating = false;
        });
        this.getAccountDetails();
      },
      onFailure: (error) => {
        if (error.name === 'UserNotConfirmedException') {
          runInAction(() => {
            this.isAuthenticating = false;
            this.signUpStep = 2;
          });
          this.signup({ email, password });
          this.showLoginVerifyScreen = { email, password };
        } else {
          runInAction(() => {
            this.isAuthenticating = false;
            this.authErrors = {
              messages: [generateMessage(error.message, false)],
              invalidFields: []
            };
          });
        }
      }
    });
  };

  requestResetPassword = (email) => {
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
    });

    const cognitoUser = getCognitoUser(email);
    cognitoUser.forgotPassword({
      onSuccess: () => {
        runInAction(() => {
          this.isAuthenticating = false;
          this.forgottenPwdStep = 2;
        });
      },
      onFailure: (error) => {
        runInAction(() => {
          this.isAuthenticating = false;
          this.authErrors = {
            messages: [generateMessage(error.message, false)],
            invalidFields: []
          };
        });
      }
    });
  };

  submitPasswordReset = (email, code, password) => {
    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = { messages: [], invalidFields: [] };
    });

    // validate new password match - not having repeatPassword now
    // if (password !== passwordRepeat) {
    //   runInAction(() => {
    //     this.isAuthenticating = false;
    //     this.authError = 'Passwords do not match';
    //   });
    //   return;
    // }

    const cognitoUser = getCognitoUser(email);
    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        runInAction(() => {
          this.isAuthenticating = false;
          this.forgottenPwdStep = 3;
        });
      },
      onFailure: (error) => {
        runInAction(() => {
          this.isAuthenticating = false;
          this.authErrors = {
            messages: [generateMessage(error.message, false)],
            invalidFields: []
          };
        });
      }
    });
  };

  logout = () => {
    if (this.user) {
      const cognitoUser = getCognitoUser(this.user.accessToken.payload.username);
      cognitoUser.signOut();
    }

    this.root.resetStore();
  };

  deactivateAccount = () => {
    runInAction(() => (this.isAuthenticating = true));
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DEACTIVATE_USER_ACCOUNT,
      onSuccess: () => {
        this.userInfo.isDeactivatingAccount = true;
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(errorMessage || 'Failed to deactivate account', true);
      },
      onFinally: () => {
        this.isAuthenticating = false;
      }
    });
  };

  changePassword = (oldPassword, newPassword) => {
    const cognitoUser = getUserPool().getCurrentUser();
    if (!cognitoUser) {
      return;
    }

    runInAction(() => {
      this.isAuthenticating = true;
      this.authErrors = {
        messages: [],
        invalidFields: []
      };
    });

    cognitoUser.getSession((err) => {
      if (err) {
        runInAction(() => {
          this.isAuthenticating = false;
          this.authErrors.messages.push(generateMessage(err.message || JSON.stringify(err), false));
        });
      } else {
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          runInAction(() => {
            if (err) {
              if (err.code === 'NotAuthorizedException') {
                this.authErrors.messages.push(
                  generateMessage(
                    'Old password is wrong',
                    false,
                    USER_CHANGE_PASSWORD_FIELDS.OLD_PASSWORD.NAME
                  )
                );
                this.authErrors.invalidFields.push(USER_CHANGE_PASSWORD_FIELDS.OLD_PASSWORD.NAME);
              } else {
                this.authErrors.messages.push(
                  generateMessage(err.message || JSON.stringify(err), false)
                );
              }
            } else {
              this.authErrors = { messages: [], invalidFields: [] };
              this.root.utilsStore.setHeaderMessage('Password updated sucessfully');
            }
            this.isAuthenticating = false;
          });
        });
      }
    });
  };

  updateUserInfo = (data = {}) => {
    if (this.isUpdatingUserInfo) {
      return;
    }

    runInAction(() => (this.isUpdatingUserInfo = true));
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.EDIT_USER_INFO,
      body: mapData({ ...this.userInfo, ...data }, USER_PROFILE_FIELDS, true),
      onSuccess: (response) => {
        this.userInfo = response;
        this.root.utilsStore.setHeaderMessage('User profile updated sucessfully');
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || 'Failed to update user profile',
          true
        );
      },
      onFinally: () => {
        this.isUpdatingUserInfo = false;
      }
    });
  };

  getAccountDetails = () => {
    if (!this.user) {
      runInAction(() => {
        this.userInfo = null;
        this.isAuthenticating = false;
        this.userVerificationStatus = ACCOUNT_VERIF_TYPES.NOT_OBTAINED;
        this.userAccountType = ACCOUNT_TYPES.NOT_OBTAINED;
      });
      return;
    }

    runInAction(() => {
      this.isAuthenticating = true;
      this.userVerificationStatus = ACCOUNT_VERIF_TYPES.OBTAINING;
      this.userAccountType = ACCOUNT_TYPES.OBTAINING;
      this.accountInfoError = null;
    });

    this.root.makeRequests({
      requests: [
        { endpoint: API_ENDPOINTS.GET_ACCOUNT_VERIFICATION_PROGRESS },
        { endpoint: API_ENDPOINTS.GET_ACCOUNT_TYPE },
        { endpoint: API_ENDPOINTS.GET_PROFILE_COMPANY },
        { endpoint: API_ENDPOINTS.GET_USER_INFO }
      ],
      onSuccess: (response) => {
        this.userVerificationStatus = response[0];
        this.userAccountType = response[1];
        this.userProfileCompany = mapData(response[2], COMPANY_PROFILE_FIELDS);
        this.userInfo = mapData(response[3], USER_PROFILE_FIELDS);
        this.hasUserProfileCompany = !Object.values(COMPANY_PROFILE_FIELDS)
          .filter((f) => f.REQUIRED)
          .map((r) => r.NAME)
          .find((fieldName) => !this.userProfileCompany[fieldName]);
      },
      onError: (errorMessage) => {
        this.userInfo = null;
        this.userVerificationStatus = ACCOUNT_VERIF_TYPES.NOT_OBTAINED;
        this.userAccountType = ACCOUNT_TYPES.NOT_OBTAINED;
        this.userProfileCompany = null;
        this.hasUserProfileCompany = false;
        this.accountInfoError = errorMessage || 'Failed to fetch account information.';
      },
      onFinally: () => {
        this.isAuthenticating = false;
      }
    });
  };
}

export default AuthStore;
