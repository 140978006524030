import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { formatCurrency, formatCurrencyRange } from '../../../utils/utils';
import { FUND_FIELDS } from '../../../utils/constants/fields';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { PUNCTUATION } from '../../../utils/constants/notation';

const FundsList = observer(() => {
  const { fundsStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    deleteConfirmations: {},
    toggleDeleteConfirmation: (id) =>
      (state.deleteConfirmations[id] = !state.deleteConfirmations[id])
  }));

  return (
    <>
      {fundsStore.funds.length !== 0 && (
        <div className="table-responsive">
          <div className="header">
            <div className="item">Fund name</div>
            <div className="item">Year</div>
            <div className="item">Volume</div>
            <div className="item">Geo focus</div>
            <div className="item">Industries</div>
            <div className="item">EV ticket</div>
          </div>
          {fundsStore.funds.map((entry) => {
            const isLoading = fundsStore.fundsInAction[entry.id];
            const showDeleteConfirm = !isLoading && state.deleteConfirmations[entry.id];
            const showActionButtons = !isLoading && !showDeleteConfirm;
            return (
              <div key={entry.id} className="row">
                <div className="item strong">
                  <div className="label">Fund name</div>
                  <div className="value">{entry[FUND_FIELDS.NAME.NAME]}</div>
                </div>
                <div className="item">
                  <div className="label">Year</div>
                  <div className="value">
                    {entry[FUND_FIELDS.VINTAGE.NAME] || PUNCTUATION.EMPTY_VALUE}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Volume</div>
                  <div className="value">{formatCurrency(entry[FUND_FIELDS.VOLUME.NAME])}</div>
                </div>
                <div className="item">
                  <div className="label">Geo focus</div>
                  <div className="value">
                    {utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES).join(', ')}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Industries</div>
                  <div className="value">
                    {utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES).join(', ')}
                  </div>
                </div>
                <div className="item">
                  <div className="label">EV ticket</div>
                  <div className="value">
                    {formatCurrencyRange(
                      entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                      entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                    )}
                  </div>
                </div>
                <div className="actions">
                  {isLoading && <GeneralLoader />}
                  {showActionButtons && (
                    <>
                      <Link
                        className="btn btn-primary btn-short"
                        to={`${paths.FUNDS_EDIT_FUND}?fundId=${entry.id}`}>
                        Edit
                      </Link>
                      <div
                        className="icon-trash"
                        onClick={() => state.toggleDeleteConfirmation(entry.id)}></div>
                    </>
                  )}
                  {showDeleteConfirm && (
                    <>
                      <Link
                        className="btn btn-outline btn-short"
                        to="#"
                        onClick={(e) =>
                          e.preventDefault() & state.toggleDeleteConfirmation(entry.id)
                        }>
                        Cancel
                      </Link>
                      <Link
                        className="btn btn-danger btn-short"
                        to="#"
                        onClick={(e) => e.preventDefault() & fundsStore.deleteFund(entry)}>
                        Delete
                      </Link>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Link className="create-fund-panel" to={paths.FUNDS_CREATE_FUND}>
        <div className="icon-add"></div>
        <div className="text">Create new fund</div>
      </Link>
    </>
  );
});

export default FundsList;
