const NoResults = () => {
  return (
    <div className="no-results">
      <h5>Oops, it seems that we couldn&apos;t find any matches.</h5>

      <div className="text-help">Search help</div>
      <ul>
        <li>Check your search for typos or invalid data</li>
        <li>
          Keep in mind that some search combinations might not have any relevant or matching data
        </li>
        <li>
          Need help finding something ? Contact us at{' '}
          <a href="mailto:info@transact.digital">info@transact.digital</a>
        </li>
      </ul>
    </div>
  );
};

export default NoResults;
