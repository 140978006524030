import { validatePasswordComplexity, generateMessage, isUsingCompanyEmail } from '../utils';
import { OPTION_KEYS } from './optionKeys';
import { UI_OPTION_KEYS } from './uiOptionKeys';
import { SEARCH_CATEGORIES } from './searchCategories';
import { phone } from 'phone';
import { runInAction } from 'mobx';
import { FUNDS_VIEW_TABS, PORTFOLIOS_VIEW_TABS } from '../../pages/Funds/constants';

export const FIELDS_TYPES = {
  TYPE_EMAIL: 'email',
  TYPE_PASSWORD: 'password',
  TYPE_TEXT: 'text',
  TYPE_NUMBER: 'number',
  TYPE_TEXTAREA: 'textarea',
  TYPE_SELECT: 'select',
  TYPE_MULTI_SELECT: 'multi_select',
  TYPE_CHECKBOX: 'checkbox'
};

export const LOGIN_FIELDS = {
  EMAIL: {
    NAME: 'email',
    ID: 'email',
    LABEL: 'Email address',
    REQUIRED: true,
    PLACEHOLDER: 'Email',
    TYPE: FIELDS_TYPES.TYPE_EMAIL
  },
  PASSWORD: {
    NAME: 'password',
    ID: 'password',
    LABEL: 'Password',
    REQUIRED: true,
    PLACEHOLDER: 'Password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    FORMAT: (value = '') => value.trim()
  }
};

export const SIGNUP_FIELDS = {
  FIRST_NAME: {
    NAME: 'first_name',
    ID: 'first_name',
    LABEL: 'First name',
    REQUIRED: true,
    PLACEHOLDER: 'First name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('First name maximum text length is 20', value.length <= 20)];
    }
  },
  LAST_NAME: {
    NAME: 'last_name',
    ID: 'last_name',
    LABEL: 'Last name',
    REQUIRED: true,
    PLACEHOLDER: 'Last name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Last name maximum text length is 20', value.length <= 20)];
    }
  },
  COMPANY_NAME: {
    NAME: 'company_name',
    ID: 'company_name',
    LABEL: 'Company',
    REQUIRED: true,
    PLACEHOLDER: 'Company',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Company name maximum text length is 50', value.length <= 50)];
    }
  },
  COMPANY_TYPE: {
    NAME: 'company_type',
    ID: 'company_type',
    LABEL: 'Company type',
    REQUIRED: true,
    PLACEHOLDER: 'Company type',
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.COMPANY_TYPES
  },
  EMAIL: {
    NAME: 'email',
    ID: 'email',
    LABEL: 'Email address',
    REQUIRED: true,
    PLACEHOLDER: 'Email address',
    TYPE: FIELDS_TYPES.TYPE_EMAIL,
    VALIDATE: (value = '') => {
      return [generateMessage('Please use your company email address', isUsingCompanyEmail(value))];
    }
  },
  PHONE_CODE: {
    NAME: 'phone_code',
    ID: 'phone_code',
    LABEL: 'Country Code',
    REQUIRED: false,
    PLACEHOLDER: 'DE +49',
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.PHONE_CODES
  },
  PHONE_NUMBER: {
    NAME: 'phone_number',
    ID: 'phone_number',
    LABEL: 'Phone number',
    REQUIRED: false,
    PLACEHOLDER: 'Phone',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    FORMAT: (value = '') => {
      const pattern = /\d+/g;
      const result = value.match(pattern);
      if (!result) {
        return '';
      }

      let formatted = result.join('');
      while (formatted.startsWith('0')) {
        formatted = formatted.slice(1);
      }

      return formatted;
    }
  },
  PASSWORD: {
    NAME: 'password',
    ID: 'password',
    LABEL: 'Password',
    REQUIRED: true,
    PLACEHOLDER: 'Password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    FORMAT: (value = '') => value.trim(),
    VALIDATE: validatePasswordComplexity
  },
  RE_PASSWORD: {
    NAME: 'repeatPassword',
    ID: 'repeatPassword',
    LABEL: 'Repeat password',
    REQUIRED: true,
    PLACEHOLDER: 'Repeat password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    FORMAT: (value = '') => value.trim()
  },
  TOS: {
    NAME: 'TOS',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_CHECKBOX,
    INPUT_CLASSNAME: 'tos',
    LABEL_CLASSNAME: 'no-padding field-tos checkbox-font',
    ON_SUBMIT_ERROR_STATE_WRAP_CLASSNAME: 'checkbox-container',
    VALIDATE: (value) => {
      return [
        generateMessage(
          'Please agree with our Terms & conditions in order to sign up',
          value === true
        )
      ];
    }
  },
  // MARKETING_CONSENT: {
  //   NAME: 'marketing_consent',
  //   LABEL:
  //     'Yes, I agree that transact.digital GmbH may send me personalized advertisements and information tailored to my personal interests (e.g., newsletters, event invitations, product updates) via e-mail, SMS and instant messages from the corporate finance sector.',
  //   REQUIRED: false,
  //   TYPE: FIELDS_TYPES.TYPE_CHECKBOX,
  //   INPUT_CLASSNAME: 'tos',
  //   LABEL_CLASSNAME: 'no-padding field-tos checkbox-font'
  // },
  VERIF_CODE: {
    NAME: 'verificationCode',
    ID: 'code',
    LABEL: 'Verification code',
    REQUIRED: true,
    PLACEHOLDER: 'Verification code',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    INPUT_CLASSNAME: 'form-control verify-input',
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    FORMAT: (value = '') => {
      if (!new RegExp('^[0-9-]{0,11}$').test(value)) {
        return '';
      }

      return value.replace(/-/g, '').split('').join('-');
    },
    DEFORMAT: (value = '') => {
      return value.split('-').join('');
    },
    VALIDATE: (value = '') => {
      const messages = [];

      let isValid = true;
      if (
        !new RegExp('^[0-9-]{0,11}$').test(value) ||
        value.length < SIGNUP_FIELDS.VERIF_CODE.MINLENGTH ||
        value.length > SIGNUP_FIELDS.VERIF_CODE.MAXLENGTH
      ) {
        isValid = false;
      }

      messages.push(generateMessage('Verification code must be 6 digits long', isValid));

      return messages;
    },
    MINLENGTH: 11,
    MAXLENGTH: 11
  }
};

export const FORGOTTEN_PWD_FIELDS = {
  EMAIL: {
    NAME: 'email',
    ID: 'email',
    LABEL: 'Email',
    REQUIRED: true,
    PLACEHOLDER: 'Email',
    TYPE: FIELDS_TYPES.TYPE_EMAIL
  },
  CODE: {
    NAME: 'code',
    ID: 'code',
    LABEL: 'Code',
    REQUIRED: true,
    PLACEHOLDER: 'Code',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    FORMAT: (value = '') => {
      if (!new RegExp('^[0-9-]{0,11}$').test(value)) {
        return '';
      }

      return value.replace(/-/g, '').split('').join('-');
    },
    DEFORMAT: (value = '') => {
      return value.split('-').join('');
    },
    VALIDATE: (value = '') => {
      const messages = [];

      let isValid = true;
      if (
        !new RegExp('^[0-9-]{0,11}$').test(value) ||
        value.length < FORGOTTEN_PWD_FIELDS.CODE.MINLENGTH ||
        value.length > FORGOTTEN_PWD_FIELDS.CODE.MAXLENGTH
      ) {
        isValid = false;
      }

      messages.push(generateMessage('Code must be 6 digits long', isValid));

      return messages;
    },
    MINLENGTH: 11,
    MAXLENGTH: 11
  },
  PASSWORD: {
    NAME: 'password',
    ID: 'password',
    LABEL: 'New Password',
    REQUIRED: true,
    PLACEHOLDER: 'New Password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    VALIDATE: validatePasswordComplexity,
    SHOW_MESSAGES_ON_INPUT_VALUE: true
  },
  RE_PASSWORD: {
    NAME: 'passwordRepeat',
    ID: 'password_repeat',
    LABEL: 'Repeat password',
    REQUIRED: true,
    PLACEHOLDER: 'Repeat password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    SHOW_MESSAGES_ON_INPUT_VALUE: true
  }
};

export const USER_PROFILE_FIELDS = {
  FIRST_NAME: {
    NAME: 'firstName',
    ID: 'first_name',
    LABEL: 'First name',
    LABEL_DESCRIPTION: 'This will be visible on your profile',
    PLACEHOLDER: 'First name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    REQUIRED: true,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('First name maximum text length is 20', value.length <= 20)];
    }
  },
  LAST_NAME: {
    NAME: 'lastName',
    ID: 'last_name',
    LABEL: 'Last name',
    LABEL_DESCRIPTION: 'This will be visible on your profile',
    PLACEHOLDER: 'Last name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    REQUIRED: true,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Last name maximum text length is 20', value.length <= 20)];
    }
  },
  EMPLOYER: {
    NAME: 'employer',
    ID: 'employer',
    LABEL: 'Employer',
    LABEL_DESCRIPTION: 'Who is your current employer',
    PLACEHOLDER: 'Last name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    REQUIRED: true
  },
  EMAIL: {
    NAME: 'email',
    ID: 'email',
    LABEL: 'Email address',
    LABEL_DESCRIPTION: 'The email address you registered',
    PLACEHOLDER: 'Last name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    REQUIRED: true
  },
  PHONE_NUMBER: {
    NAME: 'phoneNumber',
    ID: 'phone_number',
    LABEL: 'Phone number',
    LABEL_DESCRIPTION: 'Your preferred phone number',
    PLACEHOLDER: 'Phone number',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    FORMAT: (value = '') => {
      const pattern = /\d+/g;
      const result = value.match(pattern);
      if (!result) {
        return '';
      }

      let formatted = result.join('');
      while (formatted.startsWith('0')) {
        formatted = formatted.slice(1);
      }

      return '+' + formatted;
    }
    // VALIDATE: (value = '') => {
    //   return [
    //     generateMessage(
    //       'Please enter valid phone number',
    //       phone(value, { strictDetection: true }).isValid
    //     )
    //   ];
    // }
  },
  CITY: {
    NAME: 'city',
    ID: 'city',
    LABEL: 'City',
    LABEL_DESCRIPTION: 'Your current location by city',
    PLACEHOLDER: 'City',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('City maximum text length is 20', value.length <= 20)];
    }
  },
  COUNTRY: {
    NAME: 'country',
    ID: 'country',
    LABEL: 'Country',
    LABEL_DESCRIPTION: 'Your current location by country',
    PLACEHOLDER: 'Country',
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.COUNTRIES
  }
};

export const USER_CHANGE_PASSWORD_FIELDS = {
  OLD_PASSWORD: {
    NAME: 'oldPassword',
    ID: 'old_password',
    LABEL: 'Current password',
    LABEL_DESCRIPTION: 'Easily change and set a new password',
    REQUIRED: true,
    PLACEHOLDER: 'Current password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true
  },
  NEW_PASSWORD: {
    NAME: 'newPassword',
    ID: 'new_password',
    LABEL: 'New password',
    REQUIRED: true,
    PLACEHOLDER: 'New password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    VALIDATE: validatePasswordComplexity,
    SHOW_MESSAGES_ON_INPUT_VALUE: true
  },
  RE_NEW_PASSWORD: {
    NAME: 'repeatNewPassword',
    ID: 'repeat_new_password',
    LABEL: 'Repeat password',
    REQUIRED: true,
    PLACEHOLDER: 'Repeat new password',
    TYPE: FIELDS_TYPES.TYPE_PASSWORD,
    VISIBILITY_TOGGLE: true,
    SHOW_MESSAGES_ON_INPUT_VALUE: true
  }
};

export const USER_DEACTIVATE_ACCOUNT_FIELDS = {
  EMAIL: {
    NAME: 'email',
    ID: 'deactivate_email',
    LABEL: 'Email address',
    REQUIRED: true,
    LABEL_DESCRIPTION: 'The email address you registered',
    PLACEHOLDER: 'Email',
    TYPE: FIELDS_TYPES.TYPE_EMAIL
  }
};

export const COMPANY_PROFILE_FIELDS = {
  COMPANY_NAME: {
    NAME: 'companyName',
    ID: 'company_name',
    LABEL: 'Official company name',
    LABEL_DESCRIPTION: 'Your legal company name',
    REQUIRED: true,
    PLACEHOLDER: 'Company name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Company name maximum text length is 50', value.length <= 50)];
    }
  },
  COMPANY_TYPE: {
    NAME: 'companyType',
    ID: 'company_type',
    LABEL: 'Company type',
    LABEL_DESCRIPTION: 'Your company type',
    REQUIRED: true,
    PLACEHOLDER: 'Company type',
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.COMPANY_TYPES
  },
  COUNTRY: {
    NAME: 'country',
    ID: 'country',
    LABEL: 'Country',
    LABEL_DESCRIPTION: 'Location by country',
    REQUIRED: true,
    PLACEHOLDER: 'Country',
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.COUNTRIES
  },
  CITY: {
    NAME: 'city',
    ID: 'city',
    LABEL: 'City',
    LABEL_DESCRIPTION: 'Location by city',
    REQUIRED: true,
    PLACEHOLDER: 'City',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('City maximum text length is 20', value.length <= 20)];
    }
  },
  PHONE_NUMBER: {
    NAME: 'phoneNumber',
    ID: 'phone_number',
    LABEL: 'Phone number',
    LABEL_DESCRIPTION: 'Contact phone number',
    REQUIRED: true,
    PLACEHOLDER: 'Phone number',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    FORMAT: (value = '') => {
      const pattern = /\d+/g;
      const result = value.match(pattern);
      if (!result) {
        return '+';
      }

      let formatted = result.join('');
      while (formatted.startsWith('0')) {
        formatted = formatted.slice(1);
      }

      return '+' + formatted;
    }
    // VALIDATE: (value = '') => {
    //   return [
    //     generateMessage(
    //       'Please enter valid phone number',
    //       phone(value, { strictDetection: true }).isValid
    //     )
    //   ];
    // }
  },
  CONTACT_EMAIL: {
    NAME: 'contactEmail',
    ID: 'contact_email',
    LABEL: 'Contact email',
    LABEL_DESCRIPTION: 'Legal email address',
    REQUIRED: true,
    PLACEHOLDER: 'Email address',
    TYPE: FIELDS_TYPES.TYPE_EMAIL,
    VALIDATE: (value = '') => {
      return [
        generateMessage('Please use your company contact email address', isUsingCompanyEmail(value))
      ];
    }
  },
  TAGLINE: {
    NAME: 'tagline',
    ID: 'tagline',
    LABEL: 'Tagline',
    LABEL_DESCRIPTION: 'Your preferred slogan',
    REQUIRED: false,
    PLACEHOLDER: 'Tagline',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Tagline maximum text length is 100', value.length <= 100)];
    }
  }
};

export const SEARCH_FIELDS = {
  SEARCH_TYPE: {
    NAME: 'searchType',
    ID: 'search_type',
    LABEL: 'Search type',
    PLACEHOLDER: 'eg. Fund',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: UI_OPTION_KEYS.SEARCH_TYPE
  },
  INDUSTRY: {
    SECTION: SEARCH_CATEGORIES.GENERAL,
    NAME: 'industries',
    ID: 'search_industry',
    LABEL: 'Industry',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.INDUSTRIES
  },
  HEADQUARTER: {
    SECTION: SEARCH_CATEGORIES.GENERAL,
    NAME: 'headquarter',
    ID: 'search_headquarter',
    LABEL: 'Headquarter',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.COUNTRIES
  },
  GEOGRAPHICAL_FOCUS: {
    SECTION: SEARCH_CATEGORIES.GENERAL,
    NAME: 'geographies',
    ID: 'search_geographical_focus',
    LABEL: 'Geographical focus',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.GEOGRAPHIES
  },
  REVENUE: {
    SECTION: SEARCH_CATEGORIES.FINANCIALS,
    NAME: 'revenue',
    ID: 'search_revenue',
    LABEL: 'Revenue (€M)',
    LABEL_COMPACT: 'Revenue',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EBITDA: {
    SECTION: SEARCH_CATEGORIES.FINANCIALS,
    NAME: 'ebitda',
    ID: 'search_ebitda',
    LABEL: 'EBITDA (€M)',
    LABEL_COMPACT: 'EBITDA',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EBITDA_MARGIN: {
    SECTION: SEARCH_CATEGORIES.FINANCIALS,
    NAME: 'ebitdaMargin',
    ID: 'search_ebitda_margin',
    LABEL: 'EBITDA Margin (%)',
    LABEL_COMPACT: 'EBITDA Margin',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EBIT: {
    SECTION: SEARCH_CATEGORIES.FINANCIALS,
    NAME: 'ebit',
    ID: 'search_ebit',
    LABEL: 'EBIT (€M)',
    LABEL_COMPACT: 'EBIT',
    PLACEHOLDER: 'Any',
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EBIT_MARGIN: {
    SECTION: SEARCH_CATEGORIES.FINANCIALS,
    NAME: 'ebitMargin',
    ID: 'search_ebit_margin',
    LABEL: 'EBIT Margin (%)',
    LABEL_COMPACT: 'EBIT Margin',
    PLACEHOLDER: 'Any',
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EQUITY_STAKE: {
    SECTION: SEARCH_CATEGORIES.TRANSACTION,
    NAME: 'equityStakes',
    ID: 'search_equity_stake',
    LABEL: 'Equity stake',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.EQUITY_STAKES
  },
  SITUATION: {
    SECTION: SEARCH_CATEGORIES.TRANSACTION,
    NAME: 'situation',
    ID: 'search_situation',
    LABEL: 'Situation',
    PLACEHOLDER: 'Any',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_SELECT,
    VALUES_KEY: OPTION_KEYS.SITUATIONS
  },
  ENTERPRISE_MIN: {
    SECTION: SEARCH_CATEGORIES.VALUATION,
    NAME: 'enterpriseMin',
    ID: 'search_enterprise_value_min',
    LABEL: 'Enterprise value range (€M)',
    LABEL_COMPACT: 'Enterprise value range',
    PLACEHOLDER: 'Min',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PAIR_NAME: 'enterpriseMax'
  },
  ENTERPRISE_MAX: {
    SECTION: SEARCH_CATEGORIES.VALUATION,
    NAME: 'enterpriseMax',
    ID: 'search_enterprise_value_max',
    PLACEHOLDER: 'Max',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  },
  EQUITY_MIN: {
    SECTION: SEARCH_CATEGORIES.VALUATION,
    NAME: 'equityMin',
    ID: 'search_equity_value_min',
    LABEL: 'Equity value range (€M)',
    LABEL_COMPACT: 'Equity value range',
    PLACEHOLDER: 'Min',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PAIR_NAME: 'equityMax'
  },
  EQUITY_MAX: {
    SECTION: SEARCH_CATEGORIES.VALUATION,
    NAME: 'equityMax',
    ID: 'search_equity_value_max',
    PLACEHOLDER: 'Max',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_NUMBER
  }
};

export const SEARCH_REVIEW_FIELDS = {
  REVIEW_TEXT: {
    NAME: 'review_text',
    ID: 'review_text',
    LABEL: 'Your review',
    PLACEHOLDER: 'Write your review here',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_TEXTAREA
  },
  REVIEW_EMAIL: {
    NAME: 'review_email',
    ID: 'review_email',
    LABEL: 'Email address',
    PLACEHOLDER: 'Your email address',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_EMAIL
  }
};

export const OUTREACH_FIELDS = {
  SUBJECT: {
    NAME: 'subject',
    ID: 'subject',
    LABEL: 'Subject',
    PLACEHOLDER: 'Subject',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Subject maximum text length is 100', value.length <= 100)];
    }
  },
  MESSAGE: {
    NAME: 'message',
    ID: 'message',
    LABEL: 'Description',
    PLACEHOLDER: 'Write your message here',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_TEXTAREA,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Message maximum text length is 500', value.length <= 500)];
    }
  }
};

export const FUND_FIELDS = {
  NAME: {
    NAME: 'name',
    ID: 'fund_name',
    LABEL: 'Fund name',
    LABEL_DESCRIPTION: 'Official name of fund',
    REQUIRED: true,
    PLACEHOLDER: 'Fund name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Fund name maximum text length is 20', value.length <= 20)];
    },
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.INFORMATION
  },
  VINTAGE: {
    NAME: 'vintage',
    ID: 'fund_vintage',
    LABEL: 'Fund vintage',
    LABEL_DESCRIPTION: 'Year of initial investments',
    PLACEHOLDER: 'Fund vintage',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    INTEGER: true,
    MAXLENGTH: 4,
    MIN: 2000,
    MAX: new Date().getFullYear(),
    VALIDATE: (value = '') => {
      let isValid = true;
      if (value.length === 0) {
        isValid = false;
      } else {
        const num = +value;
        if (num > FUND_FIELDS.VINTAGE.MAX || num < FUND_FIELDS.VINTAGE.MIN) {
          isValid = false;
        }
      }
      return [
        generateMessage(
          `Fund vintage must be between ${FUND_FIELDS.VINTAGE.MIN} and ${FUND_FIELDS.VINTAGE.MAX}`,
          isValid
        )
      ];
    },
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.INFORMATION
  },
  VOLUME: {
    NAME: 'volume',
    ID: 'fund_volume',
    LABEL: 'Fund volume',
    LABEL_DESCRIPTION: 'Total volume (€M)',
    PLACEHOLDER: 'Fund volume',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.INFORMATION
  },
  DESCRIPTION: {
    NAME: 'description',
    ID: 'fund_description',
    LABEL: 'Fund description',
    LABEL_DESCRIPTION: 'Further relevant information',
    REQUIRED: true,
    PLACEHOLDER: 'Write your description here',
    TYPE: FIELDS_TYPES.TYPE_TEXTAREA,
    WRAP_CLASSNAME: 'form-group contains-textarea',
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Fund description maximum text length is 300', value.length <= 300)];
    },
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.INFORMATION
  },
  GEOGRAPHIES: {
    NAME: 'geography',
    ID: 'fund_geography',
    LABEL: 'Geographies',
    PLACEHOLDER: 'Geographical focus',
    LABEL_DESCRIPTION: 'Geographical focus',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.GEOGRAPHIES,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.GEO_INDUSTRIES
  },
  INDUSTRIES: {
    NAME: 'industries',
    ID: 'fund_industries',
    LABEL: 'Industries',
    PLACEHOLDER: 'Industries',
    LABEL_DESCRIPTION: 'Focus industries',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.INDUSTRIES,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.GEO_INDUSTRIES
  },
  SITUATION: {
    NAME: 'situations',
    ID: 'fund_situation',
    LABEL: 'Situation',
    LABEL_DESCRIPTION: 'Business situation',
    REQUIRED: true,
    PLACEHOLDER: 'Situation',
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.SITUATIONS,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.TRANSACTION_TYPES
  },
  EQUITY: {
    NAME: 'equityStakes',
    ID: 'fund_equity',
    LABEL: 'Equity stake',
    LABEL_DESCRIPTION: 'Investment type',
    REQUIRED: true,
    PLACEHOLDER: 'Equity stake',
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.EQUITY_STAKES,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.TRANSACTION_TYPES
  },
  REVENUE_RANGE_MIN: {
    NAME: 'revenueMin',
    ID: 'fund_revenue_range_min',
    LABEL: 'Revenue range',
    LABEL_DESCRIPTION: 'Target revenue (€M)',
    REQUIRED: true,
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PAIR_NAME: 'revenueMax',
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  REVENUE_RANGE_MAX: {
    NAME: 'revenueMax',
    ID: 'fund_revenue_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_RANGE_MIN: {
    NAME: 'ebitdaMin',
    ID: 'fund_ebitda_range_min',
    LABEL: 'EBITDA range',
    LABEL_DESCRIPTION: 'Target EBITDA (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'ebitdaMax',
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_RANGE_MAX: {
    NAME: 'ebitdaMax',
    ID: 'fund_ebitda_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_MARGIN: {
    NAME: 'ebitdaMargin',
    ID: 'fund_ebitda_margin',
    LABEL: 'EBITDA margin',
    LABEL_DESCRIPTION: 'Target margin (%)',
    PLACEHOLDER: 'Value %',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_RANGE_MIN: {
    NAME: 'ebitMin',
    ID: 'fund_ebit_range_min',
    LABEL: 'EBIT range',
    LABEL_DESCRIPTION: 'Target EBIT (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'ebitMax',
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_RANGE_MAX: {
    NAME: 'ebitMax',
    ID: 'fund_ebit_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_MARGIN: {
    NAME: 'ebitMargin',
    ID: 'fund_ebit_margin',
    LABEL: 'EBIT margin',
    LABEL_DESCRIPTION: 'Target margin (%)',
    PLACEHOLDER: 'Value %',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.FINANCIAL_METRICS
  },
  ENTERPRISE_RANGE_MIN: {
    NAME: 'enterpriseMin',
    ID: 'fund_enterprise_value_range_min',
    LABEL: 'Enterprise value range',
    LABEL_DESCRIPTION: 'Target value (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'enterpriseMax',
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.VALUATION
  },
  ENTERPRISE_RANGE_MAX: {
    NAME: 'enterpriseMax',
    ID: 'fund_enterprise_value_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.VALUATION
  },
  EQUITY_RANGE_MIN: {
    NAME: 'equityMin',
    ID: 'fund_equity_value_range_min',
    LABEL: 'Equity value range',
    LABEL_DESCRIPTION: 'Target value (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'equityMax',
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.VALUATION
  },
  EQUITY_RANGE_MAX: {
    NAME: 'equityMax',
    ID: 'fund_equity_value_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    FUND_VIEW_TAB: FUNDS_VIEW_TABS.VALUATION
  }
};

export const PORTFOLIO_FIELDS = {
  NAME: {
    NAME: 'name',
    ID: 'portfolio_name',
    LABEL: 'Portfolio name',
    LABEL_DESCRIPTION: 'Official name of the portfolio',
    REQUIRED: true,
    PLACEHOLDER: 'Portfolio name',
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Portfolio name maximum text length is 20', value.length <= 20)];
    },
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.INFORMATION
  },
  REVENUE: {
    NAME: 'revenue',
    ID: 'portfolio_revenue',
    LABEL: 'Porftolio company revenue',
    LABEL_DESCRIPTION: 'The portfolio’s revenue (€M)',
    PLACEHOLDER: 'Portfolio revenue',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.INFORMATION
  },
  EBITDA: {
    NAME: 'ebitda',
    ID: 'portfolio_ebitda',
    LABEL: 'Porftolio company EBITDA',
    LABEL_DESCRIPTION: 'Portfolio EBITDA (€M)',
    PLACEHOLDER: 'Portfolio EBITDA',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.INFORMATION
  },
  DESCRIPTION: {
    NAME: 'description',
    ID: 'portfolio_description',
    LABEL: 'Portfolio company description',
    LABEL_DESCRIPTION: 'Further relevant information',
    REQUIRED: true,
    PLACEHOLDER: 'Write your description here',
    TYPE: FIELDS_TYPES.TYPE_TEXTAREA,
    WRAP_CLASSNAME: 'form-group contains-textarea',
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [
        generateMessage('Portfolio description maximum text length is 300', value.length <= 300)
      ];
    },
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.INFORMATION
  },
  GEOGRAPHIES: {
    NAME: 'geography',
    ID: 'portfolio_geographies',
    LABEL: 'Geographies',
    PLACEHOLDER: 'Geographical focus',
    LABEL_DESCRIPTION: 'Geographical focus',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.GEOGRAPHIES,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES
  },
  INDUSTRIES: {
    NAME: 'industries',
    ID: 'portfolio_industries',
    LABEL: 'Industries',
    PLACEHOLDER: 'Industries',
    LABEL_DESCRIPTION: 'Focus industries',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.INDUSTRIES,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES
  },
  SITUATION: {
    NAME: 'situations',
    ID: 'portfolio_situation',
    LABEL: 'Situation',
    LABEL_DESCRIPTION: 'Business situation',
    REQUIRED: true,
    PLACEHOLDER: 'Situation',
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.SITUATIONS,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES
  },
  EQUITY: {
    NAME: 'equityStakes',
    ID: 'portfolio_equity',
    LABEL: 'Equity stake',
    LABEL_DESCRIPTION: 'Investment type',
    REQUIRED: true,
    PLACEHOLDER: 'Equity stake',
    TYPE: FIELDS_TYPES.TYPE_MULTI_SELECT,
    VALUES_KEY: OPTION_KEYS.EQUITY_STAKES,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES
  },
  REVENUE_RANGE_MIN: {
    NAME: 'revenueMin',
    ID: 'portfolio_revenue_range_min',
    LABEL: 'Revenue range',
    LABEL_DESCRIPTION: 'Target revenue (€M)',
    REQUIRED: true,
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    PAIR_NAME: 'revenueMax',
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  REVENUE_RANGE_MAX: {
    NAME: 'revenueMax',
    ID: 'portfolio_revenue_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_RANGE_MIN: {
    NAME: 'ebitdaMin',
    ID: 'portfolio_ebitda_range_min',
    LABEL: 'EBITDA range',
    LABEL_DESCRIPTION: 'Target EBITDA (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'ebitdaMax',
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_RANGE_MAX: {
    NAME: 'ebitdaMax',
    ID: 'portfolio_ebitda_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBITDA_MARGIN: {
    NAME: 'ebitdaMargin',
    ID: 'portfolio_ebitda_margin',
    LABEL: 'EBITDA margin',
    LABEL_DESCRIPTION: 'Target margin (%)',
    PLACEHOLDER: 'Value %',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_RANGE_MIN: {
    NAME: 'ebitMin',
    ID: 'portfolio_ebit_range_min',
    LABEL: 'EBIT range',
    LABEL_DESCRIPTION: 'Target EBIT (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'ebitMax',
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_RANGE_MAX: {
    NAME: 'ebitMax',
    ID: 'portfolio_ebit_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  EBIT_MARGIN: {
    NAME: 'ebitMargin',
    ID: 'portfolio_ebit_margin',
    LABEL: 'EBIT margin',
    LABEL_DESCRIPTION: 'Target margin (%)',
    PLACEHOLDER: 'Value %',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS
  },
  ENTERPRISE_RANGE_MIN: {
    NAME: 'enterpriseMin',
    ID: 'portfolio_enterprise_value_range_min',
    LABEL: 'Enterprise value range',
    LABEL_DESCRIPTION: 'Target value (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'enterpriseMax',
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.VALUATION
  },
  ENTERPRISE_RANGE_MAX: {
    NAME: 'enterpriseMax',
    ID: 'portfolio_enterprise_value_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.VALUATION
  },
  EQUITY_RANGE_MIN: {
    NAME: 'equityMin',
    ID: 'portfolio_equity_value_range_min',
    LABEL: 'Equity value range',
    LABEL_DESCRIPTION: 'Target value (€M)',
    PLACEHOLDER: 'Min',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PAIR_NAME: 'equityMax',
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.VALUATION
  },
  EQUITY_RANGE_MAX: {
    NAME: 'equityMax',
    ID: 'portfolio_equity_value_range_max',
    PLACEHOLDER: 'Max',
    TYPE: FIELDS_TYPES.TYPE_NUMBER,
    REQUIRED: true,
    PORTFOLIO_VIEW_TAB: PORTFOLIOS_VIEW_TABS.VALUATION
  }
};

export const SEARCH_SAVE_FIELDS = {
  NAME: {
    NAME: 'searchName',
    ID: 'search_name',
    PLACEHOLDER: 'eg.Search name 1',
    LABEL: 'Search name',
    REQUIRED: true,
    TYPE: FIELDS_TYPES.TYPE_TEXT,
    SHOW_MESSAGES_ON_INPUT_VALUE: true,
    VALIDATE: (value = '') => {
      return [generateMessage('Search name maximum text length is 20', value.length <= 20)];
    }
  }
};

export const SAVED_SEARCH_VIEW_FIELDS = {
  RESULTS_COUNT: {
    NAME: 'resultsCount',
    ID: 'saved_search_res_count',
    LABEL: 'Number of results'
  },
  SAVED_DATE: {
    NAME: 'savedDate',
    ID: 'saved_search_saved_date',
    LABEL: 'Date saved'
  }
};

export const getErrorFields = (descrFields = [], stateFields = {}, onSubmitFields = []) => {
  const validationObj = {
    messages: [],
    invalidFields: []
  };

  for (const field of descrFields) {
    const isOnSubmit = onSubmitFields.includes(field);
    if (
      typeof field.VALIDATE === 'function' &&
      (field.TYPE === FIELDS_TYPES.TYPE_CHECKBOX ||
        typeof stateFields[field.NAME] === 'number' ||
        stateFields[field.NAME].length)
    ) {
      const messages = field.VALIDATE(stateFields[field.NAME]);
      if (messages.findIndex(({ isValid }) => !isValid) !== -1) {
        validationObj.invalidFields.push({ name: field.NAME, isOnSubmit });
        if (
          !(
            field.SHOW_MESSAGES_ON_INPUT_VALUE &&
            typeof stateFields[field.NAME] === 'string' &&
            !stateFields[field.NAME].length
          )
        ) {
          validationObj.messages.push(
            ...messages.map(({ isValid, msg }) =>
              generateMessage(msg, isValid, field.NAME, isOnSubmit)
            )
          );
        }
      }
    } else if (
      field.REQUIRED &&
      ((field.TYPE === FIELDS_TYPES.TYPE_NUMBER && isNaN(stateFields[field.NAME])) ||
        !stateFields[field.NAME] ||
        (field.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT && !stateFields[field.NAME].length))
    ) {
      validationObj.invalidFields.push({ name: field.NAME, isOnSubmit });
      if (typeof field.VALIDATE === 'function') {
        const messages = field.VALIDATE(stateFields[field.NAME]);
        if (
          !(
            field.SHOW_MESSAGES_ON_INPUT_VALUE &&
            typeof stateFields[field.NAME] === 'string' &&
            !stateFields[field.NAME].length
          )
        ) {
          validationObj.messages.push(
            ...messages.map(({ isValid, msg }) =>
              generateMessage(msg, isValid, field.NAME, isOnSubmit)
            )
          );
        }
      }
    }

    if (field.PAIR_NAME) {
      const [rangeMin, rangeMax] = [stateFields[field.NAME], stateFields[field.PAIR_NAME]];
      if (rangeMin.length && rangeMax.length) {
        const [rangeMinNum, rangeMaxNum] = [+rangeMin, +rangeMax];
        if (!isNaN(rangeMinNum) && !isNaN(rangeMaxNum) && rangeMinNum > rangeMaxNum) {
          validationObj.invalidFields.push(
            { name: field.NAME, isOnSubmit: true },
            { name: field.PAIR_NAME, isOnSubmit: true }
          );
          validationObj.messages.push(
            generateMessage(
              // `${field.LABEL} min value can not be greater than max value.`,
              'Invalid value',
              false,
              field.NAME,
              true
            ),
            generateMessage('', false, field.PAIR_NAME, true)
          );
        }
      }
    }
  }

  return validationObj;
};

export const compareFields = (stateFields = {}, objectFields = {}) => {
  return !!Object.entries(stateFields).find(
    ([fieldName, fieldValue]) =>
      (typeof objectFields[fieldName] === 'string'
        ? objectFields[fieldName]
        : objectFields[fieldName] && typeof objectFields[fieldName] !== 'boolean'
        ? JSON.stringify(objectFields[fieldName])
        : '') !== (typeof fieldValue === 'string' ? fieldValue : JSON.stringify(fieldValue))
  );
};

export const trimStateFields = (stateFields = {}) => {
  runInAction(() => {
    Object.entries(stateFields).forEach(([fieldName, fieldValue]) => {
      if (typeof fieldValue === 'string') {
        stateFields[fieldName] = fieldValue.trim();
      }
    });
  });
};

export const isEmptyValue = (value) => {
  if (typeof value === 'boolean' && !value) {
    return true;
  }

  if ((typeof value === 'string' || Array.isArray(value)) && !value.length) {
    return true;
  }

  return false;
};
