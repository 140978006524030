import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FUNDS_VIEW_MODE, PORTFOLIOS_VIEW_TABS } from '../constants';
import {
  PORTFOLIO_FIELDS,
  getErrorFields,
  compareFields,
  trimStateFields
} from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import Input from '../../../components/inputs/Input';
import TextArea from '../../../components/inputs/TextArea';
import MultiSelect from '../../../components/inputs/MultiSelect';
import RangeInput from '../../../components/inputs/RangeInput';
import { mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';

const PortfolioView = observer(({ mode = FUNDS_VIEW_MODE.CREATE, portfolio = null }) => {
  const { fundsStore } = useStore();
  const { goBack } = useHistory();
  const state = useLocalObservable(() => ({
    fields: {
      ...mapFieldsToState(PORTFOLIO_FIELDS),
      [PORTFOLIO_FIELDS.NAME.NAME]:
        mode === FUNDS_VIEW_MODE.CREATE ? `Portfolio ${fundsStore.portfolios.length + 1}` : ''
    },
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    tab: PORTFOLIOS_VIEW_TABS.INFORMATION,
    setTab: (value = '') => (state.tab = value),
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(PORTFOLIO_FIELDS), state.fields);
    },
    get isRestoreDisabled() {
      const hasDiff =
        mode === FUNDS_VIEW_MODE.CREATE
          ? !!Object.entries(state.fields).find(([fieldName, fieldValue]) => {
              if (fieldName === PORTFOLIO_FIELDS.NAME.NAME) {
                return fieldValue !== `Portfolio ${fundsStore.portfolios.length + 1}`;
              }
              return Array.isArray(fieldValue) ? !!fieldValue.length : fieldValue !== '';
            })
          : compareFields(state.fields, portfolio);

      return (
        !hasDiff ||
        (mode === FUNDS_VIEW_MODE.CREATE && fundsStore.isCreatingPortfolio) ||
        (mode === FUNDS_VIEW_MODE.EDIT && fundsStore.portfoliosInAction[portfolio.id])
      );
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    restore: () => {
      if (mode === FUNDS_VIEW_MODE.CREATE) {
        state.fields = {
          ...mapFieldsToState(PORTFOLIO_FIELDS),
          [PORTFOLIO_FIELDS.NAME.NAME]:
            mode === FUNDS_VIEW_MODE.CREATE ? `Portfolio ${fundsStore.portfolios.length + 1}` : ''
        };
      } else {
        const initial = mapFieldsToState(PORTFOLIO_FIELDS);
        Object.entries(initial).forEach(([fieldName, initialFieldValue]) => {
          state.fields[fieldName] = portfolio[fieldName] || initialFieldValue;
        });
      }
    }
  }));

  useEffect(() => {
    if (mode === FUNDS_VIEW_MODE.EDIT && portfolio) {
      const initial = mapFieldsToState(PORTFOLIO_FIELDS);
      runInAction(() => {
        Object.keys(state.fields).forEach((fieldName) => {
          state.fields[fieldName] = portfolio[fieldName] || initial[fieldName];
        });
      });
    }
  }, [mode, portfolio]);

  const createPortfolio = () => {
    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    fundsStore.createPortfolio(state.fields, () => {
      goBack(paths.FUNDS_PORTOFLIO_COMPANIES);
    });
  };

  const editPortfolio = () => {
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    fundsStore.editPortfolio(portfolio, state.fields, () => {
      goBack(paths.FUNDS_PORTOFLIO_COMPANIES);
    });
  };

  const onBackClick = (e) => {
    e?.preventDefault?.();
    goBack(paths.FUNDS_PORTOFLIO_COMPANIES);
  };

  const tabConfig = [
    { path: PORTFOLIOS_VIEW_TABS.INFORMATION, desktopLabel: 'Portfolio information' },
    { path: PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES, desktopLabel: 'Geo & Industries' },
    { path: PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS, desktopLabel: 'Financials' },
    { path: PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES, desktopLabel: 'Transaction' },
    { path: PORTFOLIOS_VIEW_TABS.VALUATION, desktopLabel: 'Valuation' }
  ];

  const erroredFields = Object.fromEntries(
    state.validationFields.invalidFields.map(({ name }) => [name, true])
  );

  const tabsInfo = [];
  tabConfig.forEach(({ path }) => {
    const tabInfo = { hasErrorField: false };
    tabsInfo.push(tabInfo);

    if (!state.onSubmitErrorState) {
      return;
    }

    const fields = Object.values(PORTFOLIO_FIELDS).filter((f) => f.PORTFOLIO_VIEW_TAB === path);
    fields.forEach((fieldConfig) => {
      if (erroredFields[fieldConfig.NAME]) {
        tabInfo.hasErrorField = true;
      }
    });
  });

  const disableAllFields =
    mode === FUNDS_VIEW_MODE.CREATE
      ? fundsStore.isCreatingPortfolio
      : fundsStore.portfoliosInAction[portfolio.id];

  return (
    <>
      <div className="header-backlink">
        <div className="gray-title-panel">
          <div className="title-section">
            <h4>{state.fields[PORTFOLIO_FIELDS.NAME.NAME]}</h4>
          </div>
          <h6>Control your portfolio companies at any time</h6>
          <div className="actions">
            <button
              className="btn btn-transparent btn-short disable-fade-transparent"
              disabled={state.isRestoreDisabled}
              onClick={state.restore}>
              Restore
            </button>
            <button
              className="btn btn-primary btn-short disable-fade-primary"
              disabled={state.isSaveDisabled}
              onClick={mode === FUNDS_VIEW_MODE.CREATE ? createPortfolio : editPortfolio}>
              Save
            </button>
          </div>
        </div>
        <Link to={paths.FUNDS_PORTOFLIO_COMPANIES} className="backlink" onClick={onBackClick}>
          Back to my portfolio companies
        </Link>
      </div>
      <div className="tabs">
        {tabConfig.map(({ path, desktopLabel, mobileLabel = '' }, i) => {
          const isActive = state.tab === path;
          const isErrored = tabsInfo[i].hasErrorField;
          const tabClasNames = ['tab'];

          if (isActive) {
            tabClasNames.push('active');
          }

          if (isErrored && !isActive) {
            tabClasNames.push('error-tab');
          }

          return (
            <div key={path} className={tabClasNames.join(' ')} onClick={() => state.setTab(path)}>
              <div className="desktop-text">{desktopLabel}</div>
              <div className="mobile-text">{mobileLabel || desktopLabel}</div>
            </div>
          );
        })}
      </div>
      {state.tab === PORTFOLIOS_VIEW_TABS.INFORMATION && (
        <div className="form-body form-row-layout">
          <div className="row">
            <Input
              field={PORTFOLIO_FIELDS.NAME}
              value={state.fields[PORTFOLIO_FIELDS.NAME.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
              showOnSubmitErrorState={true}
              messages={state.validationFields.messages}
            />
            <Input
              field={PORTFOLIO_FIELDS.REVENUE}
              value={state.fields[PORTFOLIO_FIELDS.REVENUE.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
          <div className="row">
            <Input
              field={PORTFOLIO_FIELDS.EBITDA}
              value={state.fields[PORTFOLIO_FIELDS.EBITDA.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
          <div className="row">
            <TextArea
              field={PORTFOLIO_FIELDS.DESCRIPTION}
              value={state.fields[PORTFOLIO_FIELDS.DESCRIPTION.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={true}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === PORTFOLIOS_VIEW_TABS.GEO_INDUSTRIES && (
        <div className="form-body form-row-layout">
          <div className="row">
            <MultiSelect
              field={PORTFOLIO_FIELDS.GEOGRAPHIES}
              value={state.fields[PORTFOLIO_FIELDS.GEOGRAPHIES.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <MultiSelect
              field={PORTFOLIO_FIELDS.INDUSTRIES}
              value={state.fields[PORTFOLIO_FIELDS.INDUSTRIES.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === PORTFOLIOS_VIEW_TABS.FINANCIAL_METRICS && (
        <div className="form-body form-row-layout">
          <div className="row">
            <RangeInput
              fieldMin={PORTFOLIO_FIELDS.REVENUE_RANGE_MIN}
              fieldMax={PORTFOLIO_FIELDS.REVENUE_RANGE_MAX}
              valueMin={state.fields[PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME]}
              valueMax={state.fields[PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
          </div>
          <div className="row">
            <RangeInput
              fieldMin={PORTFOLIO_FIELDS.EBITDA_RANGE_MIN}
              fieldMax={PORTFOLIO_FIELDS.EBITDA_RANGE_MAX}
              valueMin={state.fields[PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME]}
              valueMax={state.fields[PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <Input
              field={PORTFOLIO_FIELDS.EBITDA_MARGIN}
              value={state.fields[PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
          <div className="row">
            <RangeInput
              fieldMin={PORTFOLIO_FIELDS.EBIT_RANGE_MIN}
              fieldMax={PORTFOLIO_FIELDS.EBIT_RANGE_MAX}
              valueMin={state.fields[PORTFOLIO_FIELDS.EBIT_RANGE_MIN.NAME]}
              valueMax={state.fields[PORTFOLIO_FIELDS.EBIT_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <Input
              field={PORTFOLIO_FIELDS.EBIT_MARGIN}
              value={state.fields[PORTFOLIO_FIELDS.EBIT_MARGIN.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === PORTFOLIOS_VIEW_TABS.TRANSACTION_TYPES && (
        <div className="form-body form-row-layout">
          <div className="row">
            <MultiSelect
              field={PORTFOLIO_FIELDS.SITUATION}
              value={state.fields[PORTFOLIO_FIELDS.SITUATION.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
            <MultiSelect
              field={PORTFOLIO_FIELDS.EQUITY}
              value={state.fields[PORTFOLIO_FIELDS.EQUITY.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={disableAllFields}
              inputWrap={{ enable: true, className: 'col' }}
            />
          </div>
        </div>
      )}
      {state.tab === PORTFOLIOS_VIEW_TABS.VALUATION && (
        <div className="form-body form-row-layout">
          <div className="row">
            <RangeInput
              fieldMin={PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN}
              fieldMax={PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX}
              valueMin={state.fields[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME]}
              valueMax={state.fields[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
            <RangeInput
              fieldMin={PORTFOLIO_FIELDS.EQUITY_RANGE_MIN}
              fieldMax={PORTFOLIO_FIELDS.EQUITY_RANGE_MAX}
              valueMin={state.fields[PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME]}
              valueMax={state.fields[PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]}
              disabled={disableAllFields}
              setFieldValue={state.setFieldValue}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={state.onSubmitErrorState}
              wrapClass={'col'}
            />
          </div>
        </div>
      )}
    </>
  );
});

export default PortfolioView;
