import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { useEffect } from 'react';

const StaticPageLayout = observer(({ children, page, hideMobileFooter, component }) => {
  const { utilsStore } = useStore();

  useEffect(() => {
    utilsStore.setIsMobileFooterHidden(hideMobileFooter || false);
    return () => {
      utilsStore.setIsMobileFooterHidden(false);
    };
  }, [hideMobileFooter]);

  const classNames = ['app-body', 'static-container'];
  if (utilsStore.isMobileHamburgerOpen) {
    classNames.push('hidden');
  }
  classNames.push(`page-${page}`);

  if (component) {
    classNames.push(`component-${component}`);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
});

export default StaticPageLayout;
