import { getPublicDomainList } from './constants/freeEmailProviders';
import { SEARCH_CATEGORIES } from './constants/searchCategories';
import { SEARCH_FIELDS, FIELDS_TYPES } from './constants/fields';
import { PUNCTUATION, CURRENCIES, NUMBERS } from './constants/notation';
import { matchRoutes } from 'react-router';

export const matchPath = (paths = [], location) => {
  const matches = matchRoutes(
    paths.map((path) => ({ path })),
    location
  );

  return !!matches;
};

export const mapFieldsToState = (fields = {}) => {
  return Object.fromEntries(
    Object.values(fields).map((fieldConfig) => [
      fieldConfig.NAME,
      fieldConfig.TYPE === FIELDS_TYPES.TYPE_CHECKBOX
        ? false
        : fieldConfig.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT
        ? []
        : ''
    ])
  );
};

export const generateMessage = (
  message = '',
  isValid = false,
  field = null,
  isOnSubmit = false
) => {
  return { msg: message, isValid, field, isOnSubmit };
};

export const isUsingCompanyEmail = (email = '') => {
  if (!email) {
    return false;
  }

  if (typeof email !== 'string') {
    return false;
  }

  if (!email.includes('@')) {
    return false;
  }

  const parts = email.split('@');
  const domain = parts[parts.length - 1];
  if (getPublicDomainList().includes(domain)) {
    return false;
  }

  return true;
};

export const validatePasswordComplexity = (password = '') => {
  const messages = [
    [password.length >= 8, 'Password must contain at least 8 characters'],
    [/\d/.test(password), 'Password must contain at least 1 number'],
    [/[^a-zA-Z0-9\s]/.test(password), 'Password must contain at least 1 special character'],
    [/[A-Z]/.test(password), 'Password must contain at least 1 uppercase letter'],
    [/[a-z]/.test(password), 'Password must contain at least 1 lowercase letter']
  ].map(([isValid, msg]) => generateMessage(msg, isValid));

  return messages;
};

export const formatNumber = (number = 0, spacer = PUNCTUATION.SPACE) => {
  try {
    const testNum = +number;
    if (isNaN(testNum) || !['string', 'number'].includes(typeof number) || number === '') {
      return PUNCTUATION.EMPTY_VALUE;
    }
  } catch (error) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer);
};

export const formatPercent = (number = 0, spacer = PUNCTUATION.SPACE) => {
  const formattedNumber = formatNumber(number, spacer);
  if (formattedNumber === PUNCTUATION.EMPTY_VALUE) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${formattedNumber}${PUNCTUATION.PERCENT}`;
};

export const formatCurrency = (
  number = 0,
  currencyNotation = CURRENCIES.EUR,
  numberNotation = NUMBERS.MILLION
) => {
  const formattedNumber = formatNumber(number);
  if (formattedNumber === PUNCTUATION.EMPTY_VALUE) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${currencyNotation}${formattedNumber}${numberNotation}`;
};

export const formatCurrencyRange = (
  number1 = 0,
  number2 = 0,
  rangeNotation = PUNCTUATION.EN_DASH,
  currencyNotation = CURRENCIES.EUR,
  numberNotation = NUMBERS.MILLION
) => {
  const first = formatCurrency(number1, currencyNotation, numberNotation);
  const second = formatCurrency(number2, currencyNotation, numberNotation);

  if ([first, second].includes(PUNCTUATION.EMPTY_VALUE)) {
    return PUNCTUATION.EMPTY_VALUE;
  }

  return `${first}${rangeNotation}${second}`;
};

export const getFilledSearchCategoriesCount = (stateFields = {}) => {
  return Object.fromEntries(
    Object.values(SEARCH_CATEGORIES).map((section) => {
      const values = Object.entries(stateFields)
        .filter(
          ([fieldName]) =>
            Object.values(SEARCH_FIELDS).find((f) => f.NAME === fieldName)?.SECTION === section
        )
        .map((pair) => pair[1]);
      const filled = values.filter((v) =>
        Array.isArray(v) ? !!v.length : typeof v === 'boolean' ? v : !!v.length
      ).length;
      const total = values.length;
      return [section, { filled, total }];
    })
  );
};

export const formatDateDDMMYYYY = (date = new Date()) => {
  let d = date;
  if (!(date instanceof Date)) {
    try {
      d = new Date(date);
    } catch (error) {
      console.error(error);
      return '??/??/????';
    }
  }

  let [day, month, year] = [d.getDate(), d.getMonth() + 1, d.getFullYear()];

  if (day < 10) {
    day = '0' + day;
  }

  if (month < 10) {
    month = '0' + month;
  }

  return `${day}/${month}/${year}`;
};
