import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { useEffect, useMemo } from 'react';
import Reroute from '../../../components/navigation/Reroute';
import { paths } from '../../../utils/constants/routes';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { runInAction } from 'mobx';
import SavedSearchesList from './components/SavedSearchesList';
import SavedSearchView from './components/SavedSearchView';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';

const savedResultsItemsPerPage = 8;

const SavedSearchResults = observer(() => {
  const { location, navigate, params } = useHistory();
  const { searchStore } = useStore();

  const state = useLocalObservable(() => ({
    page: 1,
    pages: 1
  }));

  useEffect(() => {
    if (
      (!searchStore.isLoadingSaved && !searchStore.saved.length) ||
      (searchStore.initialLoadSaved && !searchStore.isLoadingSaved)
    ) {
      searchStore.loadSaved();
    }
  }, [searchStore]);

  useEffect(() => {
    if (params.searchId || searchStore.initialLoadSaved) {
      return;
    }

    const pages = Math.ceil(searchStore.saved.length / savedResultsItemsPerPage);
    const urlParams = new URLSearchParams(location.search);
    const foundPage = urlParams.get('page');
    const page = foundPage ? +foundPage : 1;
    if (isNaN(page) || page < 1 || page > pages) {
      if (isNaN(page) || page < 1) {
        navigate(`${paths.SAVED_SEARCH_RESULTS}?page=1`);
      } else if (page > pages) {
        navigate(`${paths.SAVED_SEARCH_RESULTS}?page=${pages}`);
      }
    } else {
      // Not quite sure why this is here, uncomment if any bugs appear
      // if (!foundPage) {
      //   navigate(`${paths.SAVED_SEARCH_RESULTS}?page=${page}`);
      // }

      if (state.page !== page || state.pages !== pages) {
        runInAction(() => {
          state.page = page;
          state.pages = pages;
        });
      }
    }
  }, [searchStore, searchStore.saved, location.search, params.searchId]);

  const selectedSearch = useMemo(() => {
    const foundEntry = searchStore.saved.find((s) => s.id == params.searchId);
    return foundEntry;
  }, [searchStore.saved, params.searchId]);

  if (
    !searchStore.isLoadingSavedData &&
    params.searchId &&
    !selectedSearch &&
    !searchStore.loadSavedError
  ) {
    return <Reroute path={paths.SAVED_SEARCH_RESULTS} />;
  }

  if (searchStore.isLoadingSavedData) {
    return <GeneralLoader />;
  }

  const showError = !!searchStore.loadSavedError;
  const showSavedSearchList = !searchStore.loadSavedError && !params.searchId;
  const showSavedSearchView = !searchStore.loadSavedError && !!params.searchId;

  return (
    <StaticPageLayout page="saved-search-listing" hideMobileFooter={true}>
      {showError && (
        <GeneralError
          errorMessage={searchStore.loadSavedError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext="Try again"
          onActionButtonClick={searchStore.loadSaved}
        />
      )}
      {showSavedSearchList && (
        <SavedSearchesList
          list={searchStore.saved}
          page={state.page}
          pages={state.pages}
          itemsPerPage={savedResultsItemsPerPage}
        />
      )}
      {showSavedSearchView && <SavedSearchView search={selectedSearch} page={state.page} />}
    </StaticPageLayout>
  );
});

export default SavedSearchResults;
