export const paths = {
  HOME: '/',
  FORGOTTEN_PASSWORD: '/en/forgotten',
  SIGNUP: '/en/registration',
  LOGIN: '/en/login',
  USER_VERIF_PROGRESS: '/en/verification-progress',
  TERMS_N_CONDITIONS: '/en/terms-and-conditions',
  COOKIE_POLICY: '/en/cookie-policy',
  PRIVACY_POLICY: '/en/privacy-policy',
  IMPRINT: '/en/imprint',
  USER_PROFILE: '/en/profile',
  USER_PROFILE_SETTINGS: '/en/profile/settings',
  USER_PROFILE_SETTINGS_CHANGE_PASSWORD: '/en/profile/settings/change-password',
  USER_PROFILE_SETTINGS_DEACTIVATE_ACCOUNT: '/en/profile/settings/deactivate-account',
  SEARCH_RESULTS: '/en/search-results',
  SAVED_SEARCH_RESULTS: '/en/search-results/saved',
  COMPANY_PROFILE: '/en/company-profile',
  CREATE_COMPANY_PROFILE: '/en/company-profile/create',
  FUNDS: '/en/funds',
  FUNDS_PORTOFLIO_COMPANIES: '/en/funds/portfolio-companies',
  FUNDS_CREATE_FUND: '/en/funds/create-fund',
  FUNDS_EDIT_FUND: '/en/funds/edit-fund',
  FUNDS_CREATE_PORTFOLIO_COMPANY: '/en/funds/create-portfolio-company',
  FUNDS_EDIT_PORTFOLIO_COMPANY: '/en/funds/edit-portfolio-company'
};

export const routes = {
  USER_PROFILE_PATH: '/en/profile/:section?/:subsection?',
  FUNDS_PATH: '/en/funds/:component?',
  SAVED_SEARCH_RESULTS_PATH: '/en/search-results/saved/:searchId?'
};
