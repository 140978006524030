import { useLocalObservable, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';
import {
  FORGOTTEN_PWD_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../utils/constants/fields';
import { useEffect } from 'react';
import Input from '../../../components/inputs/Input';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { mapFieldsToState } from '../../../utils/utils';

const ForgottenPassword = observer(() => {
  const { authStore } = useStore();

  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(FORGOTTEN_PWD_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    step1onSubmitErrorState: false,
    setStep1onSubmitErrorState: (value = false) => (state.step1onSubmitErrorState = value),
    step2onSubmitErrorState: false,
    setStep2onSubmitErrorState: (value = false) => (state.step2onSubmitErrorState = value),
    get step1ValidationFields() {
      return getErrorFields([FORGOTTEN_PWD_FIELDS.EMAIL], state.fields);
    },
    get step2ValidationFields() {
      const { CODE, PASSWORD } = FORGOTTEN_PWD_FIELDS;
      return getErrorFields([CODE, PASSWORD], state.fields);
    }
  }));

  useEffect(() => {
    return () => {
      authStore.cleanUpAuthPage();
    };
  }, [authStore]);

  const onSubmit = (e) => {
    e.preventDefault();

    trimStateFields(state.fields);
    if (state.step1ValidationFields.invalidFields.length) {
      if (!state.step1onSubmitErrorState) {
        state.setStep1onSubmitErrorState(true);
      }
      return;
    }

    authStore.requestResetPassword(fields[FORGOTTEN_PWD_FIELDS.EMAIL.NAME]);
  };

  const onSubmitCode = (e) => {
    e.preventDefault();

    if (state.step2ValidationFields.invalidFields.length) {
      if (!state.step2onSubmitErrorState) {
        state.setStep2onSubmitErrorState(true);
      }
      return;
    }

    authStore.submitPasswordReset(
      fields[FORGOTTEN_PWD_FIELDS.EMAIL.NAME],
      FORGOTTEN_PWD_FIELDS.CODE.DEFORMAT(fields[FORGOTTEN_PWD_FIELDS.CODE.NAME]),
      fields[FORGOTTEN_PWD_FIELDS.PASSWORD.NAME]
    );
  };

  const { setFieldValue, fields } = state;

  return (
    <StaticPageLayout page="login-layout-forgotten-password">
      {authStore.forgottenPwdStep == 1 && (
        <form onSubmit={onSubmit}>
          <h4>Oops. Forgot your password?</h4>
          <div className="check-text">
            Please type in the email you registered with and we will send you a recovery code.
          </div>
          <Input
            {...{
              field: FORGOTTEN_PWD_FIELDS.EMAIL,
              value: fields[FORGOTTEN_PWD_FIELDS.EMAIL.NAME],
              setFieldValue
            }}
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              authStore.isAuthenticating ||
              state.step1ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length
            }>
            Send code
          </button>
          {authStore.authError && <div className="error">{authStore.authError}</div>}
          {!!state.step1ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length &&
            state.step1ValidationFields.messages
              .filter((m) => !m.isOnSubmit)
              .map((m, idx) => (
                <div className={m.isValid ? 'success' : 'error'} key={idx}>
                  {m.msg}
                </div>
              ))}
          {authStore.authErrors.messages.map((m, idx) => (
            <div className={m.isValid ? 'success' : 'error'} key={idx}>
              {m.msg}
            </div>
          ))}
        </form>
      )}

      {authStore.forgottenPwdStep == 2 && (
        <form onSubmit={onSubmitCode}>
          <h4>Oops. Forgot your password?</h4>
          <div className="check-text">Please fill in the code you received in your email.</div>
          <Input
            {...{
              field: FORGOTTEN_PWD_FIELDS.CODE,
              value: fields[FORGOTTEN_PWD_FIELDS.CODE.NAME],
              setFieldValue
            }}
          />
          <Input
            {...{
              field: FORGOTTEN_PWD_FIELDS.PASSWORD,
              value: fields[FORGOTTEN_PWD_FIELDS.PASSWORD.NAME],
              setFieldValue
            }}
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              authStore.isAuthenticating ||
              state.step2ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length
            }>
            {authStore.isAuthenticating ? 'Loading...' : 'Change password'}
          </button>
          {authStore.authError && <div className="error">{authStore.authError}</div>}
          {!!state.step2ValidationFields.invalidFields.filter((f) => !f.isOnSubmit).length &&
            state.step2ValidationFields.messages
              .filter((m) => !m.isOnSubmit)
              .map((m, idx) => (
                <div className={m.isValid ? 'success' : 'error'} key={idx}>
                  {m.msg}
                </div>
              ))}
          {authStore.authErrors.messages.map((m, idx) => (
            <div className={m.isValid ? 'success' : 'error'} key={idx}>
              {m.msg}
            </div>
          ))}
        </form>
      )}
      {authStore.forgottenPwdStep == 3 && (
        <form className="step-3">
          <h4>You have successfully set your new password!</h4>
          <div className="check-text">You can now login into your account</div>
          <Link to={paths.HOME} className="btn btn-primary btn-block">
            Login
          </Link>
        </form>
      )}
    </StaticPageLayout>
  );
});

export default ForgottenPassword;
