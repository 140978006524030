import { FIELDS_TYPES, isEmptyValue } from '../utils/constants/fields';
import { OPTION_KEYS } from '../utils/constants/optionKeys';

export const listsPreResponseMapper = (array = []) => {
  return array.map(({ data }) => data);
};

const genericMapper = (entry, fields, toRequest = false) => {
  const fieldsConfig = Object.values(fields).reduce(
    (acc, curr) => Object.assign(acc, { [curr.NAME]: curr }),
    {}
  );
  Object.entries(entry).forEach(([fieldName, fieldValue]) => {
    const fieldConfig = fieldsConfig[fieldName];
    if (fieldConfig && fieldConfig.TYPE === FIELDS_TYPES.TYPE_NUMBER) {
      let number = fieldValue;
      if (fieldConfig.INTEGER) {
        if (number !== '' && number !== null && number !== undefined) {
          number = toRequest ? parseInt(number) : '' + number;
        }
      } else if (number !== '' && number !== null && number !== undefined) {
        number = toRequest ? parseFloat(number) : number.toFixed(2);
      }

      if (toRequest) {
        if (typeof number !== 'number') {
          entry[fieldName] = null;
        } else {
          entry[fieldName] = number;
        }
      } else {
        if (number === null || number === undefined) {
          entry[fieldName] = '';
        } else {
          entry[fieldName] = number;
        }
      }
    } else if (
      fieldConfig &&
      [
        FIELDS_TYPES.TYPE_EMAIL,
        FIELDS_TYPES.TYPE_PASSWORD,
        FIELDS_TYPES.TYPE_TEXT,
        FIELDS_TYPES.TYPE_TEXTAREA
      ].includes(fieldConfig.TYPE)
    ) {
      if (!entry[fieldName]) {
        entry[fieldName] = '';
      }
    } else if (
      fieldConfig &&
      FIELDS_TYPES.TYPE_CHECKBOX === fieldConfig.TYPE &&
      typeof entry[fieldName] !== 'boolean'
    ) {
      entry[fieldName] = false;
    } else if (fieldConfig && fieldConfig.TYPE === FIELDS_TYPES.TYPE_SELECT) {
      if (isEmptyValue(entry[fieldName])) {
        entry[fieldName] = toRequest ? null : '';
      } else {
        if (!isNaN(entry[fieldName]) && toRequest) {
          entry[fieldName] = +entry[fieldName];
        }
      }
    } else if (
      fieldConfig &&
      FIELDS_TYPES.TYPE_MULTI_SELECT === fieldConfig.TYPE &&
      !Array.isArray(entry[fieldName])
    ) {
      entry[fieldName] = [];
    }
  });
  return entry;
};

export const mapData = (data, fields = {}, toRequest = false) => {
  const newData = toRequest ? JSON.parse(JSON.stringify(data)) : data;
  if (Array.isArray(newData)) {
    return newData.map((entry) => genericMapper(entry, fields, toRequest));
  } else {
    return genericMapper(newData, fields, toRequest);
  }
};

const mapPhoneCodesOptions = (codes = []) => {
  const data = [];
  codes.forEach(({ name, value, country }) =>
    value.split(',').forEach((value) => data.push({ name: `${name} ${value}`, value, country }))
  );
  return data;
};

export const mapOptions = (options = {}) => {
  options[OPTION_KEYS.PHONE_CODES] = mapPhoneCodesOptions(options[OPTION_KEYS.PHONE_CODES]);
  return options;
};

export const getMergeFieldConfig = (fields = []) => {
  return Object.fromEntries(fields.map((f, i) => [i, f]));
};
