import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../../store/store';
import { paths } from '../../../../utils/constants/routes';
import { PORTFOLIO_FIELDS } from '../../../../utils/constants/fields';
import { formatCurrencyRange, formatCurrency } from '../../../../utils/utils';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';

const PortfolioCompanies = observer(() => {
  const { fundsStore, utilsStore } = useStore();

  useEffect(() => {
    if (!fundsStore.portfolios.length && fundsStore.initialLoad && !fundsStore.isLoading) {
      fundsStore.loadFunds();
    }
  }, [fundsStore]);

  const portfolios = fundsStore.portfolios;

  const [portfolioPropertyMap, getPortfolioPropertyValue] = useMemo(() => {
    const propertyMap = {
      [PORTFOLIO_FIELDS.NAME.NAME]: { label: 'Portfolio name' },
      [PORTFOLIO_FIELDS.REVENUE.NAME]: {
        label: 'Revenue',
        format: (value) => formatCurrency(value)
      },
      [PORTFOLIO_FIELDS.EBITDA.NAME]: {
        label: 'EBITDA',
        format: (value) => formatCurrency(value)
      },
      [PORTFOLIO_FIELDS.GEOGRAPHIES.NAME]: {
        label: 'Geo focus',
        format: (value) => utilsStore.getOptionName(value, PORTFOLIO_FIELDS.GEOGRAPHIES).join(', ')
      },
      [PORTFOLIO_FIELDS.INDUSTRIES.NAME]: {
        label: 'Industries',
        format: (value) => utilsStore.getOptionName(value, PORTFOLIO_FIELDS.INDUSTRIES).join(', ')
      },
      evTicket: {
        label: 'EV ticket',
        format: (_, entry) =>
          formatCurrencyRange(
            entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
            entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
          )
      }
    };

    const getPropertyValue = (key, value, entry) => {
      return typeof propertyMap[key].format === 'function'
        ? propertyMap[key].format(value, entry)
        : value;
    };

    return [propertyMap, getPropertyValue];
  }, [utilsStore, utilsStore.options]);

  if (fundsStore.isLoadingData) {
    return <GeneralLoader />;
  }

  return (
    <>
      <div className="title-container">
        <div className="title-num">
          <h2>Your portfolios</h2>
          {!!portfolios.length && (
            <div className="num">
              ({portfolios.length > 1 ? '2' : '1'} of {portfolios.length})
            </div>
          )}
        </div>
        {!!portfolios.length && (
          <Link to={paths.FUNDS_PORTOFLIO_COMPANIES} className="btn btn-link btn-short">
            View all
          </Link>
        )}
      </div>
      {!!portfolios.length && (
        <div className="funds-listing">
          {portfolios.slice(0, 2).map((portfolio) => (
            <div className="fund-card" key={portfolio.id}>
              {Object.entries(portfolioPropertyMap).map(([key, prop]) => (
                <div className="row" key={key}>
                  <div className="label">{prop.label}</div>
                  <div className="value">
                    {getPortfolioPropertyValue(key, portfolio[key], portfolio)}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {portfolios.length === 1 && (
            <div className="fund-card empty">
              <Link className="central-row" to={paths.FUNDS_CREATE_PORTFOLIO_COMPANY}>
                Create new portfolio company
              </Link>
            </div>
          )}
        </div>
      )}
      {!portfolios.length && (
        <div className="gray-panel">
          <div className="text-container">
            <div className="text">It seems that you don’t have any portfolios</div>
            <div className="smaller-text">
              In order for businesses to find you, you need to finish your company profile and
              create a fund.
            </div>
          </div>
          <Link to={paths.FUNDS_CREATE_PORTFOLIO_COMPANY}>
            <button className="btn btn-primary btn-short">Create a portfolio</button>
          </Link>
        </div>
      )}
    </>
  );
});

export default PortfolioCompanies;
