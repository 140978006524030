import { observer } from 'mobx-react';
import { DETAILS_POUP_TABS } from '../constants/tabs';
import FundGeneralInformation from './FundGeneralInformation';
import PortfolioGeneralInformation from './PortfolioGeneralInformation';
import FundInformation from './FundInformation';
import PortfolioInformation from './PortfolioInformation';
import { UI_OPTIONS } from '../../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../../utils/constants/uiOptionKeys';
import { useEffect, useRef } from 'react';

const DetailsPopup = observer(
  ({
    searchType,
    detailsPopupDisplayed,
    detailsPopupSelectedTab,
    setDetailsPopupSelectedTab,
    displayDetailsPopup
  }) => {
    const popupRef = useRef(null);
    useEffect(() => {
      const clickEvent = (e) => {
        if (
          popupRef?.current &&
          !(e.target?.id || '').startsWith('view-search-result-details-') &&
          !popupRef.current.contains(e.target)
        ) {
          displayDetailsPopup(null);
          setDetailsPopupSelectedTab(DETAILS_POUP_TABS.GENERAL);
        }
      };
      document.addEventListener('click', clickEvent);
      return () => {
        document.removeEventListener('click', clickEvent);
      };
    }, []);

    return (
      <div className="overlay">
        <div className="container popup-details" ref={popupRef}>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  detailsPopupSelectedTab === DETAILS_POUP_TABS.GENERAL ? 'active' : ''
                }`}
                onClick={() => setDetailsPopupSelectedTab(DETAILS_POUP_TABS.GENERAL)}>
                General information
              </div>
              <div
                className={`tab ${
                  detailsPopupSelectedTab === DETAILS_POUP_TABS.INFORMATION ? 'active' : ''
                }`}
                onClick={() => setDetailsPopupSelectedTab(DETAILS_POUP_TABS.INFORMATION)}>
                {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                  ? 'Fund information'
                  : 'Portfolio information'}
              </div>
            </div>
            <div
              className="close"
              onClick={() => {
                displayDetailsPopup(null);
                setDetailsPopupSelectedTab(DETAILS_POUP_TABS.GENERAL);
              }}>
              Close
            </div>
          </div>
          {detailsPopupSelectedTab === DETAILS_POUP_TABS.GENERAL &&
            (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
              <FundGeneralInformation entry={detailsPopupDisplayed} />
            ) : (
              <PortfolioGeneralInformation entry={detailsPopupDisplayed} />
            ))}
          {detailsPopupSelectedTab === DETAILS_POUP_TABS.INFORMATION &&
            (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
              <FundInformation entry={detailsPopupDisplayed} />
            ) : (
              <PortfolioInformation entry={detailsPopupDisplayed} />
            ))}
        </div>
      </div>
    );
  }
);

export default DetailsPopup;
