import { observer, useLocalObservable } from 'mobx-react';
import {
  OUTREACH_FIELDS,
  getErrorFields,
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  trimStateFields
} from '../../../../utils/constants/fields';
import Input from '../../../../components/inputs/Input';
import TextArea from '../../../../components/inputs/TextArea';
import { mapFieldsToState } from '../../../../utils/utils';
import { useRef, useEffect } from 'react';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';
import { useStore } from '../../../../store/store';

const MessagePopup = observer(
  ({ messagePopupDisplayed, displayMessagePopup, submitMessage, actionObj }) => {
    const { utilsStore } = useStore();
    const popupRef = useRef(null);
    const state = useLocalObservable(() => ({
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      fields: mapFieldsToState(OUTREACH_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      get validationFields() {
        return getErrorFields(Object.values(OUTREACH_FIELDS), state.fields);
      },
      get isSendDisabled() {
        return (
          actionObj[messagePopupDisplayed.id] ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length)
        );
      },
      isMessageSent: false,
      messageError: null,
      sendMessage: () => {
        trimStateFields(state.fields);
        if (state.validationFields.invalidFields.length) {
          if (!state.onSubmitErrorState) {
            state.setOnSubmitErrorState(true);
          }
          return;
        }

        if (actionObj[messagePopupDisplayed.id]) {
          return;
        }

        const name = `${messagePopupDisplayed.contactInfo?.firstName || ''} ${
          messagePopupDisplayed.contactInfo?.lastName || ''
        }`;
        const itemName =
          messagePopupDisplayed[FUND_FIELDS.NAME.NAME] ||
          messagePopupDisplayed[PORTFOLIO_FIELDS.NAME.NAME] ||
          '';

        state.messageError = null;
        submitMessage(
          messagePopupDisplayed.id,
          state.fields[OUTREACH_FIELDS.SUBJECT.NAME],
          state.fields[OUTREACH_FIELDS.MESSAGE.NAME],
          () => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(
                `Your message to ${name} regarding ${itemName} has been sent successfully!`
              );
              return;
            }
            state.isMessageSent = true;
          },
          (errorMessage) => {
            if (!state.isRendered) {
              utilsStore.setHeaderMessage(
                `Failed to send message to investor ${name} regarding ${itemName}!`,
                true
              );
              return;
            }
            state.messageError = errorMessage || 'Failed to send message to investor';
          }
        );
      }
    }));

    useEffect(() => {
      state.setIsRendered(true);
      return () => {
        state.setIsRendered(false);
      };
    }, [state]);

    useEffect(() => {
      const clickEvent = (e) => {
        if (
          popupRef?.current &&
          !(e.target?.id || '').startsWith('message-search-result-details-') &&
          !popupRef.current.contains(e.target)
        ) {
          displayMessagePopup(null);
        }
      };
      document.addEventListener('click', clickEvent, { capture: true });
      return () => {
        document.removeEventListener('click', clickEvent, { capture: true });
      };
    }, []);

    const isLoading = actionObj[messagePopupDisplayed.id];

    return (
      <div className="overlay">
        <div className="container popup-message" ref={popupRef}>
          {isLoading ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <GeneralLoader />
                </div>
              </div>
            </>
          ) : state.isMessageSent ? (
            <>
              <div className="header message">
                <div className="text"></div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="content">
                <div className="success">
                  <img src="/icons/check-circle-blue.svg" alt="Success" />
                  <div className="text">
                    Your message to{' '}
                    <span className="names">
                      {' '}
                      {messagePopupDisplayed.contactInfo?.firstName || ''}{' '}
                      {messagePopupDisplayed.contactInfo?.lastName || ''}
                    </span>{' '}
                    has been sent successfully!
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="header message">
                <div className="text">
                  <label>Contact</label>
                  <div className="value">
                    {messagePopupDisplayed.contactInfo?.firstName || ''}{' '}
                    {messagePopupDisplayed.contactInfo?.lastName || ''}
                  </div>
                </div>
                <div className="close" onClick={() => displayMessagePopup(null)}>
                  Close
                </div>
              </div>
              <div className="separator"></div>
              <div className="content">
                <Input
                  field={OUTREACH_FIELDS.SUBJECT}
                  value={state.fields[OUTREACH_FIELDS.SUBJECT.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <TextArea
                  field={OUTREACH_FIELDS.MESSAGE}
                  value={state.fields[OUTREACH_FIELDS.MESSAGE.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isLoading}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState={true}
                  inputWrap={{ enable: true, className: 'field' }}
                />
                <div className="info">
                  *Use of personal email implies adherence to platform&apos;s terms. Privacy policy
                  governs data handling. No spamming.
                </div>
                <div className="actions">
                  <button
                    className="btn btn-primary btn-short"
                    onClick={state.sendMessage}
                    disabled={state.isSendDisabled}>
                    Send message
                  </button>
                </div>
                {!!state.messageError && <div className="errors">{state.messageError}</div>}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default MessagePopup;
