import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { paths } from '../../utils/constants/routes';

const Reroute = ({ path = '' }) => {
  return <Navigate to={path || paths.HOME} />;
};

Reroute.propTypes = {
  path: PropTypes.string.isRequired
};

export default Reroute;
