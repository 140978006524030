import { observer, useLocalObservable } from 'mobx-react';
import { Link } from 'react-router-dom';
import Input from '../../../../components/inputs/Input';
import {
  SEARCH_SAVE_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../../utils/constants/fields';
import { paths } from '../../../../utils/constants/routes';
import { useRef, useEffect } from 'react';
import { mapFieldsToState } from '../../../../utils/utils';
import { useStore } from '../../../../store/store';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';

const SaveSearchPopup = observer(({ displaySaveSearchPopup, onSaveSearch }) => {
  const { utilsStore } = useStore();
  const popupRef = useRef(null);
  const state = useLocalObservable(() => ({
    isRendered: false,
    setIsRendered: (value = false) => (state.isRendered = value),
    fields: mapFieldsToState(SEARCH_SAVE_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields(Object.values(SEARCH_SAVE_FIELDS), state.fields);
    },
    get isSendDisabled() {
      return (
        state.isSaving ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    isSaving: false,
    displaySuccess: false,
    messageError: null,
    onSave: () => {
      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      const searchName = state.fields[SEARCH_SAVE_FIELDS.NAME.NAME];

      state.isSaving = true;
      state.messageError = null;
      onSaveSearch(
        searchName,
        () => {
          if (!state.isRendered) {
            utilsStore.setHeaderMessage(
              `You have successfully saved search with name '${searchName}'.`
            );
            return;
          }
          state.displaySuccess = true;
        },
        (errorMessage) => {
          if (!state.isRendered) {
            utilsStore.setHeaderMessage(`Failed to save search with name '${searchName}'.`, true);
            return;
          }
          state.messageError = errorMessage || 'Failed to save search';
        },
        () => {
          if (!state.isRendered) {
            return;
          }
          state.isSaving = false;
        }
      );
    }
  }));

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, [state]);

  useEffect(() => {
    const clickEvent = (e) => {
      if (
        popupRef?.current &&
        !['save-reach-btn', 'save-reach-btn-mobile'].includes(e.target?.id) &&
        !popupRef.current.contains(e.target)
      ) {
        displaySaveSearchPopup(false);
      }
    };
    document.addEventListener('click', clickEvent, { capture: true });
    return () => {
      document.removeEventListener('click', clickEvent, { capture: true });
    };
  }, []);

  return (
    <div className="overlay">
      <div className="container popup-save-search" ref={popupRef}>
        {state.isSaving ? (
          <>
            <div className="header">
              <h5> </h5>
              <div className="close" onClick={() => displaySaveSearchPopup(null)}>
                Close
              </div>
            </div>
            <GeneralLoader />
          </>
        ) : state.displaySuccess ? (
          <>
            <div className="header message">
              <div className="success-text">
                <img src="/icons/check-circle-blue.svg" alt="Success" />
                <h4>You have successfully saved this search.</h4>
              </div>
              <div className="close" onClick={() => displaySaveSearchPopup(null)}>
                Close
              </div>
            </div>

            <div className="success-actions">
              <Link to={paths.SAVED_SEARCH_RESULTS} className="btn btn-primary btn-short">
                Go to saved searches
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="header">
              <h5>You are about to save this search results</h5>
              <div className="close" onClick={() => displaySaveSearchPopup(null)}>
                Close
              </div>
            </div>
            <p>Please type in a search name in order to save it in our system.</p>
            <Input
              field={SEARCH_SAVE_FIELDS.NAME}
              value={state.fields[SEARCH_SAVE_FIELDS.NAME.NAME]}
              setFieldValue={state.setFieldValue}
              disabled={state.isSaving}
              messages={state.validationFields.messages}
              showOnSubmitErrorState={true}
            />
            <div className="actions">
              <button
                className="btn btn-primary btn-short disable-fade-primary"
                disabled={state.isSendDisabled}
                onClick={state.onSave}>
                Save
              </button>
            </div>
            {!!state.messageError && <div className="errors">{state.messageError}</div>}
          </>
        )}
      </div>
    </div>
  );
});

export default SaveSearchPopup;
