import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { paths, routes } from './utils/constants/routes';

// components
import Header from './components/navigation/Header';
import Footer from './components/navigation/Footer';
import MobileNavigation from './components/navigation/MobileNavigation';
import CookieBanner from './components/banners/CookieBanner';

// auth pages
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ForgottenPassword from './pages/auth/ForgottenPassword';
import Profile from './pages/auth/Profile';
import VerificationProgress from './pages/auth/VerificationProgress';

// static pages
import CookiePolicy from './pages/static/CookiePolicy';
import Imprint from './pages/static/Imprint';
import PrivacyPolicy from './pages/static/PrivacyPolicy';
import TermsAndConditions from './pages/static/TermsAndConditions';

// role non-dependent pages
import Home from './pages/Home';
import CompanyProfile from './pages/CompanyProfile';
import CreateCompanyProfile from './pages/CompanyProfile/CreateCompanyProfile';

// agent pages
import SearchResults from './pages/search/SearchResults';
import SavedSearchResults from './pages/search/SavedSearchResults';

// investor pages
import Funds from './pages/Funds';

// hooks
import { useStore } from './store/store';
import useRouteGuard from './hooks/useRouteGuard';
import useSessionGuard from './hooks/useSessionGuard';
import useAppWrapClass from './hooks/useAppWrapClass';
import useAppLocationHistory from './hooks/useAppLocationHistory';
import useAppAuthGuard from './hooks/useAppAuthGuard';

const App = observer(() => {
  const { isWaitlistActivated } = useStore();
  const guard = useRouteGuard();
  const appClassName = useAppWrapClass();
  useSessionGuard();
  useAppLocationHistory();
  useAppAuthGuard();

  if (guard) {
    return guard;
  }

  return (
    <div className={appClassName}>
      <CookieBanner />
      <Header />
      <Routes>
        {/* role non-dependent pages start */}
        <Route path={paths.HOME} element={<Home />} />
        <Route path={paths.COMPANY_PROFILE} element={<CompanyProfile />} />
        <Route path={paths.CREATE_COMPANY_PROFILE} element={<CreateCompanyProfile />} />
        {/* role non-dependent pages end */}

        {/* auth pages start */}
        <Route
          path={isWaitlistActivated ? paths.LOGIN : paths.SIGNUP}
          element={isWaitlistActivated ? <Login /> : <Signup />}
        />
        <Route path={paths.FORGOTTEN_PASSWORD} element={<ForgottenPassword />} />
        <Route path={paths.USER_VERIF_PROGRESS} element={<VerificationProgress />} />
        <Route path={routes.USER_PROFILE_PATH} element={<Profile />} />
        {/* auth pages end */}

        {/* agent pages start */}
        <Route path={paths.SEARCH_RESULTS} element={<SearchResults />} />
        <Route path={routes.SAVED_SEARCH_RESULTS_PATH} element={<SavedSearchResults />} />
        {/* agent pages end */}

        {/* investor pages start */}
        <Route path={routes.FUNDS_PATH} element={<Funds />} />
        {/* investor pages end */}

        {/* static pages */}
        <Route path={paths.COOKIE_POLICY} element={<CookiePolicy />} />
        <Route path={paths.IMPRINT} element={<Imprint />} />
        <Route path={paths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={paths.TERMS_N_CONDITIONS} element={<TermsAndConditions />} />
        {/* end of static pages */}

        {/* fallback */}
        <Route path="*" element={<Navigate to={paths.HOME} replace />} />
        {/* fallback */}
      </Routes>
      <MobileNavigation />
      <Footer />
    </div>
  );
});

export default App;
