import { observer } from 'mobx-react';
import { useMemo } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useStore } from '../../store/store';
import InputWrapper from './components/InputWrapper';

const MultiSelect = observer(
  ({
    field,
    value = [],
    values = [],
    valuesKey = '',
    setFieldValue,
    disabled = false,
    inputWrap = { enable: false, className: '' }
  }) => {
    const { utilsStore } = useStore();

    const props = useMemo(() => {
      const labelProps = {};

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      return { labelProps };
    }, [field, value]);

    const items = useMemo(() => {
      return (
        values?.length
          ? values
          : field?.VALUES?.length
          ? field.VALUES
          : valuesKey || field.VALUES_KEY
          ? utilsStore?.options?.[valuesKey || field.VALUES_KEY] || []
          : []
      ).map((f) => ({
        name: f.name,
        id: f.value
      }));
    }, [utilsStore.options, field.VALUES, values]);

    const selected = useMemo(() => {
      return (value?.length ? value : []).map((v) => ({
        id: v,
        name: items.find((i) => i.id === v)?.name
      }));
    }, [value, items]);

    const withWrap = inputWrap.enable;

    const view = (
      <div className={field.WRAP_CLASSNAME || 'form-group'}>
        {!!field.LABEL && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div className="input-container icon-select-downarrow multiselect">
            <Multiselect
              id={field.ID}
              options={items}
              displayValue="name" // key from VALUES to display
              selectedValues={selected} // needs the whole object for preselecting values
              showCheckbox={true}
              // showArrow={true}
              // customArrow={true}
              placeholder={field.PLACEHOLDER}
              avoidHighlightFirstOption={true}
              onSelect={(selectedList) => {
                setFieldValue(
                  field,
                  selectedList.map((i) => i.id)
                );
              }}
              onRemove={(selectedList) => {
                setFieldValue(
                  field,
                  selectedList.map((i) => i.id)
                );
              }}
              disable={disabled}
            />
          </div>
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default MultiSelect;
