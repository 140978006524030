import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useStore } from '../../store/store';
import InputWrapper from './components/InputWrapper';

const Select = observer(
  ({
    field,
    value = '',
    values = [],
    valuesKey = '',
    setFieldValue,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    inputWrap = { enable: false, className: '' }
  }) => {
    const { utilsStore } = useStore();

    const props = useMemo(() => {
      const [selectProps, labelProps] = [{}, {}];

      selectProps.className = field.INPUT_CLASSNAME || 'form-control';

      if (!value) {
        selectProps.className += ' empty';
      }

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      return { selectProps, labelProps };
    }, [field, value]);

    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;

    const view = (
      <div
        className={field.WRAP_CLASSNAME || 'form-group'}
        {...{ 'validation-state': validationState }}>
        {!!field.LABEL && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div className="input-container icon-select-downarrow">
            <select
              name={field.NAME}
              id={field.ID}
              required={field.REQUIRED}
              value={value}
              onChange={(e) => setFieldValue(field, e.target.value)}
              disabled={disabled}
              {...props.selectProps}>
              <option value="" disabled hidden>
                {field.PLACEHOLDER}
              </option>
              {(values?.length
                ? values
                : field?.VALUES?.length
                ? field.VALUES
                : valuesKey || field.VALUES_KEY
                ? utilsStore?.options?.[valuesKey || field.VALUES_KEY] || []
                : []
              ).map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {!hideErrorMessages &&
            onSubmitErrorMessages
              .filter(({ msg }) => msg.length)
              .map(({ msg }, idx) => (
                <div
                  key={idx}
                  className={
                    field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-input'
                  }>
                  {msg}
                </div>
              ))}
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default Select;
