import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { mapData, getMergeFieldConfig } from '../../api/dataMappers';
import {
  SEARCH_SAVE_FIELDS,
  SEARCH_FIELDS,
  SAVED_SEARCH_VIEW_FIELDS
} from '../../utils/constants/fields';

class SearchStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.saved = [];
    this.loadSavedError = null;
    this.isLoadingSaved = false;
    this.initialLoadSaved = true;
  };

  saved = [];
  loadSavedError = null;
  isLoadingSaved = false;
  initialLoadSaved = true;
  get isLoadingSavedData() {
    return this.isLoadingSaved || this.initialLoadSaved;
  }

  loadSaved = () => {
    this.isLoadingSaved = true;
    this.loadSavedError = null;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_SAVED_SEARCH_RESULTS,
      onSuccess: (response) => {
        const fieldsToMap = getMergeFieldConfig([
          ...Object.values(SEARCH_SAVE_FIELDS),
          ...Object.values(SEARCH_FIELDS),
          ...Object.values(SAVED_SEARCH_VIEW_FIELDS)
        ]);

        const saved = mapData(response, fieldsToMap).sort(
          (a, b) =>
            b[SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME] -
            a[SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]
        );

        this.saved = saved;
      },
      onError: (errorMessage) => {
        this.loadSavedError = errorMessage || 'Failed to obtain saved search results';
      },
      onFinally: () => {
        this.isLoadingSaved = false;
        if (this.initialLoadSaved) {
          this.initialLoadSaved = false;
        }
      }
    });
  };

  saveSearch = (
    searchName = '',
    fields = {},
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    const reqFieldsToMap = getMergeFieldConfig([
      ...Object.values(SEARCH_SAVE_FIELDS),
      ...Object.values(SEARCH_FIELDS)
    ]);

    const body = mapData(
      { ...fields, [SEARCH_SAVE_FIELDS.NAME.NAME]: searchName },
      reqFieldsToMap,
      true
    );

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.POST_SAVED_SEARCH_RESULTS,
      body,
      onSuccess: (response) => {
        const resFieldsToMap = getMergeFieldConfig([
          ...Object.values(SEARCH_SAVE_FIELDS),
          ...Object.values(SEARCH_FIELDS),
          ...Object.values(SAVED_SEARCH_VIEW_FIELDS)
        ]);

        const newSavedSearch = mapData(response, resFieldsToMap);

        this.saved.push(newSavedSearch);

        this.saved.sort(
          (a, b) =>
            b[SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME] -
            a[SAVED_SEARCH_VIEW_FIELDS.SAVED_DATE.NAME]
        );

        onSuccess(response);
      },
      onError,
      onFinally
    });
  };
}

export default SearchStore;
